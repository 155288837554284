import axios from 'axios';
import { UploadProgress } from '../types/file.types';
const API_URL = process.env.REACT_APP_BACKEND_URL;

class UploadFileService {
  async uploadFile(file: File, urlPath: string, accessToken: string, { setUploadProgress }: UploadProgress, additionalData?: { [key: string]: string }) {
    let formData = new FormData();
    if (additionalData) {
      for (let key in additionalData) {
        const value = additionalData[key];
        formData.append(key, value);
      }
    }
    formData.append("file", file);

    return await axios.post(API_URL + urlPath, formData, {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        setUploadProgress(Math.floor((loaded * 100) / total));
      }
    });
  }
}

export const uploadFileService = new UploadFileService();

