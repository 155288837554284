import { useCallback } from 'react';
import { MatterVariable } from '../types/matter.types';
import { useAuthenticatedRequestService } from './service.base';


export function useMatterParametersService() {
    const MATTERS_PATH = 'matters/';
    const { authGet, authPut, authPost } = useAuthenticatedRequestService();

    const getMatterParameters = useCallback(async function(id: string): Promise<MatterVariable[]> {
        return await authGet(MATTERS_PATH + id + "/variables");
    }, [authGet]);

    const upsertMatterParameters = useCallback(async function(id: string,  parameters: MatterVariable[]): Promise<null> {
        return await authPut(MATTERS_PATH + id + "/variables", parameters);
    }, [authPut]);

    const deleteMatterParameters = useCallback(async function (id: string,  variableIds: number[]): Promise<null> {
        return await authPost(MATTERS_PATH + id + "/variables/delete", variableIds);
      }, [authPost]);

    const funcs = {
        getMatterParameters,
        upsertMatterParameters,
        deleteMatterParameters,
    };
    return funcs;
}
