import { useCallback } from 'react';
import { DocumentUploadInfoByCounty, DocumentUploadInfo, Progress, ChecklistItem } from '../types/matter.types';
import { useAuthenticatedRequestService } from './service.base';


export function useMatterStatusService() {
    const MATTERS_PATH = 'matters/';
    const { authGet } = useAuthenticatedRequestService();

    const getSignedPetitionInfoById = useCallback(async function(id: string): Promise<DocumentUploadInfo> {
        return await authGet(MATTERS_PATH + id + "/status/signatures");
    }, [authGet]);

    const getVoterRegistrationInfoById = useCallback(async function(id: string): Promise<DocumentUploadInfoByCounty[]> {
        return await authGet(MATTERS_PATH + id + "/status/voters");
    }, [authGet]);

    const getCirculatorRegistrationInfoById = useCallback(async function(id: string): Promise<DocumentUploadInfo> {
        return await authGet(MATTERS_PATH + id + "/status/circulators");
    }, [authGet]);

    const getProgress = useCallback(async function(id: string,): Promise<Progress> {
        return await authGet(MATTERS_PATH + id + "/status/progress");
    }, [authGet]);
    
    const getChecklist = useCallback(async function(id: string,): Promise<ChecklistItem[]> {
        return await authGet(MATTERS_PATH + id + "/status/checklist");
    }, [authGet]);
    

    const funcs = {
        getSignedPetitionInfoById,
        getVoterRegistrationInfoById,
        getCirculatorRegistrationInfoById,
        getProgress,
        getChecklist
    };
    return funcs;
}
