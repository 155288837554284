import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../AuthProvider";
import { handleError } from "../common/utils";
import { useGroupService } from "../services/group.service";
import { Group } from "../types/group.types";

interface GroupForm {
  name: string;
}

interface GroupNewProps {
  isOpen: boolean;
  close: () => void;
}

const GroupNew = ({
  isOpen,
  close,
}: GroupNewProps) => {
  const { authenticatedUser } = useAuthContext();
  const { createGroup } = useGroupService();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [group, setGroup] = useState<Group | null>(null);

  const handleSave = (
    formValues: GroupForm,
    actions: FormikHelpers<GroupForm>
  ) => {
    const { name } = formValues;

    setSuccessMessage("");
    setErrorMessage("");
    setLoading(true);

    createGroup(name).then(
      (group) => {
        if (group) {
          actions.resetForm();
          setSuccessMessage("Group created successfully");
          setLoading(false);
          close();
        }
      },
      (error) => {
        handleError(error, setErrorMessage, setLoading);
      }
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          width: 600,
          transform: "translate(-50%, -50%)",
        },
      }}
      contentLabel="New Staff Group Modal"
    >
      <Formik
        initialValues={
          {
            name: group?.name || "",
          } as GroupForm
        }
        validate={(values) => {
          const errors = {} as any;
          if (!values.name) {
            errors.name = "Name is required";
          }
          return errors;
        }}
        onSubmit={(formValues, actions) => {
          handleSave(formValues, actions);
        }}
      >
        <Form>
          <h3>New Staff Group</h3>
          <div className="form-group mt-3">
            <label htmlFor="name">Name</label>
            <Field
              name="name"
              type="text"
              placeholder="Enter Group name"
              className="form-control"
            />
            <ErrorMessage
              name="name"
              component="div"
              className="alert alert-danger p-2"
            />
          </div>

          <div className="form-group mt-3">
            <button
              onClick={close}
              type="button"
              className="btn btn-sw me-4"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-sw"
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm me-1"></span>
              )}
              {"Create"}
            </button>
          </div>
          {errorMessage && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            </div>
          )}
          {successMessage && (
            <div className="form-group">
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            </div>
          )}
        </Form>
      </Formik>
    </Modal>
  );
};

export default GroupNew;
