import { useState, useEffect } from "react";
const getPasswordEntropy = require("fast-password-entropy");

interface PasswordStrengthProps {
  password: string;
  acceptableStrength: number;
  isAcceptableStregth: (isAcceptableStregth: boolean) => void;
}

export default function PasswordStrength({
  password,
  acceptableStrength,
  isAcceptableStregth,
}: PasswordStrengthProps) {
  const [entropy, setEntropy] = useState(0);
  const createPasswordScore = (score: number) => {
    switch (true) {
      case score < 28:
        return "Weakest";
      case score < 36:
        return "Weak";
      case score < 60:
        return "Fair";
      case score < 128:
        return "Good";
      case score >= 128:
        return "Strong";
      default:
        return "Weakest";
    }
  };

  useEffect(() => {
    const passwordEntropy = getPasswordEntropy(password);
    setEntropy(passwordEntropy);
    isAcceptableStregth(passwordEntropy >= acceptableStrength);
  }, [acceptableStrength, isAcceptableStregth, password]);

  return (
    <>
      <div className="progress password-strength">
        <div
          className={`progress-bar ${createPasswordScore(
            entropy
          ).toLowerCase()}`}
          role="progressbar"
          aria-valuenow={entropy}
          aria-valuemin={0}
          style={{ width: (entropy / acceptableStrength) * 100 + "%" }}
        ></div>
      </div>
      {password && (
        <span className="password-strength-score">
          <strong>Password strength:</strong> {createPasswordScore(entropy)}
        </span>
      )}
    </>
  );
}
