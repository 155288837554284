import { faSave } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatObjectAsDate, formatDate, handleError } from "../common/utils";
import { useMatterService } from "../services/matter.service";
import { Matter, MatterForm } from "../types/matter.types";

interface MatterEditProps {
  matter: Matter | null;
}

export default function MatterEdit({ matter }: MatterEditProps) {
  const { createMatter, updateMatter } = useMatterService();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  const matterId = params?.matterId;
  const isNew = matterId === "new";

  const handleSave = (
    formValues: MatterForm,
    actions: FormikHelpers<MatterForm>
  ) => {
    setSuccessMessage("");
    setErrorMessage("");
    setLoading(true);

    if (isNew) {
      createMatter(formValues).then(
        (matter) => {
          if (matter) {
            actions.resetForm();
            setSuccessMessage("Matter created successfully");
            setLoading(false);
            navigate(`/admin/matters/${matter.id}/details`);
          }
        },
        (error) => {
          handleError(error, setErrorMessage, setLoading);
        }
      );
    } else {
      const updatedMatter = { ...matter, ...formValues } as Matter;
      updateMatter(updatedMatter).then(
        () => {
          setSuccessMessage("Matter updated successfully");
          setLoading(false);
          navigate(`/admin/matters/${matterId}/details`);
        },
        (error) => {
          handleError(error, setErrorMessage, setLoading);
        }
      );
    }
  };

  const handleCancel = () => {
    navigate("/admin");
  };

  return (
    <div className="edit-matter">
      <Formik
        initialValues={
          {
            name: matter?.name || "",
            numberSignaturesRequired: matter?.numberSignaturesRequired || 0,
            dueDate: formatObjectAsDate(matter?.dueDate),
          } as MatterForm
        }
        validate={(values) => {
          const errors = {} as any;
          if (values.name === "") {
            errors.name = "Name is required";
          }
          if (values.numberSignaturesRequired < 1) {
            errors.numberSignaturesRequired = "Signatures are required";
          }
          return errors;
        }}
        onSubmit={(formValues, actions) => {
          handleSave(formValues, actions);
        }}
      >
        {(formik) => (
          <Form>
            <div className="form-group mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <Field
                name="name"
                type="text"
                placeholder="Enter matter name"
                className="form-control"
                value={formik.values.name}
              />
              <ErrorMessage
                name="name"
                component="div"
                className="alert alert-danger p-2"
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="numberSignaturesRequired" className="form-label">
                Number of Signatures Required
              </label>
              <Field
                name="numberSignaturesRequired"
                type="number"
                placeholder="Enter signatures required"
                className="form-control"
                value={formik.values.numberSignaturesRequired}
              />
              <ErrorMessage
                name="numberSignaturesRequired"
                component="div"
                className="alert alert-danger p-2"
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="dueDate">Due Date</label>
              <Field
                name="dueDate"
                type="date"
                placeholder="Enter date file"
                className="form-control"
                value={formik.values.dueDate}
              />
              <ErrorMessage
                name="dueDate"
                component="div"
                className="alert alert-danger p-2"
              />
            </div>

            <div className="form-group mb-3">
              <button type="submit" className="btn btn-sw" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm me-1"></span>
                )}
                <FontAwesomeIcon icon={faSave} className="me-2" />
                Save
              </button>
            </div>
            {errorMessage && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              </div>
            )}
            {successMessage && (
              <div className="form-group">
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
