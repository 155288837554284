import { useCallback } from "react";
import { Group } from "../types/group.types";
import { Task } from "../types/task.types";
import { User, UserStats } from "../types/user.types";
import { useAuthenticatedRequestService } from "./service.base";

export function useGroupService() {
  const GROUPS_PATH = 'staffgroups/';
  const { authGet, authPost, authPut, authDelete } = useAuthenticatedRequestService();

  const getGroups = useCallback(async function(): Promise<Group[]> {
    return await authGet(GROUPS_PATH);
  }, [authGet]);

  const getGroupById = useCallback(async function (id: string): Promise<Group> {
    return await authGet(GROUPS_PATH + id);
  }, [authGet]);
  
  const createGroup = useCallback(async function (name: string): Promise<Group | null> {
    return await authPost(GROUPS_PATH, { name });
  }, [authPost]);

  const updateGroup = useCallback(async function (id: string, name: string): Promise<null> {
      return await authPut(GROUPS_PATH + id, { name });
  }, [authPut]);
  
  const deleteGroup = useCallback(async function (id: string): Promise<null> {
    return await authDelete(GROUPS_PATH + id);
  }, [authDelete]);

  const getGroupTasks = useCallback(async function(groupId: string, matterId: string, assigned: boolean): Promise<Task[]> {
      return await authGet(GROUPS_PATH + groupId + `/tasks?matterId=${matterId}&assigned=${assigned}`);
  }, [authGet]);

  const postAssignedGroupTasks = useCallback(async function(groupId: string, matterId: string, taskIds: string[]): Promise<number> {
      return await authPost(GROUPS_PATH  + groupId + `/tasks?matterId=${matterId}`, taskIds);
  }, [authPost]);

  const deleteAssingedGroupTask = useCallback(async function (groupId: string, taskId: string): Promise<null> {
    return await authDelete(GROUPS_PATH + groupId + "/tasks/" + taskId);
  }, [authDelete]);

  const getGroupUsers = useCallback(async function(matterId: string, groupId: string, assigned: boolean): Promise<User[]> {
    return await authGet("matters/" + matterId + "/" + GROUPS_PATH + groupId + "/users?assigned=" + assigned);
  }, [authGet]);

  const getGroupUsersStats = useCallback(async function(matterId: string, groupId: string): Promise<UserStats[]> {
    return await authGet("matters/" + matterId + "/" + GROUPS_PATH + groupId + "/users/stats");
  }, [authGet]);

  const postAssignedGroupUsers = useCallback(async function(matterId: string, groupId: string, userIds: string[]): Promise<number> {
      return await authPost("matters/" + matterId + "/" + GROUPS_PATH  + groupId + "/users", userIds);
  }, [authPost]);

  const deleteAssingedGroupUser = useCallback(async function (matterId: string, groupId: string, userId: string): Promise<null> {
    return await authDelete("matters/" + matterId + "/" + GROUPS_PATH + groupId + "/users/" + userId);
  }, [authDelete]);

const funcs = {
    getGroups,
    getGroupById,
    createGroup,
    updateGroup,
    deleteGroup,
    getGroupTasks,
    postAssignedGroupTasks,
    deleteAssingedGroupTask,
    getGroupUsers,
    postAssignedGroupUsers,
    deleteAssingedGroupUser,
    getGroupUsersStats
  };
  return funcs;
}
