import { groupBy, orderBy, sumBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../AuthProvider";
import { useGroupService } from "../services/group.service";
import { useUserService } from "../services/user.service";
import { Group } from "../types/group.types";
import { User, Roles } from "../types/user.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleUp,
  faSquarePlus,
  faTrashCan,
} from "@fortawesome/free-regular-svg-icons";
import { formatDateString, handleError } from "../common/utils";
import ConfirmModal from "./confirm-modal";
import GroupNewModal from "./group-new.component";
import InviteReviewerModal from "./invite-reviewer.component";
import { useWorkService } from "../services/work.service";
import { WorkStatus, WorkSummaryByMatter } from "../types/work.types";

export default function ManagerBoard() {
  const { authenticatedUser } = useAuthContext();
  const [matters, setMatters] = useState<WorkSummaryByMatter[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [reviewers, setReviewers] = useState<User[]>([]);

  const [loadingMatters, setLoadingMatters] = useState(false);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [loadingReviewers, setLoadingReviewers] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [groupIdToDelete, setGroupIdToDelete] = useState<string>();
  const [userIdToDelete, setUserIdToDelete] = useState<string>();
  const [showGroupNewModal, setShowGroupNewModal] = useState(false);
  const [showInviteReviewerModal, setShowInviteReviewerModal] = useState(false);
  const [showConfirmDeleteGroupModal, setShowConfirmDeleteGroupModal] =
    useState(false);
  const [showConfirmDeleteUserModal, setShowConfirmDeleteUserModal] =
    useState(false);
  const navigate = useNavigate();
  const { getWorkStatus } = useWorkService();
  const { getGroups, deleteGroup } = useGroupService();
  const { getReviewers, deleteReviewer } = useUserService();

  const loadMatters = useCallback(() => {
    setErrorMessage("");
    setLoadingMatters(true);
    getWorkStatus().then(
      (matters) => {
        const workByMatter = groupBy(matters, ({ matterName }) => matterName);
        const workSummaryByMatters = Object.entries(workByMatter).map(
          ([matterName, workStatuses]) => ({
            matterName,
            matterDueDate: workStatuses[0].matterDueDate,
            completed:
              workStatuses.filter(
                (ws) => ws.workStatus == WorkStatus.Completed
              )[0]?.count ?? 0,
            total: sumBy(workStatuses, (ws) => ws.count),
          })
        );
        setMatters(workSummaryByMatters);
        setLoadingMatters(false);
      },
      (error) => {
        handleError(error, setErrorMessage, setLoadingMatters);
      }
    );
  }, [getWorkStatus]);

  const loadGroups = useCallback(() => {
    setErrorMessage("");
    setLoadingGroups(true);

    getGroups().then(
      (groups) => {
        setGroups(orderBy(groups, (group) => group.name));
        setLoadingGroups(false);
      },
      (error) => {
        handleError(error, setErrorMessage, setLoadingGroups);
      }
    );
  }, [getGroups]);

  const loadReviewers = useCallback(() => {
    setLoadingReviewers(true);
    getReviewers().then(
      (reviewers) => {
        setReviewers(orderBy(reviewers, (reviewer) => reviewer.fullName));
        setLoadingReviewers(false);
      },
      (error) => {
        handleError(error, setErrorMessage, setLoadingReviewers);
      }
    );
  }, [getReviewers]);

  useEffect(() => {
    if (authenticatedUser?.roleId !== Roles.Manager) {
      navigate("/login");
    } else {
      loadMatters();
      loadGroups();
      loadReviewers();
    }
  }, [
    authenticatedUser?.roleId,
    loadMatters,
    loadGroups,
    navigate,
    loadReviewers,
  ]);

  const handleDeleteGroup = () => {
    if (groupIdToDelete) {
      setErrorMessage("");
      setLoadingGroups(true);

      deleteGroup(groupIdToDelete).then(
        () => {
          setLoadingGroups(false);
          loadGroups();
        },
        (error) => {
          handleError(error, setErrorMessage, setLoadingGroups);
        }
      );
    }
  };

  const handleDeleteReviewer = () => {
    if (userIdToDelete) {
      setErrorMessage("");
      setLoadingReviewers(true);

      deleteReviewer(userIdToDelete).then(
        () => {
          setLoadingReviewers(false);
          loadReviewers();
        },
        (error) => {
          handleError(error, setErrorMessage, setLoadingReviewers);
        }
      );
    }
  };

  return (
    <div className="container">
      <div className="d-flex flex-row mb-4">
        <div className="ms-auto" style={{ marginRight: "-12px" }}>
          <Link to="/manager/flagged" className="btn btn-sw btn-sm btn-outline">
            <FontAwesomeIcon icon={faCircleUp} className="me-2" />
            Flagged Work
          </Link>
        </div>
      </div>
      <div className="row tablet-view justify-content-start">
        <div className="col-4 tablet-left d-flex flex-column">
          <div className="tablet-buffer d-flex flex-fill flex-column mb-2">
            <div className="mb-auto">
              <span>Manager</span>
              <h2>Home</h2>
            </div>
            <div className="">
              <Link to="/manager/invite" className="btn btn-light btn-outline">
                <FontAwesomeIcon icon={faSquarePlus} className="me-2" />
                Invite Multiple Reviewers
              </Link>
            </div>
          </div>
        </div>
        <div className="col-8 tablet-right">
          <div className="tablet-buffer">
            <div className="d-flex left-right">
              <h3>Matters</h3>
            </div>
            <div
              style={{
                maxHeight: 400,
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {matters?.length > 0 && (
                <table className="sw">
                  <thead>
                    <tr className="d-flex">
                      <th scope="col" className="col-sm-4">
                        Name
                      </th>
                      <th scope="col" className="col-sm-3">
                        Due Date
                      </th>
                      <th scope="col" className="col-sm-5">
                        Progress
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {matters.map((matter) => (
                      <tr
                        key={matter.matterName}
                        className="d-flex border-bottom"
                      >
                        <td className="col-sm-4">{matter.matterName}</td>
                        <td className="col-sm-3">
                          {formatDateString(matter.matterDueDate)}
                        </td>
                        <td className="col-sm-5">
                          <progress
                            style={{ width: "100%" }}
                            max={matter.total}
                            value={matter.completed}
                            title={`${matter.completed} out of ${matter.total}`}
                          ></progress>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {!Object.entries(matters)?.length && !loadingMatters && (
                <div className="list-group-item list-group-item-light">
                  There are no matters
                </div>
              )}
            </div>
            <div className="d-flex left-right mt-4">
              <h3>Staff Groups</h3>
              <button
                onClick={() => setShowGroupNewModal(true)}
                className="btn btn-sw"
              >
                <FontAwesomeIcon icon={faSquarePlus} className="me-2" />
                New Staff Group
              </button>
            </div>
            <div
              style={{
                maxHeight: 400,
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {groups?.length > 0 && (
                <table className="sw">
                  <thead>
                    <tr className="d-flex">
                      <th scope="col" className="col-sm-11">
                        Name
                      </th>
                      <th scope="col" className="col-sm-1"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {groups?.map((group) => (
                      <tr key={group.id} className="d-flex border-bottom">
                        <td
                          className="col-sm-11"
                          onClick={() =>
                            navigate("/manager/groups/" + group.id)
                          }
                        >
                          {group.name}
                        </td>
                        <td className="col-sm-1">
                          <a
                            role="button"
                            onClick={() => {
                              setShowConfirmDeleteGroupModal(true);
                              setGroupIdToDelete(group.id.toString());
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashCan} />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {!groups?.length && !loadingGroups && (
                <div className="list-group-item list-group-item-light">
                  There are no staff groups
                </div>
              )}

              <ConfirmModal
                message="Are you sure you want to delete this staff group?"
                action={handleDeleteGroup}
                isOpen={showConfirmDeleteGroupModal}
                close={() => {
                  setShowConfirmDeleteGroupModal(false);
                }}
              />
              <GroupNewModal
                isOpen={showGroupNewModal}
                close={() => {
                  setShowGroupNewModal(false);
                  loadGroups();
                }}
              />
            </div>

            <div className="d-flex left-right mt-4">
              <h3>Reviewers</h3>
              <button
                onClick={() => setShowInviteReviewerModal(true)}
                className="btn btn-sw"
              >
                <FontAwesomeIcon icon={faSquarePlus} className="me-2" />
                Invite Reviewer
              </button>
            </div>
            <div
              style={{
                maxHeight: 400,
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {reviewers?.length > 0 && (
                <table className="sw">
                  <thead>
                    <tr className="d-flex">
                      <th scope="col" className="col-sm-5">
                        Name
                      </th>
                      <th scope="col" className="col-sm-6">
                        Email
                      </th>
                      <th scope="col" className="col-sm-1"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {reviewers?.map((reviewer) => (
                      <tr key={reviewer.email} className="d-flex border-bottom">
                        <td className="col-sm-5">{reviewer.fullName}</td>
                        <td className="col-sm-6">{reviewer.email}</td>
                        <td className="col-sm-1">
                          <a
                            onClick={() => {
                              setShowConfirmDeleteUserModal(true);
                              setUserIdToDelete(reviewer.id.toString());
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashCan} />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {!reviewers?.length && !loadingReviewers && (
                <div className="list-group-item list-group-item-light">
                  There are no reviewers
                </div>
              )}
              <ConfirmModal
                message="Are you sure you want to delete this reviewer?"
                action={handleDeleteReviewer}
                isOpen={showConfirmDeleteUserModal}
                close={() => {
                  setShowConfirmDeleteUserModal(false);
                }}
              />
              <InviteReviewerModal
                isOpen={showInviteReviewerModal}
                close={() => {
                  setShowInviteReviewerModal(false);
                  loadReviewers();
                }}
              />
            </div>
          </div>

          {errorMessage && (
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
