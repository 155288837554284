import { useCallback } from 'react';
import { Deficiency, FullSheetDeficiency } from '../types/deficiency.types';
import { useAuthenticatedRequestService } from './service.base';

export function useDeficiencyService() {
    const DEFICIENCIES_PATH = 'deficiencies/';
    const { authGet, authPost } = useAuthenticatedRequestService();

    const getRuleDeficienciesById = useCallback(async function(matterId: string, ruleId: string): Promise<Deficiency[]> {
        return await authGet(`matters/${matterId}/${DEFICIENCIES_PATH}rules/${ruleId}`);
    }, [authGet]);

    const getFullSheetDeficienciesById = useCallback(async function(matterId: string,): Promise<FullSheetDeficiency[]> {
        return await authGet(`matters/${matterId}/${DEFICIENCIES_PATH}full`, {timeout: 5*60*1000}); // 5 mins
    }, [authGet]);

    const getDeficiencyCsv = useCallback(async function(matterId: string,): Promise<string> {
        return await authGet(`matters/${matterId}/${DEFICIENCIES_PATH}csv`);
    }, [authGet]);

    const postMatterDeficiency = useCallback(async function (matterId: string, ruleId: number, note: string | null): Promise<Deficiency> {
        return await authPost(`matters/${matterId}/${DEFICIENCIES_PATH}${ruleId}`, {note});
    }, [authPost]);

    const clearMatterDeficiency = useCallback(async function (matterId: string): Promise<Deficiency> {
        return await authPost(`matters/${matterId}/${DEFICIENCIES_PATH}clear`, {});
    }, [authPost]);

    const funcs = {
        getRuleDeficienciesById, 
        getFullSheetDeficienciesById,
        getDeficiencyCsv,
        postMatterDeficiency,
        clearMatterDeficiency,
    };
    return funcs;
}
