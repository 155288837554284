import { useCallback } from 'react';
import { Rule, RuleContextType } from '../types/rule.types';
import { ViolationCriterion } from '../types/work.types';
import { useAuthenticatedRequestService } from './service.base';


export function useRuleService() {
    const RULES_PATH = 'rules/';
    const { authGet, authPost } = useAuthenticatedRequestService();

    const getRules = useCallback(async function (matterId: string): Promise<Rule[]> {
        return await authGet(`${RULES_PATH}${matterId}`);
    }, [authGet]);

    const getMatterViolations = useCallback(async function (matterId: string): Promise<ViolationCriterion[]> {
        return await authGet(`${RULES_PATH}${matterId}/type/${RuleContextType.Matter}`);
    }, [authGet]);

    const getRuleCount = useCallback(async function (matterId: string): Promise<number> {
        return await authGet(`${RULES_PATH}count/?matterId=${matterId}`);
    }, [authGet]);

    const runRules = useCallback(async function (matterId: string) {
        await authPost(`${RULES_PATH}run/?matterId=${matterId}`, {});
    }, [authPost]);

    const funcs = {
        getRules,
        getMatterViolations,
        getRuleCount,
        runRules
    };
    return funcs;
}
