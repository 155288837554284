import Modal from "react-modal";
import Select from "react-select";
import { MultiValue, OptionProps, GroupBase, components } from "react-select";
import { useState } from "react";
import { useGroupService } from "../services/group.service";
import { handleError } from "../common/utils";

Modal.setAppElement("#root");

interface AssignUsersModalProps {
  groupId: string;
  matterId: string;
  isOpen: boolean;
  close: () => void;
  handleReloadUsers: () => void;
}

export interface UserOption {
  value: string;
  label: string;
}

export default function AssignUsersModal({
  groupId,
  matterId,
  isOpen,
  close,
  handleReloadUsers,
}: AssignUsersModalProps) {
  const { getGroupUsers, postAssignedGroupUsers } = useGroupService();
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [savingAddedUsers, setSavingAddedUsers] = useState(false);
  const [unassignedUsers, setUnassignedUsers] = useState<UserOption[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<MultiValue<UserOption>>(
    []
  );

  const loadGroupUsers = () => {
    setErrorMessage("");
    setLoadingUsers(true);

    getGroupUsers(matterId, groupId, false).then(
      (users) => {
        setLoadingUsers(false);
        setUnassignedUsers(
          users.map((User, i) => {
            return {
              value: User.id.toString(),
              label: User.fullName || User.email,
            };
          })
        );
      },
      (error) => {
        handleError(error, setErrorMessage, setLoadingUsers);
      }
    );
  };

  const handleCloseModal = () => {
    close();
    setSelectedUsers([]);
    setUnassignedUsers([]);
  };

  const handleChange = (newValue: MultiValue<UserOption>) => {
    setSelectedUsers(newValue);
  };

  const handleSave = () => {
    setErrorMessage("");
    setSavingAddedUsers(true);

    postAssignedGroupUsers(
      matterId,
      groupId,
      selectedUsers.map((user) => {
        return user.value;
      })
    ).then(
      () => {
        setSavingAddedUsers(false);
        handleReloadUsers();
        handleCloseModal();
      },
      (error) => {
        handleError(error, setErrorMessage, setSavingAddedUsers);
      }
    );
  };

  const Option = (
    props: OptionProps<UserOption, true, GroupBase<UserOption>>
  ) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <label className="ms-2">{props.label}</label>
        </components.Option>
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      onAfterOpen={() => {
        loadGroupUsers();
      }}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          width: 600,
          transform: "translate(-50%, -50%)",
        },
      }}
      contentLabel="Add Users Modal"
    >
      <div className="row">
        <div className="col">
          <div className="d-flex left-right">
            <h2>Add People</h2>
            <span
              style={{ fontSize: "1.5rem", cursor: "pointer" }}
              aria-label="Close"
              onClick={handleCloseModal}
              className="font-weight-bold"
            >
              X
            </span>
          </div>
        </div>
      </div>
      <div className="row mt-3" style={{ height: 545 }}>
        <div className="col">
          <Select
            autoFocus
            onChange={handleChange}
            options={unassignedUsers}
            isMulti
            menuIsOpen={true}
            controlShouldRenderValue={false}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option }}
            placeholder="People"
            isSearchable
            isLoading={loadingUsers}
            styles={{
              menuList: (base) => ({
                ...base,
                maxHeight: 494,
              }),
            }}
          />
        </div>
      </div>

      <div className="row align-items-end mt-3">
        <div className="col">
          <button
            type="button"
            onClick={handleSave}
            className="btn btn-sw"
            disabled={savingAddedUsers || unassignedUsers.length === 0}
          >
            {savingAddedUsers && (
              <span className="spinner-border spinner-border-sm me-1"></span>
            )}
            Save
          </button>
          {errorMessage && (
            <div className="form-group d-inline ms-2">
              <div className="alert alert-danger d-inline" role="alert">
                {errorMessage}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
