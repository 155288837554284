import { useCallback } from 'react';
import { DataProcessingLog } from '../types/data-processing-log.types';
import { useAuthenticatedRequestService } from './service.base';

export function useDataProcessingLogsService() {
    const LOGS_PATH = 'dataprocessinglogs/';
    const { authGet } = useAuthenticatedRequestService();

    const getExternalDataLogsByPartId = useCallback(async function(externalDataSourcePartId: string): Promise<DataProcessingLog[]> {
        return await authGet(`${LOGS_PATH}externaldata/${externalDataSourcePartId}`);
    }, [authGet]);

    const getSignatureSheetLogsByUploadId = useCallback(async function(signatureSheetUploadId: string): Promise<DataProcessingLog[]> {
        return await authGet(`${LOGS_PATH}signaturesheet/${signatureSheetUploadId}`);
    }, [authGet]);

    const funcs = {
        getExternalDataLogsByPartId,
        getSignatureSheetLogsByUploadId,
    };
    return funcs;
}

