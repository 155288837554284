import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../AuthProvider";
import { useGroupService } from "../services/group.service";
import { Group } from "../types/group.types";
import { Task } from "../types/task.types";
import { Roles, UserStats } from "../types/user.types";
import AssignTasksModal from "./tasks-modal";
import AssignUsersModal from "./assign-users-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleLeft,
  faSave,
  faTrashCan,
} from "@fortawesome/free-regular-svg-icons";
import { useMatterService } from "../services/matter.service";
import { Matter } from "../types/matter.types";
import { handleError } from "../common/utils";
import GroupNew from "./group-new.component";

interface GroupForm {
  name: string;
}

export default function GroupDetails() {
  const { authenticatedUser } = useAuthContext();
  const {
    getGroupById,
    getGroupTasks,
    getGroupUsers,
    getGroupUsersStats,
    updateGroup,
    deleteAssingedGroupTask,
    deleteAssingedGroupUser,
  } = useGroupService();
  const { getAllMatters } = useMatterService();

  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingTasks, setLoadingTasks] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageAssigned, setErrorMessageAssigned] = useState("");
  const [group, setGroup] = useState<Group | null>(null);
  const [groupTasks, setGroupTasks] = useState<Task[]>([]);
  const [groupUsers, setGroupUsers] = useState<UserStats[]>([]);

  const [showTasksModal, setShowTasksModal] = useState(false);
  const [showUsersModal, setShowUsersModal] = useState(false);
  const [matters, setMatters] = useState<Matter[]>([]);
  const [selectedMatterId, setSelectedMatterId] = useState("");

  const groupId = params?.groupId;

  const loadAssignedTasks = useCallback(() => {
    if (selectedMatterId) {
      setErrorMessageAssigned("");
      setLoadingTasks(true);
      getGroupTasks(groupId!, selectedMatterId, true).then(
        (groupTasks) => {
          setGroupTasks(groupTasks);
          setLoadingTasks(false);
        },
        (error) => {
          handleError(error, setErrorMessageAssigned, setLoadingTasks);
        }
      );
    }
  }, [groupId, selectedMatterId, getGroupTasks]);

  const loadAssignedUsers = useCallback(() => {
    if (!selectedMatterId) {
      setGroupUsers([]);
      return;
    }
    setErrorMessageAssigned("");
    setLoadingUsers(true);
    getGroupUsersStats(selectedMatterId, groupId!).then(
      (groupUsers) => {
        setGroupUsers(groupUsers);
        setLoadingUsers(false);
      },
      (error) => {
        handleError(error, setErrorMessageAssigned, setLoadingUsers);
      }
    );
  }, [groupId, selectedMatterId, getGroupUsersStats]);

  useEffect(() => {
    if (authenticatedUser?.roleId !== Roles.Manager) {
      navigate("/login");
    } else {
      if (groupId) {
        setErrorMessage("");
        setLoading(true);
        getGroupById(groupId).then(
          (group) => {
            setGroup(group);
            setLoading(false);
          },
          (error) => {
            handleError(error, setErrorMessage, setLoading);
          }
        );

        loadAssignedTasks();
        loadAssignedUsers();

        setErrorMessageAssigned("");
        getAllMatters().then(
          (matters) => {
            setMatters(matters);
            if (matters.length === 1) {
              const matterId = matters[0].id.toString();
              setSelectedMatterId(matterId);
              // loadAssignedUsers(); // This should happen when setting the matterId
            }
          },
          (error) => {
            handleError(error, setErrorMessageAssigned, undefined);
          }
        );
      }
    }
  }, [
    groupId,
    authenticatedUser?.roleId,
    getGroupById,
    getAllMatters,
    loadAssignedTasks,
    loadAssignedUsers,
    navigate,
  ]);

  const handleSave = (
    formValues: GroupForm,
    actions: FormikHelpers<GroupForm>
  ) => {
    const { name } = formValues;

    setSuccessMessage("");
    setErrorMessage("");
    setLoading(true);

    updateGroup(group?.id.toString()!, name).then(
      () => {
        setSuccessMessage("Group updated successfully");
        setLoading(false);
      },
      (error) => {
        handleError(error, setErrorMessage, setLoading);
      }
    );
  };

  const calculateWorkerSpeedPercentage = useCallback((timePerTask: number) => {
    if (timePerTask === 0) return 0;
    const normalized0to1 = timePerTask / (1 + timePerTask); // where 0 is fast and 1 is slow
    return 100 - normalized0to1 * 100; // now 100 is fast, and 0 is slow
  }, []);

  const handleUnassignTask = useCallback(
    (taskId: string) => {
      setErrorMessageAssigned("");
      setLoadingTasks(true);

      deleteAssingedGroupTask(groupId!, taskId).then(
        () => {
          setLoadingTasks(false);
          loadAssignedTasks();
        },
        (error) => {
          handleError(error, setErrorMessageAssigned, setLoadingTasks);
        }
      );
    },
    [groupId, deleteAssingedGroupTask, loadAssignedTasks]
  );

  const handleUnassignUser = useCallback(
    (userId: string) => {
      setErrorMessageAssigned("");
      setLoadingUsers(true);

      deleteAssingedGroupUser(selectedMatterId, groupId!, userId).then(
        () => {
          setLoadingUsers(false);
          loadAssignedUsers();
        },
        (error) => {
          handleError(error, setErrorMessageAssigned, setLoadingUsers);
        }
      );
    },
    [groupId, selectedMatterId, deleteAssingedGroupUser, loadAssignedUsers]
  );

  return group ? (
    <div className="container">
      <div className="d-flex flex-row mb-4">
        <div className="me-auto" style={{ marginLeft: "-12px" }}>
          <Link to="/manager" className="btn btn-sw btn-sm btn-outline">
            <FontAwesomeIcon icon={faCircleLeft} className="me-2" />
            Back to Home
          </Link>
        </div>
      </div>
      <div className="row tablet-view">
        <div className="col-4 tablet-left d-flex flex-column">
          <div className="tablet-buffer d-flex flex-fill flex-column mb-3">
            <span>Group</span>
            <h2>{group?.name}</h2>
          </div>
        </div>
        <div className="col-8 tablet-right">
          <div className="tablet-buffer">
            <Formik
              initialValues={
                {
                  name: group?.name || "",
                } as GroupForm
              }
              validate={(values) => {
                const errors = {} as any;
                if (!values.name) {
                  errors.name = "Name is required";
                }
                return errors;
              }}
              onSubmit={(formValues, actions) => {
                handleSave(formValues, actions);
              }}
            >
              <Form>
                <div className="form-group mb-3">
                  <label htmlFor="name">Name</label>
                  <Field
                    name="name"
                    type="text"
                    placeholder="Enter template name"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="alert alert-danger p-2"
                  />
                </div>

                <div className="form-group mb-3">
                  <button
                    type="submit"
                    className="btn btn-sw"
                    disabled={loading}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm me-1"></span>
                    )}
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    Save
                  </button>
                </div>
                {errorMessage && (
                  <div className="form-group mb-3">
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  </div>
                )}
                {successMessage && (
                  <div className="form-group mb-3">
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  </div>
                )}
              </Form>
            </Formik>

            <div className="mb-3">
              <h3>Matter</h3>

              <select
                style={{ minWidth: "25%" }}
                value={selectedMatterId}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setSelectedMatterId(e.currentTarget.value);
                }}
                className="form-control w-auto d-inline align-middle"
              >
                <option value="">Select Matter</option>
                {matters.map((matter) => {
                  return (
                    <option key={matter.id} value={matter.id}>
                      {matter.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="d-flex left-right">
              <h3>Tasks</h3>
              <button
                className="btn btn-outline-secondary"
                onClick={() => setShowTasksModal(true)}
              >
                Add Tasks
              </button>
            </div>

            {loadingTasks && (
              <div className="pl-1">
                <span className="spinner-border spinner-border-sm me-1 text-secondary"></span>
                <span className="">Loading...</span>
              </div>
            )}

            {groupTasks?.length > 0 && (
              <table className="sw">
                <thead>
                  <tr className="d-flex">
                    <th scope="col" className="col-sm-11">
                      Name
                    </th>
                    <th scope="col" className="col-sm-1"></th>
                  </tr>
                </thead>
                <tbody>
                  {groupTasks?.map((groupTask) => (
                    <tr key={groupTask.id} className="d-flex border-bottom">
                      <td className="col-sm-11">{groupTask.name}</td>
                      <td className="col-sm-1">
                        <a
                          role="button"
                          onClick={() => {
                            handleUnassignTask(groupTask.id.toString());
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashCan} className="pt-1" />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {!groupTasks?.length && !loadingTasks && (
              <div>There are no tasks assigned</div>
            )}

            <div className="d-flex left-right mt-4">
              <h3 className="d-inline">People</h3>
              <button
                className="btn btn-outline-secondary ms-2"
                disabled={selectedMatterId === ""}
                onClick={() => {
                  setShowUsersModal(true);
                }}
              >
                Add People
              </button>
            </div>

            {loadingUsers && (
              <div className="pl-1">
                <span className="spinner-border spinner-border-sm me-1 text-secondary"></span>
                <span className="">Loading...</span>
              </div>
            )}

            <div
              style={{
                maxHeight: 300,
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {groupUsers?.length > 0 && (
                <table className="sw">
                  <thead>
                    <tr className="d-flex">
                      <th scope="col" className="col-sm-2">
                        Speed
                      </th>
                      <th scope="col" className="col-sm-6">
                        Name
                      </th>
                      <th
                        className="col-sm-1 small text-center"
                        title="Deficient"
                      >
                        Def
                      </th>
                      <th
                        className="col-sm-1 small text-center"
                        title="Total Time Worked"
                      >
                        Time
                      </th>
                      <th
                        className="col-sm-1 small text-center"
                        title="Flagged for Review"
                      >
                        FfR
                      </th>
                      <th className="col-sm-1 small"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupUsers?.map((groupUser) => (
                      <tr key={groupUser.id} className="d-flex border-bottom">
                        <td className="col-sm-2 ps-2">
                          <progress
                            style={{ width: "100%" }}
                            max="100"
                            value={calculateWorkerSpeedPercentage(
                              groupUser.workerSpeed
                            )}
                          />
                        </td>
                        <td className="col-sm-6">
                          {groupUser.fullName || groupUser.email}
                        </td>
                        <td className="col-sm-1 text-center px-1">
                          {groupUser.deficiencies}
                        </td>
                        <td className="col-sm-1 text-center px-1">
                          {groupUser.hoursWorked}
                          <span className="small">hrs</span>
                        </td>
                        <td className="col-sm-1 text-center px-1">
                          {groupUser.inReview}
                        </td>
                        <td className="col-sm-1 pr-0">
                          <a
                            role="button"
                            onClick={() => {
                              handleUnassignUser(groupUser.id.toString());
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              className="pt-1"
                            />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {!groupUsers?.length && !loadingUsers && (
                <div>There are no people assigned</div>
              )}
            </div>

            {errorMessageAssigned && (
              <div className="alert alert-danger" role="alert">
                {errorMessageAssigned}
              </div>
            )}
            <AssignTasksModal
              groupId={groupId!}
              matterId={selectedMatterId}
              isOpen={showTasksModal}
              close={() => {
                setShowTasksModal(false);
              }}
              handleReloadTasks={() => {
                loadAssignedTasks();
              }}
            ></AssignTasksModal>

            <AssignUsersModal
              groupId={groupId!}
              matterId={selectedMatterId}
              isOpen={showUsersModal}
              close={() => {
                setShowUsersModal(false);
              }}
              handleReloadUsers={() => {
                loadAssignedUsers();
              }}
            ></AssignUsersModal>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>{!loading && "This Group is invalid"}</>
  );
}
