import { useState } from "react";
import { Link } from "react-router-dom";
import { Template } from "../types/template.types";
import UploadFiles from "./upload-files.component";

interface TemplateUploadsProps {
  template: Template;
  refreshTemplateInfo: () => void;
}

export default function TemplateUploads({
  template,
  refreshTemplateInfo,
}: TemplateUploadsProps) {
  const [processing, setProcessing] = useState(false);

  return (
    <div className="mt-4">
      <h3>Upload Template Form</h3>
      <UploadFiles
        uploadUrlPath={`upload/templateForms?templateId=${template.id}`}
        fileTypes=".tiff, .pdf, .zip"
        uploadProcessing={() => setProcessing(true)}
        uploadComplete={() => {
          refreshTemplateInfo();
          setProcessing(false);
        }}
        uploadError={() => setProcessing(false)}
      />
      {processing && (
        <div>
          <span className="spinner-border spinner-border-sm me-1 text-secondary"></span>
          <span className="small">Processing file...</span>
        </div>
      )}
      {template?.fileName ? (
        <>
          {!processing && (
            <>
              <span className="small">
                {`${template?.fileName} uploaded by ${template?.uploadedBy} on ${template?.uploadedOn}.`}
              </span>
              <div className="d-flex mt-3">
                <Link
                  to={`/admin/templates/${template.id}/field-identification`}
                  className="btn btn-sw me-3"
                >
                  Identify Fields
                </Link>

                <Link
                  to={`/admin/templates/${template.id}/column-identification`}
                  className="btn btn-sw"
                >
                  Identify Columns
                </Link>
              </div>
            </>
          )}
        </>
      ) : (
        <span className="small">No template uploaded</span>
      )}
    </div>
  );
}
