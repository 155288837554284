import { useEffect } from "react";
import { ChecklistStep } from "../types/matter.types";

export interface CheckedTask {
  step: ChecklistStep;
  checked: boolean;
  url: string;
  linkText: string;
  text: string;
}

interface MatterChecklistProps {
  checklist: CheckedTask[];
  refreshChecklist: () => void;
}

export const MatterChecklist = ({ checklist, refreshChecklist }: MatterChecklistProps) => {

  useEffect(() => refreshChecklist(), [refreshChecklist]);

  return (
    <>
      {checklist.map((p) => (
        <div className="form-check" key={p.step}>
          <input
            className="form-check-input"
            type="checkbox"
            checked={p.checked}
            readOnly
          />
          <label className="form-check-label">
            <a className="checklist-link" href={p.url}>
              {p.linkText}
            </a>
            {p.text}
          </label>
        </div>
      ))}
    </>
  );
};

export default MatterChecklist;
