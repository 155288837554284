import { useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useUserService } from "../services/user.service";
import { useAuthService } from "../services/auth.service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthContext } from "../AuthProvider";
import PasswordStrengthMeter from "./password-strength.component";
import { handleError } from "../common/utils";

interface ResetPasswordForm {
  password: string;
  confirmPassword: string;
}

const ResetPassword = () => {
  const { setAuthenticatedUser } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams] = useSearchParams();
  const passwordRef = useRef<string>("");
  const isAcceptableStrengthRef = useRef<boolean>(false);
  const navigate = useNavigate();
  const { resetPassword } = useUserService();
  const { login } = useAuthService();

  const handleResetPassword = (formValues: ResetPasswordForm) => {
    const { password } = formValues;
    setSuccessMessage("");
    setErrorMessage("");
    setLoading(true);

    const token = searchParams.get("token") as string;
    if (!token) {
      setErrorMessage("Token is invalid");
      setLoading(false);
      return;
    }

    const email = searchParams.get("email") as string;
    if (!email) {
      setErrorMessage("Email is invalid");
      setLoading(false);
      return;
    }

    resetPassword(password, token).then(
      () => {
        setSuccessMessage("Your password has been reset");

        login(email, password).then(
          (user) => {
            setAuthenticatedUser(user);
            setLoading(false);

            if (user?.roleId === 1) {
              navigate("/admin");
            } else if (user?.roleId === 2) {
              navigate("/manager");
            } else if (user?.roleId === 3) {
              navigate("/work");
            }
          },
          (error) => {
            handleError(error, setErrorMessage, setLoading);
          }
        );
      },
      (error) => {
        handleError(error, setErrorMessage, setLoading);
      }
    );
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <h2 className="mb-3">Reset Password</h2>
        <Formik
          initialValues={
            {
              password: "",
              confirmPassword: "",
              token: "",
            } as ResetPasswordForm
          }
          validate={(values) => {
            const errors = {} as any;

            if (values.password === "") {
              errors.password = "Password is required";
            } else if (isAcceptableStrengthRef.current !== true) {
              errors.password = "Password is not strong enough";
            }

            if (values.confirmPassword === "") {
              errors.confirmPassword = "Confirm password is required";
            }

            if (values.confirmPassword !== values.password) {
              errors.confirmPassword = "Passwords do not match";
            }

            return errors;
          }}
          onSubmit={handleResetPassword}
        >
          {({ setFieldValue }) => (
            <Form>
              <div>
                <div className="form-group">
                  <Field
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      passwordRef.current = e.target.value;
                      setFieldValue("password", e.target.value);
                    }}
                    name="password"
                    type="password"
                    placeholder="Enter a password"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="alert alert-danger p-2"
                  />
                  <PasswordStrengthMeter
                    password={passwordRef.current}
                    acceptableStrength={60}
                    isAcceptableStregth={(isAcceptableStrength: boolean) => {
                      isAcceptableStrengthRef.current = isAcceptableStrength;
                    }}
                  />
                </div>
                <div className="form-group">
                  <Field
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirm your password"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="alert alert-danger p-2"
                  />
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-sw btn-block"
                    disabled={loading}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm me-1"></span>
                    )}
                    Reset
                  </button>
                </div>
              </div>
              {errorMessage && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                </div>
              )}
              {successMessage && (
                <div className="form-group">
                  <div className="alert alert-success" role="alert">
                    {successMessage}
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;
