export interface Matter {
  id: number;
  name: string;
  numberSignaturesRequired: number;
  isGoalDeficencies: boolean;
  dueDate: Date | null;
}

export enum ChecklistStep {
  UploadTemplates,
  SetupTemplates,
  SetupParameters,
  UploadCirculators,
  UploadVoters,
  CreateTasks,
  UploadSignatures,
  CreateRules,
  FinishTranscription,
}

export interface MatterVariable {
  matterVariableId: number | null;
  key: string;
  value: string;
}

export interface MatterForm {
  name: string;
  numberSignaturesRequired: number;
  isGoalDeficencies: boolean;
  dueDate: string | null;
}

export interface DocumentUploadInfo {
  count: number;
  lastUploadId: string;
  lastUploadedBy: string;
  lastUploadedOn: string;
}

export interface DocumentUploadInfoByCounty {
  county: string;
  count: number;
  lastUploadId: string;
  lastUploadedBy: string;
  lastUploadedOn: string;
}

export interface Progress {
  reviewed: number;
  deficient: number;
  remaining: number;
  threshold: number;
}

export interface ChecklistItem {
  step: ChecklistStep;
  status: boolean;
}