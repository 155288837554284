import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../AuthProvider";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { Roles } from "../types/user.types";
import { useTemplateService } from "../services/template.service";
import { GetNextSignatureTableColumnDTO } from "../types/template.types";
import { handleError } from "../common/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from "@fortawesome/free-regular-svg-icons";

interface ColumnIdentificationForm {
  fieldName: string;
  isSkipped: boolean;
  isHandwritten: boolean;
  isSignature: boolean;
  isDate: boolean;
  isVoterId: boolean;
}

export default function ColumnIdentification() {
  const { authenticatedUser } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [image, setImage] = useState("");
  const [transcribableField, setTranscribableField] =
    useState<GetNextSignatureTableColumnDTO>();
  const params = useParams();
  const templateId = params?.templateId;
  const fieldId = params?.fieldId;
  const formikRef = useRef<FormikProps<ColumnIdentificationForm>>(null);
  const [isComplete, setIsComplete] = useState(false);
  const navigate = useNavigate();
  const { getNextSignatureTableColumn, updateSignatureTableColumn } =
    useTemplateService();

  const getTheNextSignatureTableColumn = useCallback(() => {
    setErrorMessage("");
    setLoading(true);
    setIsComplete(false);

    getNextSignatureTableColumn(templateId!, fieldId).then(
      (tf) => {
        setLoading(false);
        setImage(tf.image.fileContents);
        setTranscribableField(tf);
        formikRef.current?.setValues({
          fieldName: tf.name || "",
          ...tf,
          isSkipped: false,
        });
      },
      (error) => {
        handleError(error, setErrorMessage, setLoading);
      }
    );
  }, [fieldId, templateId, getNextSignatureTableColumn]);

  useEffect(() => {
    if (authenticatedUser?.roleId !== Roles.Admin) {
      navigate("/login");
    } else if (templateId && authenticatedUser?.accessToken) {
      getTheNextSignatureTableColumn();
    }
  }, [
    authenticatedUser?.roleId,
    getTheNextSignatureTableColumn,
    templateId,
    navigate,
    authenticatedUser?.accessToken,
  ]);

  const handleNext = (formValues: ColumnIdentificationForm) => {
    const { fieldName, isSkipped, isHandwritten, isSignature, isDate, isVoterId } = formValues;
    setErrorMessage("");
    setLoading(true);

    if (templateId && transcribableField?.id) {
      updateSignatureTableColumn(templateId, transcribableField?.id, {
        name: fieldName,
        isSkipped,
        isHandwritten,
        isSignature,
        isDate,
        isVoterId,
      }).then(
        (tf) => {
          if (tf && tf.id) {
            formikRef.current?.resetForm();
            navigate(
              `/admin/templates/${templateId}/column-identification/${transcribableField?.id}`
            );
          } else {
            setIsComplete(true);
          }
        },
        (error) => {
          handleError(error, setErrorMessage, setLoading);
        }
      );
    }
  };

  return (
    <div className="container bg-sw-secondary">
      <div className="row bg-sw-primary">
        <div className="sw-buffer">
          <div className="d-flex flex-row mb-4">
            <div className="me-auto">
              <Link to="/admin" className="btn btn-sw btn-sm btn-outline">
                <FontAwesomeIcon icon={faCircleLeft} className="me-2" />
                Back to Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="tablet-buffer bg-sw-secondary">
        <div className="row">
          <div className="col-sm-12 col-lg-8">
            {!loading && (
              <img
                alt="signature-form"
                style={{ maxWidth: 650 }}
                src={`data:image/png;base64,${image}`}
              />
            )}
          </div>
          <div className="col-sm-12 col-lg-4">
            {isComplete ? (
              <div className="alert alert-success" role="alert">
                You've completed the column identification!{" "}
                <Link to={"/admin/templates/" + templateId}>Click here</Link> to
                return to the template.
              </div>
            ) : (
              <Formik
                innerRef={formikRef}
                initialValues={
                  {
                    fieldName: "",
                    isHandwritten: false,
                    isSkipped: false,
                  } as ColumnIdentificationForm
                }
                validate={(values) => {
                  const errors = {} as any;
                  if (values.fieldName === "") {
                    errors.fieldName = "Field name is required or skip";
                  }
                  return errors;
                }}
                onSubmit={handleNext}
              >
                <Form>
                  <div className="form-group">
                    <label htmlFor="fieldName">Name</label>
                    <Field
                      name="fieldName"
                      type="text"
                      placeholder="Enter field name"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="fieldName"
                      component="div"
                      className="alert alert-danger p-2"
                    />
                  </div>

                  <div className="form-group form-check">
                    <Field
                      id="isHandwritten"
                      name="isHandwritten"
                      placeholder="Is Handwritten"
                      className="form-check-input"
                      type="checkbox"
                    />
                    <label className="form-check-label" htmlFor="isHandwritten">
                      Is Handwritten? (Requires human review)
                    </label>
                    <ErrorMessage
                      name="isHandwritten"
                      component="div"
                      className="alert alert-danger p-2"
                    />
                  </div>

                  <div className="form-group form-check">
                    <Field
                      id="isSignature"
                      name="isSignature"
                      placeholder="Is Signature"
                      className="form-check-input"
                      type="checkbox"
                    />
                    <label className="form-check-label" htmlFor="isSignature">
                      Is Signature?
                    </label>
                    <ErrorMessage
                      name="isSignature"
                      component="div"
                      className="alert alert-danger p-2"
                    />
                  </div>

                  <div className="form-group form-check">
                    <Field
                      id="isDate"
                      name="isDate"
                      placeholder="Is Date"
                      className="form-check-input"
                      type="checkbox"
                    />
                    <label className="form-check-label" htmlFor="isDate">
                      Is Date?
                    </label>
                    <ErrorMessage
                      name="isDate"
                      component="div"
                      className="alert alert-danger p-2"
                    />
                  </div>

                  <div className="form-group form-check">
                    <Field
                      id="isVoterId"
                      name="isVoterId"
                      placeholder="Is Voter Id"
                      className="form-check-input"
                      type="checkbox"
                    />
                    <label className="form-check-label" htmlFor="isVoterId">
                      Is Voter Id?
                    </label>
                    <ErrorMessage
                      name="isVoterId"
                      component="div"
                      className="alert alert-danger p-2"
                    />
                  </div>

                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-sw"
                      disabled={loading}
                    >
                      {!loading && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-arrow-right me-1"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                          ></path>
                        </svg>
                      )}
                      {loading && (
                        <span className="spinner-border spinner-border-sm me-1"></span>
                      )}
                      Next
                    </button>
                    <button
                      type="button"
                      style={{
                        fontWeight: "bold",
                        background: "none",
                        border: "none",
                        outline: "none",
                        cursor: "pointer",
                      }}
                      className="ms-3"
                      onClick={() => {
                        formikRef.current?.resetForm();
                        handleNext({
                          fieldName: "",
                          // isHandwritten: false,
                          isSkipped: true,
                        } as ColumnIdentificationForm);
                      }}
                    >
                      SKIP THIS FIELD
                    </button>
                  </div>
                  {errorMessage && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        {errorMessage}
                      </div>
                    </div>
                  )}
                </Form>
              </Formik>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
