import Modal from "react-modal";
import Select from "react-select";
import { MultiValue, OptionProps, GroupBase, components } from "react-select";
import { useState } from "react";
import { useGroupService } from "../services/group.service";
import { handleError } from "../common/utils";

Modal.setAppElement("#root");

interface AssignTasksModalProps {
  groupId: string;
  matterId: string;
  isOpen: boolean;
  close: () => void;
  handleReloadTasks: () => void;
}

interface TaskOption {
  value: string;
  label: string;
}

export default function AssignTasksModal({
  groupId,
  matterId,
  isOpen,
  close,
  handleReloadTasks,
}: AssignTasksModalProps) {
  const { getGroupTasks, postAssignedGroupTasks } = useGroupService();
  const [loadingTasks, setLoadingTasks] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [savingAddedTasks, setSavingAddedTasks] = useState(false);
  const [unassignedTasks, setUnassignedTasks] = useState<TaskOption[]>([]);
  const [selectedTasks, setSelectedTasks] = useState<MultiValue<TaskOption>>(
    []
  );

  const loadGroupTasks = () => {
    setErrorMessage("");
    setLoadingTasks(true);

    getGroupTasks(groupId, matterId, false).then(
      (tasks) => {
        setLoadingTasks(false);
        setUnassignedTasks(
          tasks.map((task, i) => {
            return {
              value: task.id.toString(),
              label: task.name,
            };
          })
        );
      },
      (error) => {
        handleError(error, setErrorMessage, setLoadingTasks);
      }
    );
  };

  const closeModal = () => {
    close();
    setSelectedTasks([]);
    setUnassignedTasks([]);
  };

  const handleChange = (newValue: MultiValue<TaskOption>) => {
    setSelectedTasks(newValue);
  };

  const handleSave = () => {
    setErrorMessage("");
    setSavingAddedTasks(true);

    postAssignedGroupTasks(
      groupId,
      matterId,
      selectedTasks.map((task) => {
        return task.value;
      })
    ).then(
      () => {
        setSavingAddedTasks(false);
        handleReloadTasks();
        closeModal();
      },
      (error) => {
        handleError(error, setErrorMessage, setSavingAddedTasks);
      }
    );
  };

  const Option = (
    props: OptionProps<TaskOption, true, GroupBase<TaskOption>>
  ) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <label className="ms-2">{props.label}</label>
        </components.Option>
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      onAfterOpen={() => {
        loadGroupTasks();
      }}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          width: 600,
          transform: "translate(-50%, -50%)",
        },
      }}
      contentLabel="Add Tasks Modal"
    >
      <div className="row">
        <div className="col">
          <div className="d-flex left-right">
            <h2>Add Tasks</h2>
            <span
              style={{ fontSize: "1.5rem", cursor: "pointer" }}
              aria-label="Close"
              onClick={closeModal}
              className="font-weight-bold"
            >
              X
            </span>
          </div>
        </div>
      </div>
      <div className="row mt-3" style={{ height: 545 }}>
        <div className="col">
          <Select
            autoFocus
            onChange={handleChange}
            options={unassignedTasks}
            isMulti
            menuIsOpen={true}
            controlShouldRenderValue={false}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option }}
            placeholder="Tasks"
            isSearchable
            isLoading={loadingTasks}
            styles={{
              menuList: (base) => ({
                ...base,
                maxHeight: 494,
              }),
            }}
          />
        </div>
      </div>

      <div className="row align-items-end mt-3">
        <div className="col">
          <button
            type="button"
            onClick={handleSave}
            className="btn btn-sw"
            disabled={savingAddedTasks || unassignedTasks.length === 0}
          >
            {savingAddedTasks && (
              <span className="spinner-border spinner-border-sm me-1"></span>
            )}
            Save
          </button>
          {errorMessage && (
            <div className="form-group d-inline ms-2">
              <div className="alert alert-danger d-inline" role="alert">
                {errorMessage}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
