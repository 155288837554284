import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../AuthProvider";
import { useWorkService } from "../services/work.service";
import {
  Work,
  ValidationForm,
  WorkStatus,
  WorkField,
  RegisteredVoterFlags,
} from "../types/work.types";
import { FormikProps } from "formik";
import { handleError, convertFieldValuesToTypes } from "../common/utils";
import WorkItem from "./work-item.component";

export default function ReviewerBoard() {
  const { authenticatedUser } = useAuthContext();
  const { getWork, getNextWork, getPreviousWork, updateWork } =
    useWorkService();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [work, setWork] = useState<Work | null>();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isOnBreak, setIsOnBreak] = useState(false);
  const [isWorkCompleted, setIsWorkCompleted] = useState(false);
  const params = useParams();
  const workId = params?.workId;
  const formikRef = useRef<FormikProps<ValidationForm>>(null);

  const loadWork = useCallback(
    (workId) => {
      setErrorMessage("");
      setLoading(true);
      getWork(workId).then(
        (work) => {
          setLoading(false);
          setWork(work);
          navigate(`/work/${work?.workId}`);
        },
        (error) => {
          handleError(error, setErrorMessage, setLoading);
        }
      );
    },
    [getWork, navigate]
  );

  useEffect(() => {
    if (isInitialLoad && workId) {
      loadWork(workId);
    }
  }, [authenticatedUser?.roleId, workId, isInitialLoad, navigate, loadWork]);

  const loadNextWork = () => {
    setErrorMessage("");
    setLoading(true);
    setWork(null);
    getNextWork().then(
      (work) => {
        setIsInitialLoad(false);
        setLoading(false);

        if (work) {
          setWork(work);
          navigate(`/work/${work?.workId}`);
        } else {
          // All work completed
          setIsWorkCompleted(true);
        }
      },
      (error) => {
        handleError(error, setErrorMessage, setLoading);
      }
    );
  };

  const loadPreviousWork = (e: SyntheticEvent) => {
    e.preventDefault();

    if (work?.workId) {
      setErrorMessage("");
      setLoading(true);
      setWork(null);
      getPreviousWork(work?.workId.toString()).then(
        (work) => {
          setIsInitialLoad(false);
          setLoading(false);

          if (work) {
            setWork(work);
            navigate(`/work/${work?.workId}`);
          } else {
            navigate("/work");
          }
        },
        (error) => {
          handleError(error, setErrorMessage, setLoading);
        }
      );
    }
  };

  function callUpdateWork(
    workId: string,
    workStatus: WorkStatus
  ): Promise<void> {
    setLoading(true);
    setErrorMessage("");

    var work = {
      workStatusId: workStatus,
      fields: convertFieldValuesToTypes(formikRef.current?.values.fields!),
      externalDataRecordId:
        formikRef.current?.values.externalDataRecordId || null,
      registeredVoterFlags:
        formikRef.current?.values.registeredVoterFlags ||
        RegisteredVoterFlags.Unknown,
    };
    return updateWork(workId, work).then(
      () => {
        loadWork(workId);
      },
      (error) => {
        handleError(error, setErrorMessage, setLoading);
      }
    );
  }

  const flagForReview = (e: SyntheticEvent) => {
    e.preventDefault();

    if (work?.workId) {
      callUpdateWork(work.workId.toString(), WorkStatus.Flagged).then(
        loadNextWork
      );
    }
  };

  const takeBreak = (e: SyntheticEvent) => {
    e.preventDefault();

    if (work?.workId) {
      callUpdateWork(work.workId.toString(), WorkStatus.Break).then(() => {
        setIsOnBreak(true);
        setWork(null);
      });
    }
  };

  const handleSaveWork = (
    fields: WorkField[],
    externalDataRecordId?: string | null,
    registeredVoterFlags?: RegisteredVoterFlags | null
  ): Promise<void> => {
    if (!work?.workId) {
      return Promise.reject("No work");
    }
    setErrorMessage("");
    setLoading(true);
    return updateWork(work?.workId.toString(), {
      workStatusId: WorkStatus.Completed,
      fields: convertFieldValuesToTypes(fields),
      externalDataRecordId: externalDataRecordId || null,
      registeredVoterFlags:
        registeredVoterFlags || RegisteredVoterFlags.Unknown,
    }).then(
      () => {
        setLoading(false);
        setWork({ ...work, fields: convertFieldValuesToTypes(fields) });
      },
      (error) => {
        handleError(error, setErrorMessage, setLoading);
      }
    );
  };

  const handleNextWork = (
    fields: WorkField[],
    externalDataRecordId?: string | null,
    registeredVoterFlags?: RegisteredVoterFlags | null
  ) => {
    if (work?.workId) {
      handleSaveWork(fields, externalDataRecordId, registeredVoterFlags).then(
        () => {
          loadNextWork();
        },
        (error) => {
          handleError(error, setErrorMessage, setLoading);
        }
      );
    }
  };

  return (
    <div className="container">
      <div className="d-flex flex-row mb-4">
        <div style={{ marginRight: "-12px" }}>{work?.matterName}</div>
      </div>
      <div className="container bg-white">
        {!isOnBreak && !isWorkCompleted && workId && (
          <>
            {loading && (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}

            {!loading && work && (
              <WorkItem
                work={work}
                onSave={handleSaveWork}
                onNext={handleNextWork}
                onPrevious={loadPreviousWork}
                onFlagForReview={flagForReview}
                onTakeBreak={takeBreak}
                formikRef={formikRef}
                errorMessage={errorMessage}
              />
            )}
          </>
        )}

        {!isOnBreak && !isWorkCompleted && !workId && (
          <div className="row">
            <div className="col text-center mt-5">
              <h1>BEGIN TASK</h1>
              <div className="mb-3">Click continue to begin a set of tasks</div>
              <button
                type="button"
                className="btn btn-sw mb-5"
                disabled={loading}
                onClick={() => {
                  loadNextWork();
                }}
              >
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Continue
              </button>
            </div>
          </div>
        )}

        {isOnBreak && (
          <div className="row">
            <div className="col text-center mt-5">
              <h1>RESUME</h1>
              <div className="mb-3">Click resume to begin</div>
              <button
                type="button"
                className="btn btn-sw mb-5"
                onClick={() => {
                  setIsOnBreak(false);
                  loadNextWork();
                }}
              >
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Resume
              </button>
            </div>
          </div>
        )}

        {isWorkCompleted && (
          <div className="row">
            <div className="col text-center mt-5">
              <h1>ALL DONE</h1>
              <div className="mb-5">You've completed all task work</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
