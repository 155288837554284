import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { useExternalDataService } from "../services/external-data.service";
import UploadVoter from "./upload-voter.component";

export default function UploadVoters() {
  const params = useParams();
  const { getExternalDataSourceById } = useExternalDataService();

  // const [voterRegistrationUploadInfo, setVoterRegistrationUploadInfo] =
  //   useState<ExternalDataSource>();

  const [county, setCounty] = useState("");
  const [totalParts, setTotalParts] = useState("1");
  const [csvHeader, setCsvHeader] = useState<string[]>([]);
  const [csvRows, setCsvRows] = useState<string[][]>([]);

  const [uploadUrlPaths, setUploadUrlPaths] = useState<string[]>([]);
  const [filePaths, setFilePaths] = useState<string[]>([]);
  const [additionalData] = useState<{ [key: string]: string }>({});
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [headerChoices, setHeaderChoices] = useState<string[][]>([]);

  const dataSourceId = params?.dataSourceId;
  const isNew = dataSourceId === "new";

  const computeUploadUrlPaths = useCallback(
    (county: string, total: number | undefined = undefined): string[] => {
      const paths = [];
      if (!total) {
        total = Number(totalParts);
      }
      for (let num = 1; num <= total; num++) {
        paths.push(getUrlPath(county, num.toString(), total.toString()));
      }
      return paths;
    },
    [totalParts]
  );

  const populateVoterRegistrationInfo = useCallback(() => {
    if (isNew) {
      return;
    }
    getExternalDataSourceById(dataSourceId || "").then((info) => {
      setCounty(info.county);
      setTotalParts(info.totalParts?.toString() || "");

      if (info.externalDataSourceParts) {
        const existingfilePaths = info.externalDataSourceParts.map(
          (part) => part.fileName
        );
        setFilePaths(existingfilePaths);
      }
      const urlPaths = computeUploadUrlPaths(info.county, info.totalParts);
      setUploadUrlPaths(urlPaths);
    });
  }, [isNew, getExternalDataSourceById, dataSourceId, computeUploadUrlPaths]);

  const possibleHeaderChoices: string[] = [
    "",
    "VoterId",
    "Status",
    "LastName",
    "FirstName",
    "MiddleName",
    "StreetNumber",
    "Direction",
    "StreetName",
    "StreetType",
    "StreetAddress",
    "ApartmentNumber",
    "City",
    "ZipCode",
    "RegistrationDate",
    "Party",
    "BirthYear",
    "FedIdOnly",
    "County",
  ];
  /* "FederalOnlyVoter",*/
  function initializeSelectedValues(headerFields: string[]) {
    const currentSelectedValues = new Array(headerFields.length).fill("");
    let index = 0;
    for (const header of headerFields) {
      const headerNoSpaces = header.replace(" ", "");
      var headerChoice = possibleHeaderChoices.find(
        (s) => s.toLowerCase() === headerNoSpaces.toLocaleLowerCase()
      );
      if (headerChoice) {
        currentSelectedValues[index] = headerChoice;
        additionalData[headerChoice] = header;
      }
      index++;
    }
    return currentSelectedValues;
  }

  function initializeHeaderChoices(headerFields: string[]) {
    const choices = new Array(headerFields.length);
    //console.log(`possibleHeaderChoices`, possibleHeaderChoices);
    for (let index = 0; index < headerFields.length; index++) {
      choices[index] = [...possibleHeaderChoices];
    }
    // console.log(`initialized header choices`, choices);
    return choices;
  }

  function handleSetHeaderFields(headerFields: string[]) {
    // console.log(`headerfields`, headerFields);
    const selectedValues = initializeSelectedValues(headerFields);
    setSelectedValues(selectedValues);

    const currentHeaderChoices = initializeHeaderChoices(headerFields);
    removeSelectedChoiceFromUnchosenSelects(
      selectedValues,
      currentHeaderChoices
    );
    setCsvHeader(headerFields);
  }

  useEffect(() => {
    document.documentElement.style.backgroundColor = "white";
    populateVoterRegistrationInfo();
    return () => {
      document.documentElement.style.backgroundColor = "";
    };
  }, []);

  const getUrlPath = (county: string, part: string, total: string): string => {
    let urlPath = `upload/registeredvoters?county=${county}`;
    if (+total > 1) {
      urlPath += `&part=${part}&total=${total}`;
    }
    return urlPath;
  };

  const handleCountyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCounty(e.target.value);
    setUploadUrlPaths(computeUploadUrlPaths(e.target.value));
  };

  const handleTotalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTotalParts(e.target.value);
    if (e.target.value) {
      const total = Number(e.target.value);
      setUploadUrlPaths(computeUploadUrlPaths(county, total));
    } else {
      setUploadUrlPaths(computeUploadUrlPaths(county));
    }
  };

  const handleHeaderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const headerIndex = csvHeader.findIndex((s) => s === e.target.name);
    let currentSelectedValues = [...selectedValues];
    if (headerIndex >= 0) {
      currentSelectedValues[headerIndex] = e.target.value;
      setSelectedValues(currentSelectedValues);
    }

    removeSelectedChoiceFromUnchosenSelects(currentSelectedValues, [
      ...headerChoices,
    ]);
    additionalData[e.target.value] = e.target.name;
  };

  const handleSetCsvRows = (rows: string[][]) => {
    setCsvRows(rows)
  }

  function removeSelectedChoiceFromUnchosenSelects(
    currentSelectedValues: string[],
    currentHeaderChoices: string[][]
  ) {
    const nonBlankSelectedValues = currentSelectedValues.filter(
      (sv) => sv && sv.length
    );
    const remainingChoices = possibleHeaderChoices.filter(
      (x) => !nonBlankSelectedValues.includes(x)
    );
    // console.log(`removing selected choice from unchosen`, nonBlankSelectedValues, currentHeaderChoices, remainingChoices);

    let index = 0;
    for (const selectedValue of currentSelectedValues) {
      if (selectedValue.length === 0) {
        currentHeaderChoices[index] = remainingChoices;
      }
      index++;
    }
    // console.log(`new header choices`, currentHeaderChoices);
    setHeaderChoices(currentHeaderChoices);
  }

  return (
    <div className="container-fluid bg-sw-secondary">
      <div className="row bg-sw-primary">
        <div className="sw-buffer">
          <div className="d-flex flex-row mb-4">
            <div className="me-auto">
              <Link to="/admin" className="btn btn-sw btn-sm btn-outline">
                <FontAwesomeIcon icon={faCircleLeft} className="me-2" />
                Back to Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
      <h3>Upload Voter Registration Files</h3>
      <div className="container">
        <div className="row gy-2">
          <div className="col-sm-12">
            <label>County</label>
            <input
              name="county"
              className="form-control form-control-sm w-50"
              value={county}
              onChange={handleCountyChange}
            />
          </div>
        </div>
        <div className="row gy-2">
          <div className="col-sm-2">
            <label className="w-10">Total Parts</label>
            <input
              name="part"
              className="form-control form-control-sm w-20"
              value={totalParts}
              onChange={handleTotalChange}
            />
          </div>
        </div>
        {uploadUrlPaths.map((urlPath, urlIndex) => (
          <UploadVoter
            key={`uploadVoter_${urlIndex}`}
            filePath={filePaths[urlIndex]}
            possibleHeaderChoices={possibleHeaderChoices}
            urlPath={urlPath}
            urlIndex={urlIndex}
            isValid={county !== ""}
            additionalData={additionalData}
            isCsvHeaderSet={csvHeader?.length > 0}
            onSetRows={handleSetCsvRows}
            onSetHeaderFields={handleSetHeaderFields}
            onUploadComplete={populateVoterRegistrationInfo}
          />
        ))}
        {csvHeader?.length > 0 && (
          <table className="excel">
            <thead>
              <tr>
                <th></th>
                {csvHeader.map((h, i) => (
                  <th key={i}>
                    <select
                      name={h}
                      onChange={handleHeaderChange}
                      value={selectedValues[i]}
                    >
                      {headerChoices[i].map((c, i) => (
                        <option key={i.toString() + c} value={c}>
                          {c}
                        </option>
                      ))}
                    </select>
                  </th>
                ))}
              </tr>
              <tr>
                <th></th>
                {csvHeader.map((h) => (
                  <th key={h}>{h}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {csvRows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>{rowIndex + 1}</td>
                  {row.map((v, i) => (
                    <td key={rowIndex * 100 + i}>{v}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
