import { FileContentResult } from "./file.types";

export interface Work {
  workId: number | null;
  taskTypeId: number;
  externalDataRecordId: number | null;
  registeredVoterFlags: RegisteredVoterFlags;
  fields: WorkField[];
  image: FileContentResult;
  taskName: string;
  taskDescription: string;
  showWholeColumn: boolean;
  violationCriteria: ViolationCriterion[];
  matterId: number;
  matterName: string;
}

export enum Validity {
  Unknown,
  Valid,
  Invalid,
  Strikethrough,
}

export interface WorkField {
  id: number;
  name: string;
  value: string;
  validity: Validity; // string => https://github.com/jaredpalmer/formik/issues/2044
  violationCriteria: ViolationCriterion[];
  violationId: number | string | null;
  violationNote: string | null;
}

export interface ViolationCriterion {
  ruleId: number;
  name: string;
}

export enum RegisteredVoterFlags{
  Unknown,
  Valid,
  MismatchedName,
  MismatchedAddress,
  NotRegistered,
}

export interface ValidationForm {
  fields: WorkField[];
  externalDataRecordId?: string;
  registeredVoterFlags: RegisteredVoterFlags;
}

export interface MatterWorkStatusDTO {
  matterId: number;
  matterName: string;
  matterDueDate: string;
  workStatus: WorkStatus;
  count: number;
}

export interface WorkSummaryByMatter {
  matterName: string;
  matterDueDate: string;
  completed: number;
  total: number;
}

export interface FinishWorkDTO {
  fields: WorkField[];
  externalDataRecordId: string | null;
  registeredVoterFlags: RegisteredVoterFlags;
  workStatusId: WorkStatus;
}

export enum WorkStatus {
  None = 0,
  Flagged = 1,
  Break = 2, 
  Completed = 3, 
  Assigned = 4,
}

export enum TaskType {
  None = 0,
  TranscribableField = 1,
  SignatureTableColumn = 2, 
  ExternalDataSourceVerification = 4,
}

