import { useState } from "react";
import { Deficiency } from "../types/deficiency.types";
import { Rule } from "../types/rule.types";

export type DeficienciesByRuleProps = {
  rules: Rule[];
  errorRules: string;
  loadRuleDeficiencies: (ruleId: string) => void;
  loadingRuleDeficiencies: boolean;
  ruleDeficiencies: Deficiency[];
  errorRuleDeficiencies: string;
};

export default function DeficienciesByRule({
  rules,
  errorRules,
  loadRuleDeficiencies,
  loadingRuleDeficiencies,
  ruleDeficiencies,
  errorRuleDeficiencies,
}: DeficienciesByRuleProps) {
  const [selectedRuleId, setSelectedRuleId] = useState("");

  return (
    <>
      <div className="row">
        <div className="col-sm-6">
          <div className="list-group mb-3">
            <select
              value={selectedRuleId}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setSelectedRuleId(e.currentTarget.value);
                loadRuleDeficiencies(e.currentTarget.value);
              }}
              className="form-control"
            >
              <option value="">Select Rule</option>
              {rules.map((rule) => {
                return (
                  <option key={rule.id} value={rule.id}>
                    {rule.name}
                  </option>
                );
              })}
            </select>
            {errorRules && (
              <div className="alert alert-danger" role="alert">
                {errorRules}
              </div>
            )}
          </div>

          {/* cols-4, maxWidth: 250, cols-3, maxWidth: 300 */}
          {loadingRuleDeficiencies && (
            <div className="col">
              <span className="spinner-border spinner-border-sm me-1 text-secondary"></span>
              <span>Loading rule deficiencies...</span>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-sm-6">
            {ruleDeficiencies?.length > 0
              ? ruleDeficiencies?.map((deficiency) => (
                  <div key={deficiency.id} className="col border-bottom pb-2">
                    <img
                      alt="deficiency"
                      style={{ maxHeight: 120 }}
                      src={`data:image/png;base64,${deficiency.image.fileContents}`}
                    />
                    <span className="d-block small mt-3">{`Sheet: ${
                      deficiency.sheetNumber
                    }${
                      deficiency.signatureRowId !== null
                        ? ", Row: " + deficiency.rowNumber
                        : ""
                    }${
                      deficiency.signatureSheetFieldId !== null
                        ? ", Field: " + deficiency.signatureSheetFieldId
                        : ""
                    } - ${deficiency.ruleName}`}</span>
                  </div>
                ))
              : !loadingRuleDeficiencies && (
                  <div className="col-sm-12">
                    There are no rule deficiencies for this rule
                  </div>
                )}
          </div>

          {errorRuleDeficiencies && (
            <div className="alert alert-danger" role="alert">
              {errorRuleDeficiencies}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
