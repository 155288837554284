import { useCallback } from "react";
import { User } from "../types/user.types";
import { useAuthenticatedRequestService, useRequestService } from "./service.base";

const callbackUrl = process.env.REACT_APP_FRONTEND_URL;

export function useUserService() {
  const USERS_PATH = 'users';
  const { authGet, authPost, authDelete } = useAuthenticatedRequestService();
  const { httpPost } = useRequestService();

  const invite = useCallback(async function (email: string, roleId: string): Promise<User> {
    return await authPost(USERS_PATH + "/invite?callBackUrl=" + callbackUrl + "register", { email, roleId });
  }, [authPost]);

  const register = useCallback(async function (
    fullname: string,
    shortname: string,
    phoneNumber: string,
    password: string,
    token: string) {
    return await httpPost(USERS_PATH + "/validate", { fullname, shortname, phoneNumber, password, token });
  }, [httpPost]);

  const requestPasswordReset = useCallback(async function (email: string): Promise<null> {
    return await httpPost(USERS_PATH + "/sendresetpassword?callBackUrl=" + callbackUrl + "reset-password", { email });
  }, [httpPost]);

  const resetPassword = useCallback(async function (password: string, token: string): Promise<null> {
    return await authPost(USERS_PATH + "/resetpassword", { password, token });
  }, [authPost]);

  const getReviewers = useCallback(async function (): Promise<User[]> {
    return await authGet(USERS_PATH + "/reviewers");
  }, [authGet]);

  const deleteReviewer = useCallback(async function (id: string): Promise<User[]> {
    return await authDelete(`${USERS_PATH}/${id}`);
  }, [authDelete]);

  const funcs = {
    invite,
    register,
    requestPasswordReset,
    resetPassword,
    getReviewers,
    deleteReviewer,
  };
  return funcs;
}
