import {
  faCircleLeft,
  faPenToSquare,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../AuthProvider";
import { formatDateString, handleError } from "../common/utils";
import { useMatterService } from "../services/matter.service";
import { Matter } from "../types/matter.types";
import { Roles } from "../types/user.types";
import MatterEdit from "./matter-edit.component";

export default function AdminMatterEdit() {
  const { authenticatedUser } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [matter, setMatter] = useState<Matter | null>(null);
  const { getMatterById } = useMatterService();
  const navigate = useNavigate();
  const params = useParams();
  const matterId = params?.matterId;
  const isNew = matterId === "new";

  useEffect(() => {
    if (authenticatedUser?.roleId !== Roles.Admin) {
      navigate("/login");
    } else if (!isNew && matterId) {
      setLoading(true);
      getMatterById(matterId).then(
        (matter) => {
          setLoading(false);
          setMatter(matter);
        },
        (error) => {
          handleError(error, setErrorMessage, setLoading);
        }
      );
    }
  }, [isNew, matterId, authenticatedUser?.roleId, getMatterById, navigate]);

  return isNew || matter ? (
    <div className="container">
      <div className="d-flex flex-row mb-4">
        <div className="me-auto" style={{ marginLeft: "-12px" }}>
          <Link to={`/admin/matter/${matterId}/details`} className="btn btn-sw btn-sm btn-outline">
            <FontAwesomeIcon icon={faCircleLeft} className="me-2" />
            Back to Matter
          </Link>
        </div>
      </div>
      <div className="row tablet-view">
        <div className="col-4 tablet-left d-flex flex-column">
          <div className="tablet-buffer d-flex flex-fill flex-column mb-3">
            <div>
              <span>Matter</span>
              <h2>{matter?.name || "New"}</h2>
              {!isNew && (
                <>
                  <span>
                    {matter?.numberSignaturesRequired} due by{" "}
                    {formatDateString(matter?.dueDate?.toString())}
                  </span>
                  <a role="button" onClick={() => {}}>
                    <FontAwesomeIcon icon={faPenToSquare} className="ms-2" />
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-8 tablet-right">
          <div className="tablet-buffer">
            <MatterEdit matter={matter} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>{!loading && "This matter is invalid"}</>
  );
}
