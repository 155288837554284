import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faSquarePlus } from "@fortawesome/free-regular-svg-icons";

import { useAuthContext } from "../AuthProvider";
import { useExternalDataService } from "../services/external-data.service";

import { Roles } from "../types/user.types";
import { ExternalDataSource, UploadType } from "../types/external-data.types";
import { formatDateString, handleError } from "../common/utils";

import ConfirmModal from "./confirm-modal";

interface AdminDataFilesProps {
  uploadType: UploadType;
}

export default function AdminDataFiles({ uploadType }: AdminDataFilesProps) {
  const { authenticatedUser } = useAuthContext();
  const navigate = useNavigate();
  const { checkExternalData, getAllExternalData, deleteExternalData } = useExternalDataService();
  const [dataSources, setDataSources] = useState<ExternalDataSource[]>([]);
  const [loadingDataSources, setLoadingDataSources] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [dataSourceIdToDelete, setDataSourceIdToDelete] = useState<string>();
  const [countOfRowsToDelete, setCountOfRowsToDelete] = useState<number>(0);
  const [showDataSourceConfirmModal, setShowDataSourceConfirmModal] =
    useState(false);

  const urlPath = uploadType.toString()[0].toLowerCase() + uploadType.toString().substring(1) + 's'

  const loadDataSources = useCallback(() => {
    setErrorMessage("");
    setLoadingDataSources(true);

    getAllExternalData(uploadType).then(
      (dataSources) => {
        setDataSources(dataSources);
        setLoadingDataSources(false);
      },
      (error) => {
        handleError(error, setErrorMessage, setLoadingDataSources);
      }
    );
  }, [getAllExternalData]);

  useEffect(() => {
    if (authenticatedUser?.roleId !== Roles.Admin) {
      navigate("/login");
    } else {
      loadDataSources();
    }
  }, [authenticatedUser?.roleId, loadDataSources, navigate]);

  const handleDeleteDataSource = () => {
    if (dataSourceIdToDelete) {
      setErrorMessage("");
      setLoadingDataSources(true);
      deleteExternalData(dataSourceIdToDelete).then(
        () => {
          setLoadingDataSources(false);
          loadDataSources();
        },
        (error) => {
          handleError(error, setErrorMessage, setLoadingDataSources);
        }
      );
    }
  };

  async function checkForDelete(dataSourceId: number): Promise<void> {
    const countOfRows = await checkExternalData(dataSourceId.toString())
    setCountOfRowsToDelete(countOfRows)
    setShowDataSourceConfirmModal(true);
    setDataSourceIdToDelete(dataSourceId.toString());
  }

  return (
    <>
      <div className="d-flex left-right mt-4">
        <h3>{`${uploadType} Files`}</h3>
        <Link to={`/admin/${urlPath}/new`} className="btn btn-sw btn-outline">
          <FontAwesomeIcon icon={faSquarePlus} className="me-2" />
          {`New ${uploadType} File`}
        </Link>
      </div>
      <div
        className="mt-3"
        style={{ maxHeight: 400, overflowY: "auto", overflowX: "hidden" }}
      >
        {dataSources?.length > 0 && (
          <table className="sw">
            <thead>
              <tr className="d-flex">
                <th scope="col" className="col-sm-7">
                  Name
                </th>
                <th scope="col" className="col-sm-4">
                  Uploaded
                </th>
                <th scope="col" className="col-sm-1"></th>
              </tr>
            </thead>
            <tbody>
              {dataSources?.map((dataSource) => (
                <tr key={dataSource.id} className="d-flex border-bottom">
                  <td
                    className="col-sm-7"
                    onClick={() =>
                      navigate(`/admin/${urlPath}/${dataSource.id}`)
                    }
                  >
                    {dataSource.fileName}
                  </td>
                  <td className="col-sm-4">
                    {formatDateString(dataSource?.uploadedOn.toString())}
                  </td>
                  <td className="col-sm-1">
                    <button
                      className="btn btn-link"
                      onClick={() => checkForDelete(dataSource.id)}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#741D38" }}
                        icon={faTrashCan}
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {!dataSources?.length && !loadingDataSources && (
          <div className="list-group-item list-group-item-light">
            {`There are no ${uploadType.toLowerCase()} files`}
          </div>
        )}
      </div>

      <ConfirmModal
        message={`There are ${countOfRowsToDelete} rows currently referencing this data source.  Are you sure you want to delete this dataSource?`}
        action={handleDeleteDataSource}
        isOpen={showDataSourceConfirmModal}
        close={() => {
          setShowDataSourceConfirmModal(false);
        }}
      />
    </>
  );
}
