import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { useRef } from "react";
import Modal from "react-modal";
import { ViolationCriterion } from "../types/work.types";

interface AddMatterDeficiencyForm {
  violationId: number | string | null;
  violationNote: string | null;
}

interface AddMatterDeficiencyModalProps {
  isOpen: boolean;
  close: () => void;
  addDeficiency: (form: AddMatterDeficiencyForm) => void;
  clearDeficiency: () => void;
  errorMessage?: string;
  violationCriteria: ViolationCriterion[];
}

export const AddMatterDeficiencyModal = ({
  isOpen,
  close,
  addDeficiency,
  clearDeficiency,
  errorMessage,
  violationCriteria,
}: AddMatterDeficiencyModalProps) => {
  const formikRef = useRef<FormikProps<AddMatterDeficiencyForm>>(null);

  const handleCloseModal = () => {
    close();
  };

  const handleAdd = (formValues: AddMatterDeficiencyForm) => {
    addDeficiency(formValues);
    close();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      onAfterOpen={() => {
        // TODO: load things
      }}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          width: 600,
          transform: "translate(-50%, -50%)",
        },
      }}
      contentLabel="Add Users Modal"
    >
      <div className="row">
        <div className="col">
          <div className="d-flex left-right">
            <h3>Matter Deficiency</h3>
            <span
              style={{ fontSize: "1.25rem", cursor: "pointer" }}
              aria-label="Close"
              onClick={close}
              className="font-weight-bold"
            >
              X
            </span>
          </div>
        </div>
      </div>

      <Formik
        innerRef={formikRef}
        enableReinitialize
        validateOnBlur={true}
        initialValues={{} as AddMatterDeficiencyForm}
        onSubmit={handleAdd}
      >
        {(formik) => (
          <Form>
            <div className="row text-center my-3">
              <div className="form-group mt-2">
                <Field
                  as="select"
                  style={{ minWidth: "25%" }}
                  className="form-control w-50"
                  name={`violationId`}
                  value={formik.values.violationId?.toString()}
                  onChange={formik.handleChange}
                >
                  <option value="">Select Violation</option>
                  {violationCriteria.map((criterion) => (
                    <option key={criterion.ruleId} value={criterion.ruleId}>
                      {criterion.name}
                    </option>
                  ))}
                </Field>

                <ErrorMessage
                  name={`violationId`}
                  component="div"
                  className="alert alert-danger w-50 p-2"
                />

                <Field
                  className="form-control w-50 mt-2"
                  placeholder="Notes"
                  as="textarea"
                  name={`violationNote`}
                  value={formik.values.violationNote}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="row align-items-end mt-3">
              <div className="col">
                <button type="submit" className="btn btn-sw">
                  Add Deficiency
                </button>
                {errorMessage && (
                  <div className="form-group d-inline ms-2">
                    <div className="alert alert-danger d-inline" role="alert">
                      {errorMessage}
                    </div>
                  </div>
                )}
              </div>
              <div className="col">
                <button type="button" onClick={clearDeficiency} className="btn btn-sw">
                  Clear Deficiency
                </button>
                {errorMessage && (
                  <div className="form-group d-inline ms-2">
                    <div className="alert alert-danger d-inline" role="alert">
                      {errorMessage}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
