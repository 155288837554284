import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { handleError } from "../common/utils";
import { useMatterStatusService } from "../services/matter-status.service";
import { Progress } from "../types/matter.types";

export const useProgressWithRefresh = () => {
    const { getProgress } = useMatterStatusService();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [progress, setProgress] = useState<Progress>();
    const params = useParams();
    const matterId = params?.matterId;

    const refreshProgress = useCallback(() => {
        setIsLoading(true);
        getProgress(matterId || "").then(
            (progress) => {
                setProgress(progress);
                setIsLoading(false);
            },
            (error) => {
                handleError(error, setErrorMessage, undefined);
                setIsLoading(false);
            }
        );
    }, [matterId, getProgress]);

    return { progress, isLoading, errorMessage, refreshProgress }
}