import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import { useUserService } from "../services/user.service";
import { handleError } from "../common/utils";

interface ForgotPasswordForm {
  email: string;
}

const ForgotPassword = () => {
  const { requestPasswordReset } = useUserService();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handlePasswordResetRequest = (formValues: ForgotPasswordForm) => {
    const { email } = formValues;

    setErrorMessage("");
    setLoading(true);

    requestPasswordReset(email).then(
      () => {
        setLoading(false);
        setShowSuccessMessage(true);
      },
      (error) => {
        handleError(error, setErrorMessage, setLoading);
      }
    );
  };

  return (
    <div className="card card-container tablet-left">
      <h2 className="mb-5">Forgot Password</h2>
      <span className="small mb-1">
        Enter your email address and we'll send you a link to reset your
        password.
      </span>
      <Formik
        initialValues={{ email: "" } as ForgotPasswordForm}
        validate={(values) => {
          const errors = {} as any;
          if (values.email === "") {
            errors.email = "Email is required";
          }
          return errors;
        }}
        onSubmit={handlePasswordResetRequest}
      >
        <Form>
          <div className="form-group">
            <Field
              name="email"
              type="text"
              placeholder="Email"
              className="form-control"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="alert alert-danger p-2"
            />
          </div>
          {errorMessage && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            </div>
          )}
          <div className="form-group mt-5">
            <button
              type="submit"
              className="btn btn-light btn-outline"
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm me-1"></span>
              )}
              <span>Submit</span>
            </button>
          </div>
          {showSuccessMessage && (
            <div className="form-group">
              <div className="alert alert-success" role="alert">
                An email has been sent to you. Click the link in the email to
                reset your password. <Link to={"/login"}>Click here</Link> to go
                back to the login.
              </div>
            </div>
          )}
        </Form>
      </Formik>
    </div>
  );
};

export default ForgotPassword;
