import { useCallback } from 'react';
import { GetNextSignatureTableColumnDTO, GetNextTranscribableFieldDTO, Template, UpdateSignatureTableColumnDTO, UpdateTranscribableFieldDTO } from '../types/template.types';
import { useAuthenticatedRequestService } from './service.base';


export function useTemplateService() {
    const TEMPLATES_PATH = 'templates/';
    const { authGet, authPut, authPost, authDelete } = useAuthenticatedRequestService();

    const getAllTemplates = useCallback(async function (): Promise<Template[]> {
        return await authGet(TEMPLATES_PATH);
    }, [authGet]);

    const getTemplateById = useCallback(async function (id: string): Promise<Template> {
        return await authGet(TEMPLATES_PATH + id);
    }, [authGet]);

    const createTemplate = useCallback(async function (name: string): Promise<Template | null> {
        return await authPost(TEMPLATES_PATH, { name });
    }, [authPost]);

    const updateTemplate = useCallback(async function (id: string, name: string): Promise<Template | null> {
        return await authPut(TEMPLATES_PATH + id, { name });
    }, [authPut]);

    const deleteTemplate = useCallback(async function (id: string): Promise<null> {
        return await authDelete(TEMPLATES_PATH + id);
    }, [authDelete]);

    const getNextTranscribableField = useCallback(async function (templateId: string, fieldId?: string): Promise<GetNextTranscribableFieldDTO> {
        let urlPath = TEMPLATES_PATH + templateId + "/transcribablefields";
        if (fieldId) { urlPath += '/' + fieldId; }
        return await authGet(urlPath);
    }, [authGet]);

    const updateTranscribableField = useCallback(async function (templateId: string, fieldId: string, dto: UpdateTranscribableFieldDTO): Promise<GetNextTranscribableFieldDTO> {
        return await authPut(TEMPLATES_PATH + templateId + "/transcribablefields/" + fieldId, dto);
    }, [authPut]);

    const getNextSignatureTableColumn = useCallback(async function (templateId: string, fieldId?: string): Promise<GetNextSignatureTableColumnDTO> {
        let urlPath = TEMPLATES_PATH + templateId + "/signaturetablecolumns";
        if (fieldId) { urlPath += '/' + fieldId; }
        return await authGet(urlPath);
    }, [authGet]);

    const updateSignatureTableColumn = useCallback(async function (templateId: string, fieldId: string, dto: UpdateSignatureTableColumnDTO): Promise<GetNextSignatureTableColumnDTO> {
        return await authPut(TEMPLATES_PATH + templateId + "/signaturetablecolumns/" + fieldId, dto);
    }, [authPut]);


    const funcs = {
        getAllTemplates,
        getTemplateById,
        createTemplate,
        updateTemplate,
        deleteTemplate,
        getNextTranscribableField,
        updateTranscribableField,
        getNextSignatureTableColumn,
        updateSignatureTableColumn
    };
    return funcs;
}
