import { useCallback } from 'react';
import { Matter, MatterForm } from '../types/matter.types';
import { useAuthenticatedRequestService } from './service.base';


export function useMatterService() {
    const MATTERS_PATH = 'matters/';
    const { authGet, authPut, authPost, authDelete } = useAuthenticatedRequestService();

    const getAllMatters = useCallback(async function(): Promise<Matter[]> {
        return await authGet(MATTERS_PATH);
    }, [authGet]);

    const getMatterById = useCallback(async function(id: string): Promise<Matter> {
        return await authGet(MATTERS_PATH + id);
    }, [authGet]);

    const createMatter = useCallback(async function(matter: MatterForm): Promise<Matter | null> {
        return await authPost(MATTERS_PATH, matter);
    }, [authPost]);

    const updateMatter = useCallback(async function(matter: Matter): Promise<null> {
        return await authPut(MATTERS_PATH + matter.id, matter);
    }, [authPut]);

    const deleteMatter = useCallback(async function (id: string): Promise<null> {
        return await authDelete(MATTERS_PATH + id);
    }, [authDelete]);

    const deleteUpload = useCallback(async function (uploadId: string): Promise<null> {
        return await authDelete("upload/signedpetitions/" + uploadId);
      }, [authDelete]);
    
    const getValidSignaturesCsv = useCallback(async function(matterId: string,): Promise<string> {
        return await authGet(`${MATTERS_PATH}${matterId}/validsignatures/csv`);
    }, [authGet]);


    const funcs = {
        getAllMatters,
        getMatterById,
        createMatter,
        updateMatter,
        deleteMatter,
        deleteUpload,
        getValidSignaturesCsv,
    };
    return funcs;
}
