import { createContext, useContext, useState } from "react";
import { User } from "./types/user.types";

interface Context {
  authenticatedUser: User | null;
  setAuthenticatedUser(user: User | null): void;
}

const AuthContext = createContext<Context>(null!);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  const getAuthenticatedUser = () => {
    if (user === null) {
      const localStorageUser = localStorage.getItem("user");
      if (!localStorageUser) return null;

      const authenticatedUser = JSON.parse(localStorageUser);
      if (authenticatedUser as User) {
        setUser(authenticatedUser);
        return authenticatedUser;
      }
    }

    return user;
  };

  const context: Context = {
    authenticatedUser: getAuthenticatedUser(),
    setAuthenticatedUser(user: User) {
      setUser(user);
      if (user == null) {
        localStorage.removeItem("user");
        return;
      }
      localStorage.setItem("user", JSON.stringify(user));
    },
  };

  return <AuthContext.Provider value={context} children={children} />;
};

export function useAuthContext() {
  return useContext(AuthContext) || {};
}
