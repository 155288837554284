import { faCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../AuthProvider";
import { Roles } from "../types/user.types";
import UploadFiles from "./upload-files.component";

export default function InviteReviewers() {
  const { authenticatedUser } = useAuthContext();
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticatedUser?.roleId !== Roles.Manager) {
      navigate("/login");
    }
  }, [authenticatedUser?.roleId, navigate]);

  return (
    <div className="container">
      <div className="d-flex flex-row mb-4">
        <div className="me-auto" style={{ marginLeft: "-12px" }}>
          <Link to="/manager" className="btn btn-sw btn-sm btn-outline">
            <FontAwesomeIcon icon={faCircleLeft} className="me-2" />
            Back to Home
          </Link>
        </div>
      </div>
      <div className="row tablet-view">
        <div className="col-4 tablet-left d-flex flex-column">
          <div className="tablet-buffer d-flex flex-fill flex-column mb-3">
            <span>Manager</span>
            <h2>Invite</h2>
          </div>
        </div>
        <div className="col-8 tablet-right">
          <div className="tablet-buffer">
            <div className="d-flex left-right">
              <h3>Import Reviewers</h3>
            </div>
            <UploadFiles
              uploadUrlPath={`users/reviewers?callbackUrl=${process.env.REACT_APP_FRONTEND_URL}register`}
              fileTypes=".csv,.txt"
              uploadProcessing={() => setProcessing(true)}
              uploadComplete={() => setProcessing(false)}
              uploadError={() => setProcessing(false)}
            />
            {processing && (
              <div>
                <span className="spinner-border spinner-border-sm me-1 text-secondary"></span>
                <span className="small">Processing file...</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
