import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../AuthProvider";
import { useWorkService } from "../services/work.service";
import {
  Work,
  ValidationForm,
  WorkStatus,
  WorkField,
  RegisteredVoterFlags,
} from "../types/work.types";
import { Roles } from "../types/user.types";
import { FormikProps } from "formik";
import { handleError, convertFieldValuesToTypes } from "../common/utils";
import WorkItem from "./work-item.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from "@fortawesome/free-regular-svg-icons";

export default function FlaggedWork() {
  const { authenticatedUser } = useAuthContext();
  const { getNextFlaggedWork, updateWork } = useWorkService();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [work, setWork] = useState<Work | null>();
  const formikRef = useRef<FormikProps<ValidationForm>>(null);

  const loadNextFlaggedWork = useCallback(() => {
    setErrorMessage("");
    setLoading(true);
    setWork(null);
    getNextFlaggedWork().then(
      (work) => {
        setLoading(false);

        if (work) {
          setWork(work);
          navigate(`/manager/flagged/${work?.workId}`);
        } else {
          // All work completed
          navigate(`/manager`);
        }
      },
      (error) => {
        handleError(error, setErrorMessage, setLoading);
      }
    );
  }, [getNextFlaggedWork, navigate]);

  useEffect(() => {
    if (authenticatedUser?.roleId !== Roles.Manager) {
      navigate("/login");
    } else {
      loadNextFlaggedWork();
    }
  }, [authenticatedUser?.roleId, loadNextFlaggedWork, navigate]);

  const handleSaveWork = (
    fields: WorkField[],
    externalDataRecordId?: string | null,
    registeredVoterFlags?: RegisteredVoterFlags | null
  ): Promise<void> => {
    if (!work?.workId) {
      return Promise.reject("No work");
    }
    setErrorMessage("");
    setLoading(true);
    return updateWork(work?.workId.toString(), {
      workStatusId: WorkStatus.Completed,
      fields: convertFieldValuesToTypes(fields),
      externalDataRecordId: externalDataRecordId || null,
      registeredVoterFlags:
        registeredVoterFlags || RegisteredVoterFlags.Unknown,
    }).then(
      () => {
        setLoading(false);
        setWork({ ...work, fields: convertFieldValuesToTypes(fields) });
      },
      (error) => {
        handleError(error, setErrorMessage, setLoading);
      }
    );
  };

  const handleNextFlaggedWork = (
    fields: WorkField[],
    externalDataRecordId?: string | null,
    registeredVoterFlags?: RegisteredVoterFlags | null
  ) => {
    if (work?.workId) {
      handleSaveWork(fields, externalDataRecordId, registeredVoterFlags).then(
        () => {
          loadNextFlaggedWork();
        },
        (error) => {
          handleError(error, setErrorMessage, setLoading);
        }
      );
    }
  };

  return (
    <div className="container">
      <div className="d-flex flex-row mb-4">
        <div className="me-auto" style={{ marginLeft: "-12px" }}>
          <Link to="/manager" className="btn btn-sw btn-sm btn-outline">
            <FontAwesomeIcon icon={faCircleLeft} className="me-2" />
            Back to Home
          </Link>
        </div>
      </div>

      {loading && (
        <div
          style={{ height: 525 }}
          className="row justify-content-center align-items-center"
        >
          <span className="spinner-border spinner-border-sm me-1 text-secondary"></span>
          <span>Loading...</span>
        </div>
      )}

      {!loading && work && (
        <WorkItem
          work={work}
          onSave={handleSaveWork}
          onNext={handleNextFlaggedWork}
          formikRef={formikRef}
          errorMessage={errorMessage}
        />
      )}

      {errorMessage && (
        <div className="row">
          <div className="col">
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
