import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuthService } from "../services/auth.service";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../AuthProvider";
import { handleError } from "../common/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";

interface LoginForm {
  email: string;
  password: string;
}

const Login = () => {
  const { setAuthenticatedUser } = useAuthContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { login } = useAuthService();

  const handleLogin = (formValues: LoginForm) => {
    const { email, password } = formValues;

    setErrorMessage("");
    setLoading(true);

    login(email, password).then(
      (user) => {
        setAuthenticatedUser(user);
        setLoading(false);

        if (user?.roleId === 1) {
          navigate("/admin");
        } else if (user?.roleId === 2) {
          navigate("/manager");
        } else if (user?.roleId === 3) {
          navigate("/work");
        }
      },
      (error) => {
        handleError(error, setErrorMessage, setLoading);
      }
    );
  };

  return (
    <div className="card card-container tablet-left">
      <h2>Sign In</h2>
      <Formik
        initialValues={{ email: "", password: "" } as LoginForm}
        validate={(values) => {
          const errors = {} as any;
          if (values.email === "") {
            errors.email = "Email is required";
          }
          if (values.password === "") {
            errors.password = "Password is required";
          }
          return errors;
        }}
        onSubmit={handleLogin}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="form-group mt-4 mb-2">
              <Field
                name="email"
                type="text"
                placeholder="Email"
                className="form-control"
              />
              <ErrorMessage name="email" component="div" className="white" />
            </div>
            <div className="form-group mb-2">
              <Field
                name="password"
                type="password"
                placeholder="Password"
                className="form-control"
              />
              <ErrorMessage name="password" component="div" className="white" />
            </div>
            {errorMessage && (
              <div className="form-group mb-2">
                <div className="white" role="alert">
                  {errorMessage}
                </div>
              </div>
            )}
            <div className="form-group mt-4">
              <button
                type="submit"
                className="btn btn-light btn-outline"
                disabled={loading || !!errors.email || !!errors.password}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm me-2"></span>
                )}
                <FontAwesomeIcon
                  icon={faArrowAltCircleRight}
                  className="me-2"
                />
                Go
              </button>
            </div>

            <div className="mt-3">
              <Link className="small white" to="/forgot-password">
                Forgot your password?
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
