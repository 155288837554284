import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { CheckedTask } from "../components/matter-checklist.component";
import { useMatterStatusService } from "../services/matter-status.service";
import { ChecklistStep } from "../types/matter.types";

export const useChecklistWithRefresh = () => {
    const params = useParams();
    const matterId = params?.matterId;
    const { getChecklist } = useMatterStatusService();
  
    const initialChecklist = useMemo(
      () => [
        {
          step: ChecklistStep.UploadTemplates,
          checked: false,
          url: `/admin/templates/new`,
          linkText: "Upload",
          text: " templates",
        },
        {
          step: ChecklistStep.SetupTemplates,
          checked: false,
          url: `/admin/templates`,
          linkText: "",
          text: "Set up templates",
        },
        {
          step: ChecklistStep.SetupParameters,
          checked: false,
          url: `/admin/matters/${matterId}/variables`,
          linkText: "",
          text: "Set up matter parameters",
        },
        {
          step: ChecklistStep.UploadCirculators,
          checked: false,
          url: `/admin/matters/${matterId}/circulators`,
          linkText: "",
          text: "Upload circulator info",
        },
        {
          step: ChecklistStep.UploadVoters,
          checked: false,
          url: `/admin/matters/${matterId}/voters`,
          linkText: "Upload",
          text: " voter registration files",
        },
        {
          step: ChecklistStep.CreateTasks,
          checked: false,
          url: `/admin/tasks`,
          linkText: "",
          text: "Create tasks",
        },
        {
          step: ChecklistStep.UploadSignatures,
          checked: false,
          url: `/admin/matters/${matterId}/signatures`,
          linkText: "",
          text: "Upload signed petitions",
        },
        {
          step: ChecklistStep.CreateRules,
          checked: false,
          url: `/admin/rules`,
          linkText: "",
          text: "Create rules",
        },
        {
          step: ChecklistStep.FinishTranscription,
          checked: false,
          url: "",
          linkText: "",
          text: "Finish transcription",
        },
      ],
      [matterId]
    );
  
    const checklistByStep = useMemo(
      () =>
        initialChecklist.reduce(
          (result: Record<ChecklistStep, CheckedTask>, task) => {
            result[task.step] = task;
            return result;
          },
          {} as Record<ChecklistStep, CheckedTask>
        ),
      [initialChecklist]
    );
  
    const [checklist, setChecklist] = useState<CheckedTask[]>(initialChecklist);
  
    const refreshChecklist = useCallback(() => {
      getChecklist(matterId?.toString() || "").then((cl) => {
        setChecklist(
          cl.map((item) => {
            const task = checklistByStep[item.step];
            task.checked = item.status;
            return task;
          })
        );
      });
    }, [checklistByStep, getChecklist, matterId]);
  
    return { checklist, refreshChecklist };
  };
  
  