import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../AuthProvider";
import { handleError } from "../common/utils";
import { useUserService } from "../services/user.service";
import { Roles } from "../types/user.types";

interface InviteForm {
  email: string;
}

interface InviteReviewerProps {
  isOpen: boolean;
  close: () => void;
}

export default function InviteReviewer({ isOpen, close }: InviteReviewerProps) {
  const { authenticatedUser } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { invite } = useUserService();

  useEffect(() => {
    if (authenticatedUser?.roleId !== Roles.Manager) {
      navigate("/login");
    }
    setSuccessMessage("");
    setErrorMessage("");
  }, [authenticatedUser?.roleId, navigate]);

  const handleInvite = (
    formValues: InviteForm,
    actions: FormikHelpers<InviteForm>
  ) => {
    const { email } = formValues;

    setSuccessMessage("");
    setErrorMessage("");
    setLoading(true);

    if (authenticatedUser) {
      invite(email, Roles.Reviewer.toString()).then(
        () => {
          actions.resetForm();
          setSuccessMessage("An invitation has been sent");
          setLoading(false);
        },
        (error) => {
          handleError(error, setErrorMessage, setLoading);
        }
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          width: 600,
          transform: "translate(-50%, -50%)",
        },
      }}
      contentLabel="Invite Reviewer Modal"
    >
      <Formik
        initialValues={
          {
            email: "",
          } as InviteForm
        }
        validate={(values) => {
          const errors = {} as any;
          if (!values.email) {
            errors.email = "Email is required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          return errors;
        }}
        onSubmit={(formValues, actions) => {
          handleInvite(formValues, actions);
        }}
      >
        <Form>
          <h3>Invite Reviewer</h3>
          <div className="mt-3">
            <div className="form-group">
              <Field
                name="email"
                type="email"
                placeholder="Enter email address for invite"
                className="form-control"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="alert alert-danger p-2"
              />
            </div>
            <div className="mt-3">
              <div className="form-group">
                <button
                  onClick={close}
                  type="button"
                  className="btn btn-sw me-4"
                  disabled={loading}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-sw" disabled={loading}>
                  {loading && (
                    <span className="spinner-border spinner-border-sm me-1"></span>
                  )}
                  Invite
                </button>
              </div>
            </div>
          </div>
          {errorMessage && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            </div>
          )}
          {successMessage && (
            <div className="form-group">
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            </div>
          )}
        </Form>
      </Formik>
    </Modal>
  );
}
