import { RefObject, useCallback, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { ValidationForm, Validity, Work, WorkField } from "../types/work.types";

interface FieldValidationProps {
  work: Work;
  formikRef: RefObject<FormikProps<ValidationForm>> | null;
  handleNextWork: (fields: WorkField[]) => void;
  nextLabel?: string;
  isSignatureTable: boolean;
}

interface KeyboardEvent {
  key: string;
  repeat: boolean;
  target: EventTarget | null;
}

export default function FieldValidation({
  work,
  handleNextWork,
  formikRef,
  nextLabel,
  isSignatureTable,
}: FieldValidationProps) {
  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.repeat) return;

      if (e.key && e.key.toLowerCase() === "enter") {
        formikRef?.current?.submitForm();
        return;
      }

      const nodeName = (e.target as Element)?.nodeName;
      if (nodeName !== "INPUT" && nodeName !== "TEXTAREA") {
        if (e.key && e.key.toLowerCase() === "v") {
          formikRef?.current?.setFieldValue(
            "fields[0].validity",
            Validity.Valid
          );
          return;
        }
        if (e.key && e.key.toLowerCase() === "n") {
          formikRef?.current?.setFieldValue(
            "fields[0].validity",
            Validity.Invalid
          );
          return;
        }
        if (e.key && e.key.toLowerCase() === "s") {
          formikRef?.current?.setFieldValue(
            "fields[0].validity",
            Validity.Strikethrough
          );
          return;
        }
      }
    },
    [formikRef]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress, true);
    return () => {
      document.removeEventListener("keydown", handleKeyPress, true);
    };
  }, [handleKeyPress]);

  const handleNext = (formValues: ValidationForm) => {
    handleNextWork(formValues.fields);
  };

  const checkValidity = (value: string) => {
    if (value === Validity.Unknown.toString()) {
      return "Validity is required";
    }
    return null;
  };

  return (
    <>
      <div className="row text-center">
        <div className="col-sm-12">
          <img
            alt="transcribable field"
            style={{ width:'100%', border: "1px black solid" }}
            src={`data:image/png;base64,${work.image.fileContents}`}
          />
        </div>
      </div>
      <div className="row mt-3">
        <Formik
          innerRef={formikRef}
          enableReinitialize
          validateOnBlur={true}
          initialValues={
            {
              fields: work.fields,
            } as ValidationForm
          }
          onSubmit={handleNext}
        >
          {(formik) => (
            <Form>
              <div className="row text-center my-3">
                <>
                  {formik.values.fields.map((field, i) => (
                    <div
                      key={field.name}
                      className={`col-sm-${12 / formik.values.fields.length}`}
                    >
                      {field.name && (
                        <div className="form-group mt-4">
                          <Field
                            name={`fields[${i}].value`}
                            className="form-control"
                            placeholder={`Enter ${field.name} value`}
                            type="text"
                            validate={(value: string) => {
                              if (
                                !value &&
                                field.validity === Validity.Valid
                              ) {
                                return "Value is required";
                              }
                              return null;
                            }}

                          />
                          <ErrorMessage
                            name={`fields[${i}].value`}
                            component="div"
                            className="alert alert-danger p-2"
                          />
                        </div>
                      )}

                      <div className="form-group mt-2">
                        <div className="form-check form-check-inline">
                          <Field
                            id={`fields[${i}].valid`}
                            className="form-check-input"
                            type="radio"
                            validate={checkValidity}
                            name={`fields[${i}].validity`}
                            required
                            checked={field.validity === Validity.Valid}
                            onChange={() => {
                              formik.setFieldValue(
                                `fields[${i}].validity`,
                                Validity.Valid
                              );
                            }}
                          />
                          <label className="m-0" htmlFor={`fields[${i}].valid`}>
                            <span style={{ textDecoration: "underline" }}>
                              V
                            </span>
                            <span>alid</span>
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <Field
                            id={`fields[${i}].invalid`}
                            className="form-check-input"
                            type="radio"
                            validate={checkValidity}
                            name={`fields[${i}].validity`}
                            required
                            checked={field.validity === Validity.Invalid}
                            onChange={() => {
                              formik.setFieldValue(
                                `fields[${i}].validity`,
                                Validity.Invalid
                              );
                            }}
                          />
                          <label
                            className="m-0"
                            htmlFor={`fields[${i}].invalid`}
                          >
                            <span>I</span>
                            <span style={{ textDecoration: "underline" }}>
                              n
                            </span>
                            <span>valid</span>
                          </label>
                        </div>
                        {isSignatureTable && (
                          <div className="form-check form-check-inline">
                            <Field
                              id={`fields[${i}].strikethrough`}
                              className="form-check-input"
                              type="radio"
                              validate={checkValidity}
                              name={`fields[${i}].validity`}
                              required
                              checked={
                                field.validity === Validity.Strikethrough
                              }
                              onChange={() => {
                                formik.setFieldValue(
                                  `fields[${i}].validity`,
                                  Validity.Strikethrough
                                );
                              }}
                            />
                            <label
                              className="m-0"
                              htmlFor={`fields[${i}].invalid`}
                            >
                              <span style={{ textDecoration: "underline" }}>
                                S
                              </span>
                              <span>trikethrough</span>
                            </label>
                          </div>
                        )}
                      </div>

                      {field.validity === Validity.Invalid && (
                        <div className="form-group mt-2">
                          <Field
                            as="select"
                            style={{ minWidth: "25%" }}
                            className="form-control w-50 m-auto"
                            validate={(value: string) => {
                              if (
                                !value &&
                                field.validity === Validity.Invalid
                              ) {
                                return "Violation is required";
                              }
                              return null;
                            }}
                            name={`fields[${i}].violationId`}
                            value={field.violationId?.toString()}
                            onChange={formik.handleChange}
                          >
                            <option value="">Select Violation</option>
                            {field.violationCriteria && field.violationCriteria.map((criterion) => {
                              return (
                                <option
                                  key={criterion.ruleId}
                                  value={criterion.ruleId}
                                >
                                  {criterion.name}
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name={`fields[${i}].violationId`}
                            component="div"
                            className="alert alert-danger w-50 m-auto p-2"
                          />

                          <Field
                            className="form-control w-50 m-auto mt-2"
                            placeholder="Notes"
                            as="textarea"
                            name={`fields[${i}].violationNote`}
                            value={field.violationNote}
                            onChange={formik.handleChange}
                          />
                        </div>
                      )}

                      <ErrorMessage
                        name={`fields[${i}].validity`}
                        component="div"
                        className="alert alert-danger w-50 ms-auto me-auto p-2"
                      />
                    </div>
                  ))}
                </>
              </div>

              <div className="row text-center">
                <div className="col-sm-12 mt-1">
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-sw"
                      disabled={formik.isSubmitting || !!formik.errors.fields}
                    >
                      {!formik.isSubmitting && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-arrow-right me-1"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                          ></path>
                        </svg>
                      )}
                      {formik.isSubmitting && (
                        <span className="spinner-border spinner-border-sm me-1"></span>
                      )}
                      {nextLabel || "Next"}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
