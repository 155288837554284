import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft, faSave, faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";

import { useAuthContext } from "../AuthProvider";
import { useExternalDataService } from "../services/external-data.service";

import { Roles } from "../types/user.types";
import { ExternalDataSource, UploadType } from "../types/external-data.types";
import { handleError } from "../common/utils";

interface MatterVoterFilesProps {}

export default function MatterVoterFiles({}: MatterVoterFilesProps) {
  const { authenticatedUser } = useAuthContext();
  const navigate = useNavigate();
  const {
    getAllExternalData,
    getExternalDataSourceMatters,
    postExternalDataSourceMatters,
  } = useExternalDataService();
  const [dataSources, setDataSources] = useState<ExternalDataSource[]>([]);
  const [isLoadingDataSources, setIsLoadingDataSources] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [initialValues, setInitialValues] = useState<Record<string, boolean>>(
    {}
  );
  const formikRef = useRef<FormikProps<Record<string, boolean>>>(null);
  const params = useParams();
  const matterId = params?.matterId;

  const isExternalDataSourceChecked = (
    dataSources: ExternalDataSource[],
    dataSource: ExternalDataSource
  ) => {
    const isChecked = 
      dataSources.filter((eds) => eds.id === dataSource.id).length > 0;
    return isChecked;
  };

  const loadDataSources = useCallback(() => {
    setErrorMessage("");
    setIsLoadingDataSources(true);

    getAllExternalData(UploadType.Voter).then(
      (externalDataSources) => {
        setDataSources(externalDataSources);
        getExternalDataSourceMatters(matterId || "").then((edsms) => {
          const initialVals = externalDataSources.reduce(
            (a, v) => ({
              ...a,
              [`isChecked${v.id.toString()}`]: isExternalDataSourceChecked(
                edsms,
                v
              ),
            }),
            {}
          );
          setInitialValues(initialVals);
          setIsLoadingDataSources(false);
        });
      },
      (error) => {
        handleError(error, setErrorMessage, setIsLoadingDataSources);
      }
    );
  }, [getAllExternalData]);

  const handleCancel = () => {
    navigate(`/admin/matters/${matterId}/details`);
  }

  const handleSubmit = (formValues: Record<string, boolean>) => {
    setIsSaving(true);
    postExternalDataSourceMatters(matterId || "", formValues).then(
      () => {
        setIsSaving(false);
        navigate(`/admin/matters/${matterId}/details`);
      },
      (error) => {
        handleError(error, setErrorMessage, setIsSaving);
      }
    );
  };

  useEffect(() => {
    if (authenticatedUser?.roleId !== Roles.Admin) {
      navigate("/login");
    } else {
      loadDataSources();
    }
  }, [authenticatedUser?.roleId, loadDataSources, navigate]);

  return (
    <div className="container">
      <div className="d-flex flex-row mb-4">
        <div className="me-auto" style={{ marginLeft: "-12px" }}>
          <Link to="/admin" className="btn btn-sw btn-sm btn-outline">
            <FontAwesomeIcon icon={faCircleLeft} className="me-2" />
            Back to Dashboard
          </Link>
        </div>
      </div>
      <div className="row tablet-view justify-content-start">
        <div className="col-4 tablet-left d-flex flex-column">
          <div className="tablet-buffer d-flex flex-fill flex-column mb-2">
            <div className="mb-auto">
              <h2>Matter Voter Files</h2>
            </div>
            <div className=""></div>
          </div>
        </div>
        <div className="col-8 tablet-right">
          <div className="tablet-buffer">
            <h3>Select Voter Files</h3>
            {dataSources.length > 0 && (
              <Formik
                enableReinitialize={true}
                innerRef={formikRef}
                initialValues={initialValues}
                validate={(values) => {
                  const errors = {} as any;
                  return errors;
                }}
                onSubmit={handleSubmit}
              >
                <Form>
                  <div className="mb-3">
                    {dataSources.map((dataSource) => (
                      <div key={dataSource.id} className="form-check">
                        <Field
                          id={dataSource.id.toString()}
                          name={`isChecked${dataSource.id.toString()}`}
                          className="form-check-input"
                          type="checkbox"
                        />
                        <label
                          className="form-check-label"
                          htmlFor={dataSource.id.toString()}
                        >
                          {dataSource.fileName}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="form-group mb-3">
                    <button
                      type="submit"
                      className="btn btn-sw me-3"
                      disabled={isLoadingDataSources || isSaving}
                    >
                      {(isLoadingDataSources || isSaving) && (
                        <span className="spinner-border spinner-border-sm me-1"></span>
                      )}
                      <FontAwesomeIcon icon={faSave} className="me-2" />
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-sw ms-3"
                      onClick={handleCancel}
                    >
                      <FontAwesomeIcon icon={faCircleLeft} className="me-2" />
                      Cancel
                    </button>
                  </div>
                </Form>
              </Formik>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
