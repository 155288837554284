import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useMatterStatusService } from "../services/matter-status.service";
import { DocumentUploadInfoByCounty } from "../types/matter.types";

export default function MatterVoterStatus() {
  const params = useParams();
  const matterId = params?.matterId;
  const { getVoterRegistrationInfoById } = useMatterStatusService();
  const [voterRegistrationInfo, setVoterRegistrationInfo] = useState<
    DocumentUploadInfoByCounty[]
  >([]);

  const populateVoterRegistrationInfo = useCallback(() => {
    getVoterRegistrationInfoById(matterId?.toString() || "").then((info) => {
      setVoterRegistrationInfo(info);
    });
  }, [getVoterRegistrationInfoById, matterId]);

  useEffect(() => {
    populateVoterRegistrationInfo();
  }, [populateVoterRegistrationInfo]);

  return (
    <>
      <div className="d-flex left-right mt-4">
        <h3>Voter Files</h3>
        <Link
          to={`/admin/matters/${matterId}/voters`}
          className="btn btn-sw btn-outline"
        >
          <FontAwesomeIcon icon={faSquarePlus} className="me-2" />
          Select Files
        </Link>
      </div>
      <div>
        <span className="small">
          {voterRegistrationInfo?.length || 0} counties linked.
        </span>
      </div>
    </>
  );
}
