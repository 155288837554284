import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faSquarePlus } from "@fortawesome/free-regular-svg-icons";

import { useAuthContext } from "../AuthProvider";
import { useTemplateService } from "../services/template.service";

import { Roles } from "../types/user.types";
import { Template } from "../types/template.types";
import { formatDateString, handleError } from "../common/utils";

import ConfirmModal from "./confirm-modal";

export default function AdminTemplates() {
  const { authenticatedUser } = useAuthContext();
  const navigate = useNavigate();
  const { getAllTemplates, deleteTemplate } = useTemplateService();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [templateIdToDelete, setTemplateIdToDelete] = useState<string>();
  const [showTemplateConfirmModal, setShowTemplateConfirmModal] =
    useState(false);

  const loadTemplates = useCallback(() => {
    setErrorMessage("");
    setLoadingTemplates(true);

    getAllTemplates().then(
      (templates) => {
        setTemplates(templates);
        setLoadingTemplates(false);
      },
      (error) => {
        handleError(error, setErrorMessage, setLoadingTemplates);
      }
    );
  }, [getAllTemplates]);

  useEffect(() => {
    if (authenticatedUser?.roleId !== Roles.Admin) {
      navigate("/login");
    } else {
      loadTemplates();
    }
  }, [authenticatedUser?.roleId, loadTemplates, navigate]);

  const handleDeleteTemplate = () => {
    if (templateIdToDelete) {
      setErrorMessage("");
      setLoadingTemplates(true);

      deleteTemplate(templateIdToDelete).then(
        () => {
          setLoadingTemplates(false);
          loadTemplates();
        },
        (error) => {
          handleError(error, setErrorMessage, setLoadingTemplates);
        }
      );
    }
  };

  return (
    <>
      <div className="d-flex left-right mt-4">
        <h3>Templates</h3>
        <Link to="/admin/templates/new" className="btn btn-sw btn-outline">
          <FontAwesomeIcon icon={faSquarePlus} className="me-2" />
          New Template
        </Link>
      </div>
      <div
        className="mt-3"
        style={{ maxHeight: 400, overflowY: "auto", overflowX: "hidden" }}
      >
        {templates?.length > 0 && (
          <table className="sw">
            <thead>
              <tr className="d-flex">
                <th scope="col" className="col-sm-7">
                  Name
                </th>
                <th scope="col" className="col-sm-4">
                  Created
                </th>
                <th scope="col" className="col-sm-1"></th>
              </tr>
            </thead>
            <tbody>
              {templates?.map((template) => (
                <tr key={template.id} className="d-flex border-bottom">
                  <td
                    className="col-sm-7"
                    onClick={() => navigate("/admin/templates/" + template.id)}
                  >
                    {template.name}
                  </td>
                  <td className="col-sm-4">
                    {formatDateString(template?.createdOn.toString())}
                  </td>
                  <td className="col-sm-1">
                    <button
                      className="btn btn-link"
                      onClick={() => {
                        setShowTemplateConfirmModal(true);
                        setTemplateIdToDelete(template.id.toString());
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#741D38" }}
                        icon={faTrashCan}
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {!templates?.length && !loadingTemplates && (
          <div className="list-group-item list-group-item-light">
            There are no templates
          </div>
        )}
      </div>

      <ConfirmModal
        message="Are you sure you want to delete this template?"
        action={handleDeleteTemplate}
        isOpen={showTemplateConfirmModal}
        close={() => {
          setShowTemplateConfirmModal(false);
        }}
      />
    </>
  );
}
