import { faCircleLeft, faSave } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../AuthProvider";
import { handleError } from "../common/utils";
import { useTemplateService } from "../services/template.service";
import { Template } from "../types/template.types";
import { Roles } from "../types/user.types";
import TemplateUploads from "./template-uploads.component";

interface TemplateForm {
  name: string;
}

export default function TemplateDetails() {
  const { authenticatedUser } = useAuthContext();
  const { getTemplateById, createTemplate, updateTemplate } =
    useTemplateService();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [template, setTemplate] = useState<Template | null>(null);

  const templateId = params?.templateId;
  const isNew = templateId === "new";

  const populateTemplateInfo = useCallback(() => {
    setErrorMessage("");
    setLoading(true);
    getTemplateById(templateId!).then(
      (template) => {
        setTemplate(template);
        setLoading(false);
      },
      (error) => {
        handleError(error, setErrorMessage, setLoading);
      }
    );
  }, [getTemplateById, templateId]);

  useEffect(() => {
    if (authenticatedUser?.roleId !== Roles.Admin) {
      navigate("/login");
    } else {
      if (!isNew && templateId) {
        populateTemplateInfo();
      }
    }
  }, [
    isNew,
    templateId,
    authenticatedUser?.roleId,
    populateTemplateInfo,
    navigate,
  ]);

  const handleSave = (
    formValues: TemplateForm,
    actions: FormikHelpers<TemplateForm>
  ) => {
    const { name } = formValues;

    setSuccessMessage("");
    setErrorMessage("");
    setLoading(true);

    if (isNew) {
      createTemplate(name).then(
        (template) => {
          if (template) {
            actions.resetForm();
            setSuccessMessage("Template created successfully");
            setLoading(false);
            navigate("/admin/templates/" + template.id);
          }
        },
        (error) => {
          handleError(error, setErrorMessage, setLoading);
        }
      );
    } else {
      updateTemplate(template?.id.toString()!, name).then(
        () => {
          setSuccessMessage("Template updated successfully");
          setLoading(false);
        },
        (error) => {
          handleError(error, setErrorMessage, setLoading);
        }
      );
    }
  };

  return isNew || template ? (
    <div className="container">
      <div className="d-flex flex-row mb-4">
        <div className="me-auto" style={{ marginLeft: "-12px" }}>
          <Link to="/admin" className="btn btn-sw btn-sm btn-outline">
            <FontAwesomeIcon icon={faCircleLeft} className="me-2" />
            Back to Dashboard
          </Link>
        </div>
      </div>
      <div className="row tablet-view">
        <div className="col-4 tablet-left d-flex flex-column">
          <div className="tablet-buffer d-flex flex-fill flex-column mb-3">
            <div>
              <span>Template</span>
              <h2>{template?.name || "New"}</h2>
            </div>
          </div>
        </div>
        <div className="col-8 tablet-right">
          <div className="tablet-buffer">
            <Formik
              initialValues={
                {
                  name: template?.name || "",
                } as TemplateForm
              }
              validate={(values) => {
                const errors = {} as any;
                if (!values.name) {
                  errors.name = "Name is required";
                }
                return errors;
              }}
              onSubmit={(formValues, actions) => {
                handleSave(formValues, actions);
              }}
            >
              <Form>
                <div className="form-group mb-3">
                  <label htmlFor="name">Name</label>
                  <Field
                    name="name"
                    type="text"
                    placeholder="Enter template name"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="alert alert-danger p-2"
                  />
                </div>

                <div className="form-group mb-3">
                  <button
                    type="submit"
                    className="btn btn-sw"
                    disabled={loading}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm me-1"></span>
                    )}
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    Save
                  </button>
                </div>
                {errorMessage && (
                  <div className="form-group mb-3">
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  </div>
                )}
                {successMessage && (
                  <div className="form-group mb-3">
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  </div>
                )}
              </Form>
            </Formik>
            {!isNew && (
              <TemplateUploads
                template={template!}
                refreshTemplateInfo={populateTemplateInfo}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>{!loading && "This template is invalid"}</>
  );
}
