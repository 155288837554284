import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useAuthContext } from "./AuthProvider";
import { useEffect } from "react";
import Register from "./components/register.component";
import Login from "./components/login.component";
import AdminBoard from "./components/board-admin.component";
import ManagerBoard from "./components/board-manager.component";
import ReviewerBoard from "./components/board-reviewer.component";
import InviteManager from "./components/invite-manager.component";
import InviteReviewers from "./components/invite-reviewers.component";
import FieldIndentification from "./components/field-identification.component";
import ColumnIdentification from "./components/column-identification.component";
import MatterDetails from "./components/matter-details.component";
import TemplateDetails from "./components/template-details.component";
import GroupDetails from "./components/group-details.component";
import Deficiencies from "./components/deficiencies.component";
import FlaggedWork from "./components/flagged-work.component";
import ForgotPassword from "./components/forgot-password.component";
import ResetPassword from "./components/reset-password.component";
import UploadVoters from "./components/upload-voters.component";
import MatterVoterFiles from "./components/matter-voter-files.component";
import AdminMatterEdit from "./components/admin-matter-edit";

export default function App() {
  const { authenticatedUser, setAuthenticatedUser } = useAuthContext();

  const logOut = () => {
    setAuthenticatedUser(null);
  };

  useEffect(() => {}, [setAuthenticatedUser]);

  return (
    <div className="App">
      <BrowserRouter>
        <nav className="navbar navbar-expand">
          <Link to={"/"} className="navbar-brand">
            <img
              width={60}
              src="/swlaw-logo.svg"
              alt="Snell &amp; Wilmer Logo"
            />
          </Link>

          {authenticatedUser && (
            <div className="navbar-nav ms-auto">
              <div className="d-flex align-items-center logged-in-text me-2">
                Logged in as {authenticatedUser.fullName}
              </div>
              <a href="/login" className="nav-link" onClick={logOut}>
                <button type="button" className="btn btn-sw-m">
                  Log Out
                </button>
              </a>
            </div>
          )}
        </nav>
        <div className="mt-4">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            <Route path="/admin" element={<AdminBoard />} />
            <Route path="/admin/invite" element={<InviteManager />} />
            <Route
              path="/admin/templates/:templateId"
              element={<TemplateDetails />}
            />
            <Route
              path="/admin/templates/:templateId/field-identification"
              element={<FieldIndentification />}
            />
            <Route
              path="/admin/templates/:templateId/field-identification/:fieldId"
              element={<FieldIndentification />}
            />

            <Route
              path="/admin/templates/:templateId/column-identification"
              element={<ColumnIdentification />}
            />
            <Route
              path="/admin/templates/:templateId/column-identification/:fieldId"
              element={<ColumnIdentification />}
            />

            <Route
              path="/admin/matters/:matterId"
              element={<AdminMatterEdit />}
            />
            <Route
              path="/admin/matters/:matterId/details"
              element={<MatterDetails />}
            />
            <Route
              path="/admin/matters/:matterId/voters"
              element={<MatterVoterFiles />}
            />
            <Route
              path="/admin/matters/:matterId/deficiencies"
              element={<Deficiencies />}
            />

            <Route
              path="/admin/voters/:dataSourceId"
              element={<UploadVoters />}
            />

            <Route path="/manager" element={<ManagerBoard />} />
            <Route path="/manager/invite" element={<InviteReviewers />} />
            <Route path="/manager/flagged" element={<FlaggedWork />} />
            <Route path="/manager/flagged/:workId" element={<FlaggedWork />} />
            <Route path="/manager/groups/:groupId" element={<GroupDetails />} />

            <Route path="/work" element={<ReviewerBoard />} />
            <Route path="/work/:workId" element={<ReviewerBoard />} />

            <Route path="*" element={<Login />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}
