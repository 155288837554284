import { WorkField } from "../types/work.types";

const handleError = (error: any, setErrorMessage: React.Dispatch<React.SetStateAction<string>>, setLoading?: React.Dispatch<React.SetStateAction<boolean>>) => {
    const resMessage = (error.response &&
      error.response.data &&
      error.response.data.message) ||
      error.response.data ||
      error.message ||
      error.toString();
  
      setErrorMessage(resMessage);
      if (setLoading !== undefined){
          setLoading(false);
      }
  };

  const convertFieldValuesToTypes = (fields: WorkField[]) => {
    return fields.map((field) => {
      return {
        ...field,
        validity: field.validity,
        violationId: field.violationId !== "" ? field.violationId : null,
        violationNote: field.violationNote !== "" ? field.violationNote : null,
      };
    });
  };

  const convertFieldValuesToStrings = (fields: WorkField[]) => {
    return fields.map((field) => {
      return {
        ...field,
        validity: field.validity,
        violationId: field.violationId || "",
        violationNote: field.violationNote || "",
      };
    });
  };


  const formatObjectAsDate = (d: string | Date | null | undefined) => {
    if (!d) {
      return '';
    }
    if (d instanceof Date) {
      return formatDate(d);
    }
    return formatDateString(d);
  }

  const formatDate = (d: Date | null | undefined) => {
    if (!d) {
      return '';
    }
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) { month = "0" + month; };
    if (day.length < 2) { day = "0" + day; };

    return [year, month, day].join("-");
  }

  const formatDateString = (date: string | null | undefined) => {
    if (!date) { return ''; }
    const d = new Date(date);
    return formatDate(d);
  };
  
  export {
      handleError,
      convertFieldValuesToTypes,
      convertFieldValuesToStrings,
      formatDate,
      formatDateString,
      formatObjectAsDate,
  };

