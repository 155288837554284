import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../AuthProvider";
import { handleError } from "../common/utils";
import { useUserService } from "../services/user.service";
import { Roles } from "../types/user.types";

interface InviteForm {
  email: string;
  roleId: string;
}

export default function InviteManager() {
  const { authenticatedUser } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { invite } = useUserService();

  useEffect(() => {
    if (authenticatedUser?.roleId !== Roles.Admin) {
      navigate("/login");
    }
  }, [authenticatedUser?.roleId, navigate]);

  const handleInvite = (
    formValues: InviteForm,
    actions: FormikHelpers<InviteForm>
  ) => {
    const { email, roleId } = formValues;
    setSuccessMessage("");
    setErrorMessage("");
    setLoading(true);

    if (authenticatedUser) {
      invite(email, roleId).then(
        () => {
          actions.resetForm();
          setSuccessMessage(`An invitation has been sent to ${email}`);
          setLoading(false);
        },
        (error) => {
          handleError(error, setErrorMessage, setLoading);
        }
      );
    }
  };

  const handleCancel = () => {
    navigate("/admin");
  };

  return (
    <div className="card card-container tablet-left">
      <h2>Invite</h2>
      <Formik
        initialValues={
          {
            email: "",
            roleId: "",
          } as InviteForm
        }
        validate={(values) => {
          const errors = {} as any;
          if (!values.email) {
            errors.email = "Email is required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (values.roleId === "") {
            errors.roleId = "Role is required";
          }

          return errors;
        }}
        onSubmit={(formValues, actions) => {
          handleInvite(formValues, actions);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div>
              <div className="form-group mt-4 mb-2">
                <Field
                  name="email"
                  type="email"
                  placeholder="Enter email address for invite"
                  className="form-control"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="white"
                />
              </div>
              <div className="form-group mb-2">
                <Field name="roleId" as="select" className="form-control">
                  <option value="">Select Role</option>
                  <option value={Roles.Admin}>Law Admin</option>
                  <option value={Roles.Manager}>Staff Manager</option>
                </Field>
                <ErrorMessage
                  name="roleId"
                  component="div"
                  className="white"
                />
              </div>
              <div className="form-group mt-4 mb-2">
                <button
                  onClick={handleCancel}
                  type="button"
                  className="btn btn-light btn-outline me-5"
                  disabled={loading}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-light btn-outline"
                  disabled={loading || !!errors.email || !!errors.roleId}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm me-1"></span>
                  )}
                  Invite
                </button>
              </div>
            </div>
            {errorMessage && (
              <div className="form-group mb-2">
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              </div>
            )}
            {successMessage && (
              <div className="form-group mb-2">
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
