import { useCallback, useState } from "react";
import * as Papa from "papaparse";

import UploadFiles from "./upload-files.component";
import { useDataProcessingLogsService } from "../services/data-processing-logs.service";

interface UploadVoterProps {
  filePath: string;
  urlPath: string;
  urlIndex: number;
  possibleHeaderChoices: string[];
  isValid: boolean;
  additionalData: { [key: string]: string };
  isCsvHeaderSet: boolean;
  onUploadComplete: () => void;
  onSetRows: (rows: string[][]) => void;
  onSetHeaderFields: (headerFields: string[]) => void;
}

export default function UploadVoter(props: UploadVoterProps) {
  const { getExternalDataLogsByPartId } = useDataProcessingLogsService();

  const [isProcessing, setIsProcessing] = useState(false);
  const [processingLog, setProcessingLog] = useState<string>();
  const [filePath, setFilePath] = useState<string>(props.filePath);

  const startGettingLogs = useCallback(
    (externalPartId: string) => {
      let interval = setInterval(async () => {
        const logs = await getExternalDataLogsByPartId(externalPartId);
        if (logs && logs.length) {
          const lastLog = logs[logs.length - 1];
          if (lastLog.operation.includes("finished")) {
            clearInterval(interval);
            setIsProcessing(false);
          }
          setProcessingLog(lastLog.operation);
        }
      }, 2000);
    },
    [getExternalDataLogsByPartId]
  );

  const filterHeaders = (r: Papa.ParseStepResult<unknown>): string[] => {
    const regExDate = /\d{1,2}\/\d{1,2}\/\d{2,4}/;
    return r.meta.fields?.filter((f) => !regExDate.test(f)) || [];
  };

  const handleReplaceFile = () => {
    setFilePath("");
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length !== 1) {
      return;
    }
    let linesParsed = 0;
    const rows: string[][] = [];
    let headerFields: string[] = [];
    Papa.parse(e?.target.files[0], {
      step: (r, p) => {
        if (linesParsed === 0) {
          headerFields = filterHeaders(r);
          if (!props.isCsvHeaderSet) {
            props.onSetHeaderFields(headerFields);
          }
        }
        linesParsed++;
        const row = [] as string[];
        const obj = r.data as any;
        for (const field of headerFields) {
          row.push(obj[field]?.toString() || "");
        }
        rows.push(row);
        // stop after 20
        if (linesParsed === 20) {
          p.abort();
        }
      },
      complete: (r) => {
        props.onSetRows(rows);
      },
      header: true,
    });
  };

  return (
    <div className="row gy-2" key={props.urlIndex}>
      <div className="col-sm-12">
        <UploadFiles
          filePath={filePath}
          uploadUrlPath={props.urlPath}
          fileTypes=".csv"
          isValid={() => props.isValid}
          uploadProcessing={() => {
            setIsProcessing(true);
          }}
          uploadComplete={(result) => {
            props.onUploadComplete();
            startGettingLogs(result);
          }}
          uploadError={(msg) => {
            setIsProcessing(false);
          }}
          fileSelected={handleFileChange}
          replaceFile={() => handleReplaceFile()}
          additionalData={props.additionalData}
        />
        {isProcessing && (
          <div>
            <span className="spinner-border spinner-border-sm me-1 text-secondary"></span>
            <span className="small">{processingLog}</span>
          </div>
        )}
      </div>
    </div>
  );
}
