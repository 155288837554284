import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDataProcessingLogsService } from "../services/data-processing-logs.service";
import { useMatterStatusService } from "../services/matter-status.service";
import { useMatterService } from "../services/matter.service";
import { useRuleService } from "../services/rule.service";
import { DocumentUploadInfo } from "../types/matter.types";
import ConfirmModal from "./confirm-modal";
import SelectTemplate from "./select-template.component";
import UploadFiles from "./upload-files.component";

interface MatterUploadSignaturesProps {
  refreshChecklist: () => void;
}

export default function MatterUploadSignatures({
  refreshChecklist,
}: MatterUploadSignaturesProps) {
  const { deleteUpload } = useMatterService();
  const { getSignedPetitionInfoById } = useMatterStatusService();
  const { getSignatureSheetLogsByUploadId } = useDataProcessingLogsService();

  const params = useParams();
  const matterId = params?.matterId;

  const [signedPetitionUploadInfo, setSignedPetitionUploadInfo] =
    useState<DocumentUploadInfo>({
      count: 0,
      lastUploadId: "",
      lastUploadedBy: "",
      lastUploadedOn: "",
    });

  const [templateId, setTemplateId] = useState("");
  const [signedPetitionsProcessing, setSignedPetitionsProcessing] =
    useState(false);
  const [processingLog, setProcessingLog] = useState<string>();
  const [showDeleteUploadConfirmModal, setShowDeleteUploadConfirmModal] =
    useState(false);

  const populateSignedPetitionInfo = useCallback(() => {
    getSignedPetitionInfoById(matterId?.toString() || "").then((info) => {
      setSignedPetitionUploadInfo(info);
    });
  }, [getSignedPetitionInfoById, matterId]);

  useEffect(() => {
    populateSignedPetitionInfo();
  }, [populateSignedPetitionInfo]);

  const handleTemplateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTemplateId(e.target.value);
  };

  const { getRuleCount } = useRuleService();
  const [ruleCount, setRuleCount] = useState(0);

  const getCountOfRules = useCallback(() => {
    getRuleCount(matterId?.toString() || "").then((count: number) => {
      setRuleCount(count);
    });
  }, [getRuleCount, matterId]);

  useEffect(() => {
    getCountOfRules();
  }, [getCountOfRules]);

  const handleDeleteUpload = useCallback(
    async (uploadId: string) => {
      await deleteUpload(uploadId);
      await populateSignedPetitionInfo();
    },
    [deleteUpload]
  );

  const startGettingLogs = useCallback(
    (uploadId: string) => {
      let interval = setInterval(async () => {
        const logs = await getSignatureSheetLogsByUploadId(uploadId);
        if (logs && logs.length) {
          const lastLog = logs[logs.length - 1];
          if (lastLog.operation.includes("finished")) {
            clearInterval(interval);
            setSignedPetitionsProcessing(false);
            getCountOfRules();
            populateSignedPetitionInfo();
            refreshChecklist();
          }
          setProcessingLog(lastLog.operation);
        }
      }, 2000);
    },
    [getCountOfRules, getSignatureSheetLogsByUploadId, refreshChecklist]
  );

  return (
    <>
      <div className="mt-4">
        <div>
          <h3>Signed Petitions</h3>

          <SelectTemplate templateChange={handleTemplateChange} />

          <UploadFiles
            uploadUrlPath={`upload/signedpetitions?templateId=${templateId}&matterId=${matterId}`}
            fileTypes=".tiff, .pdf, .zip"
            isMultiFile={true}
            isValid={() => {
              if (templateId !== "") {
                return true;
              }
              return false;
            }}
            uploadProcessing={() => setSignedPetitionsProcessing(true)}
            uploadComplete={(uploadData) => {
              populateSignedPetitionInfo();
              startGettingLogs(uploadData);
            }}
            uploadError={() => setSignedPetitionsProcessing(false)}
          />
          {signedPetitionsProcessing && (
            <div>
              <span className="spinner-border spinner-border-sm me-1 text-secondary"></span>
              <span className="small">{processingLog}</span>
            </div>
          )}
          {!signedPetitionsProcessing && !!signedPetitionUploadInfo?.count && (
            <>
              <span className="small">
                {`${signedPetitionUploadInfo?.count} signed petitions have been uploaded. `}
              </span>
              <span className="small">
                {`Last upload by ${signedPetitionUploadInfo?.lastUploadedBy} on ${signedPetitionUploadInfo?.lastUploadedOn}`}
              </span>
              <a role="button" className="ms-2" onClick={() => handleDeleteUpload(signedPetitionUploadInfo?.lastUploadId)}>
                <FontAwesomeIcon icon={faTrashCan} className="pt-1" />
              </a>
            </>
          )}
        </div>
        <div>
          <span className="small">{ruleCount} rules have been created.</span>
        </div>
      </div>
      <ConfirmModal
        message="Are you sure you want to delete the most recent upload (all of its sheets and work)?"
        action={() =>
          handleDeleteUpload(signedPetitionUploadInfo?.lastUploadId)
        }
        isOpen={showDeleteUploadConfirmModal}
        close={() => {
          setShowDeleteUploadConfirmModal(false);
        }}
      />
    </>
  );
}
