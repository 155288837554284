import { FullSheetDeficiency } from "../types/deficiency.types";

export type DeficienciesBySheetProps = {
  loadingSheetDeficiencies: boolean;
  sheetDeficiencies: FullSheetDeficiency[];
  errorSheetDeficiencies: string;
};

export default function DeficienciesBySheet({
  loadingSheetDeficiencies,
  sheetDeficiencies,
  errorSheetDeficiencies,
}: DeficienciesBySheetProps) {
  return (
    <div className="row mt-5">
      <div className="col-sm-12">
        {loadingSheetDeficiencies && (
          <div>
            <span className="spinner-border spinner-border-sm me-1 text-secondary"></span>
            <span>Loading sheet deficiencies...</span>
          </div>
        )}

        {sheetDeficiencies?.length > 0
          ? sheetDeficiencies?.map((deficiency, i) => (
              <div key={i} className="row">
                <div className="col-md-4">
                  {deficiency.deficiencyBadges?.length > 0 &&
                    deficiency.deficiencyBadges?.map((badge, j) => (
                      <div key={j}>
                        <span style={{ color: "#FF0000" }}>
                          {badge.badgeNumber}
                        </span>
                        {badge.workId && (
                          <a href={`/work/${badge.workId}`} className="ms-2">
                            {badge.badgeDescription}
                          </a>
                        )}
                        {!badge.workId && (
                          <span className="ms-2">{badge.badgeDescription}</span>
                        )}
                      </div>
                    ))}
                </div>
                {deficiency.image?.fileContents && (
                  <div className="col-md-8">
                    <img
                      alt="deficiency"
                      style={{ maxWidth: 730 }}
                      src={`data:image/png;base64,${deficiency.image.fileContents}`}
                    />
                  </div>
                )}
              </div>
            ))
          : !loadingSheetDeficiencies && (
              <div className="row">
                <div className="col">
                  There are no sheet deficiencies to display
                </div>
              </div>
            )}

        {errorSheetDeficiencies && (
          <div className="alert alert-danger" role="alert">
            {errorSheetDeficiencies}
          </div>
        )}
      </div>
    </div>
  );
}
