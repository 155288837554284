import {
  faCircleLeft,
  faCircleUp,
  faPlayCircle,
  faPenToSquare,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../AuthProvider";
import { formatDateString, handleError } from "../common/utils";
import { useMatterService } from "../services/matter.service";
import { useRuleService } from "../services/rule.service";
import { Matter, MatterVariable } from "../types/matter.types";
import { Roles } from "../types/user.types";
import { useProgressWithRefresh } from '../hooks/useProgressWithRefresh'
import { useChecklistWithRefresh } from '../hooks/useChecklistWithRefresh'
import MatterChecklist from "./matter-checklist.component";
import MatterProgress from "./matter-progress.component";
import MatterUploadCirculators from "./upload-circulators.component";
import MatterUploadSignatures from "./matter-upload-signatures.component";
import MatterParameters from "./matter-parameters.component";
import MatterVoterStatus from "./matter-voter-status.component";
import { useMatterParametersService } from "../services/matter-parameters.service";

export default function MatterDetails() {
  const { authenticatedUser } = useAuthContext();
  const { getMatterById, getValidSignaturesCsv } = useMatterService();
  const { runRules } = useRuleService();
  const { getMatterParameters } = useMatterParametersService();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isRunningRules, setIsRunningRules] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorExport, setErrorExport] = useState("");
  const [matter, setMatter] = useState<Matter | null>(null);
  const { checklist, refreshChecklist } = useChecklistWithRefresh();
  const [matterParameters, setMatterParameters] = useState<MatterVariable[]>(
    []
  );
  const csvLink = useRef<HTMLAnchorElement>(null);
  const { progress, isLoading: progressIsLoading, errorMessage: progressErrorMessage, refreshProgress} = useProgressWithRefresh()

  const params = useParams();
  const matterId = params?.matterId;
  const isNew = matterId === "new";

  useEffect(() => {
    if (authenticatedUser?.roleId !== Roles.Admin) {
      navigate("/login");
    } else if (!isNew && matterId) {
      setLoading(true);
      getMatterById(matterId).then(
        (matter) => {
          setLoading(false);
          setMatter(matter);
          loadParameters();
          refreshProgress();
        },
        (error) => {
          handleError(error, setErrorMessage, setLoading);
        }
      );
    }
  }, [isNew, matterId, authenticatedUser?.roleId, getMatterById, navigate, refreshProgress]);

  const loadParameters = useCallback(() => {
    setErrorMessage("");
    setLoading(true);

    getMatterParameters(matterId?.toString() || "").then(
      (parameters) => {
        setLoading(false);
        setMatterParameters(parameters);
      },
      (error) => {
        handleError(error, setErrorMessage, setLoading);
      }
    );
  }, [matterId, getMatterParameters]);

  const handleRefresh = async () => {
    refreshChecklist();
    loadParameters();
  }

  const exportValidSignatures = () => {
    setErrorExport("");
    getValidSignaturesCsv(matterId!).then(
      (csv) => {
        csvLink.current?.setAttribute("download", "CSVData.csv");
        csvLink.current?.setAttribute(
          "href",
          URL.createObjectURL(new Blob([csv], { type: "text/csv" }))
        );
        csvLink.current?.click();
      },
      (error) => {
        handleError(error, setErrorExport);
      }
    );
  };

  const handleRunRules = async () => {
    setIsRunningRules(true);
    await runRules(matterId!);
    refreshProgress();
    setIsRunningRules(false);
  };

  return isNew || matter ? (
    <div className="container">
      <div className="d-flex flex-row mb-4">
        <div className="me-auto" style={{ marginLeft: "-12px" }}>
          <Link to="/admin" className="btn btn-sw btn-sm btn-outline">
            <FontAwesomeIcon icon={faCircleLeft} className="me-2" />
            Back to Dashboard
          </Link>
        </div>
        {!isNew && (
          <div className="ms-auto" style={{ marginRight: "-12px" }}>
            <button onClick={exportValidSignatures} className="btn btn-sw btn-sm btn-outline me-2">
              Export Valid
            </button>
            <a ref={csvLink} style={{ display: "none" }} target="_blank"></a>
            <Link
              to={`/admin/matters/${matterId}/deficiencies`}
              className="btn btn-sw btn-sm btn-outline"
            >
              <FontAwesomeIcon icon={faCircleUp} className="me-2" />
              Deficiencies
            </Link>
          </div>
        )}
      </div>
      <div className="row tablet-view">
        <div className="col-4 tablet-left d-flex flex-column">
          <div className="tablet-buffer d-flex flex-fill flex-column mb-3">
            <div>
              <span>Matter</span>
              <h2>{matter?.name || "New"}</h2>
              {!isNew && (
                <>
                  <span>
                    {matter?.numberSignaturesRequired} due by{" "}
                    {formatDateString(matter?.dueDate?.toString())}
                  </span>
                  <a
                    role="button"
                    onClick={() => {
                      navigate(`/admin/matters/${matterId}`);
                    }}
                  >
                    <FontAwesomeIcon icon={faPenToSquare} className="ms-2" />
                  </a>
                </>
              )}
            </div>
            {!isNew && (
              <>
                <div className="mt-4 mb-auto">
                  <MatterChecklist
                    checklist={checklist}
                    refreshChecklist={refreshChecklist}
                  />
                </div>
                <div>
                  <button
                    className="btn btn-light btn-outline"
                    onClick={handleRunRules}
                  >
                    {isRunningRules ? (
                      <span className="spinner-border spinner-border-sm me-1 text-secondary"></span>
                    ) : (
                      <FontAwesomeIcon icon={faPlayCircle} className="me-2" />
                    )}
                    Run Rules
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col-8 tablet-right">
          <div className="tablet-buffer">
            <MatterProgress progress={progress} isLoading={progressIsLoading} errorMessage={progressErrorMessage}  />
            <MatterParameters refreshChecklist={handleRefresh} matterParameters={matterParameters} />
            <MatterUploadCirculators refreshChecklist={refreshChecklist} />

            <MatterVoterStatus />

            <MatterUploadSignatures refreshChecklist={handleRefresh} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>{!loading && "This matter is invalid"}</>
  );
}
