import {
  faCircleLeft,
  faFlag,
  faShareSquare,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormikProps } from "formik";
import { SyntheticEvent } from "react";
import { convertFieldValuesToStrings } from "../common/utils";
import { RegisteredVoterFlags, TaskType, ValidationForm, Work, WorkField } from "../types/work.types";
import ColumnValidation from "./column-validation.component";
import FieldValidation from "./field-validation.component";
import VoterValidation from "./voter-validation.component";

interface WorkItemProps {
  work: Work;
  onSave?: (fields: WorkField[], 
    externalDataRecordId?: string | null,
    registeredVoterFlags?: RegisteredVoterFlags,
  ) => void;
  onNext: (fields: WorkField[], 
    externalDataRecordId?: string | null,
    registeredVoterFlags?: RegisteredVoterFlags,
  ) => void;
  onPrevious?: (e: SyntheticEvent) => void;
  onFlagForReview?: (e: SyntheticEvent) => void;
  onTakeBreak?: (e: SyntheticEvent) => void;
  formikRef: React.RefObject<FormikProps<ValidationForm>>;
  errorMessage: string;
}

const WorkItem = ({
  work,
  onSave,
  onNext,
  onPrevious,
  onFlagForReview,
  onTakeBreak,
  formikRef,
  errorMessage,
}: WorkItemProps) => {

  function handleSubmitCorrection(fields: WorkField[]) {
    if (onSave) {
      onSave(fields);
    }
  }

  const showTaskWorkForTaskType = () => {
    if (work) {
      work.fields = convertFieldValuesToStrings(work.fields);
      const isRegistrationVerification = !!(work!.taskTypeId & TaskType.ExternalDataSourceVerification);
      const otherThanRegistrationVerification = work!.taskTypeId & ~TaskType.ExternalDataSourceVerification;
      const isSignatureTableColumn = !!(work!.taskTypeId & TaskType.SignatureTableColumn);

      if (isRegistrationVerification) {
        return (
          <>
            {!!otherThanRegistrationVerification && (
              <FieldValidation
                work={work}
                handleNextWork={handleSubmitCorrection}
                formikRef={formikRef}
                nextLabel="Submit Correction"
                isSignatureTable={isSignatureTableColumn}
                />
            )}

            <VoterValidation
              work={work}
              handleNextWork={onNext}
              formikRef={formikRef}
            />
          </>
        );
      } else if (work?.showWholeColumn) {
        return (
          <ColumnValidation
            work={work}
            handleNextWork={onNext}
            formikRef={formikRef}
          />
        );
      } else {
        return (
          <FieldValidation
            work={work}
            handleNextWork={onNext}
            formikRef={formikRef}
            isSignatureTable={isSignatureTableColumn}
          />
        );
      }
    }
  };

  return (
    <div className="row tablet-view justify-content-start">
      <div className="col-4 tablet-left d-flex flex-column">
        <div className="tablet-buffer d-flex flex-fill flex-column mb-2">
          <div className="mb-auto">
            <h3>{work.taskName}</h3>
            {work.taskDescription && (
              <ul className="mt-3">
                {work.taskDescription && work.taskDescription.split("\n").map((instruction, i) => (
                  <li key={i}>{instruction}</li>
                ))}
              </ul>
            )}
            <div>
              <ul className="mt-3">
                {work.violationCriteria && work.violationCriteria.map((rule) => (
                  <li key={rule.ruleId}>{rule.name}</li>
                ))}
              </ul>
            </div>
          </div>
          <div>
            {onTakeBreak && (
              <button
                className="btn btn-light btn-outline"
                onClick={onTakeBreak}
              >
                <FontAwesomeIcon icon={faShareSquare} className="me-2" />
                Take a Break
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="col-8 tablet-right d-flex flex-column">
        <div className="tablet-buffer d-flex flex-fill flex-column mb-2">
          <div className="mb-auto">
            {showTaskWorkForTaskType()}

            {errorMessage && (
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="d-flex left-right">
              <div className="me-auto">
                {onPrevious && (
                  <button className="btn btn-sw" onClick={onPrevious}>
                    <FontAwesomeIcon icon={faCircleLeft} className="me-2" />
                    Previous Item
                  </button>
                )}
              </div>
              <div>
                {onFlagForReview && (
                  <button className="btn btn-sw-m" onClick={onFlagForReview}>
                    <FontAwesomeIcon icon={faFlag} className="me-2" />
                    Flag for Review
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkItem;
