import { useCallback } from 'react';
import { ExternalData, ExternalDataField, ExternalDataSource, UploadType } from '../types/external-data.types';
import { useAuthenticatedRequestService } from './service.base';

export function useExternalDataService() {
    const EXTERNALDATA_PATH = 'externaldata/';
    const { authGet, authPost, authDelete } = useAuthenticatedRequestService();

    const getAllExternalData = useCallback(async function(uploadType: UploadType): Promise<ExternalDataSource[]> {
        return await authGet(`${EXTERNALDATA_PATH}?uploadType=${uploadType}`);
    }, [authGet]);

    const getExternalDataSourceById = useCallback(async function(externalDataSourceId: string): Promise<ExternalDataSource> {
        return await authGet(`${EXTERNALDATA_PATH}id/${externalDataSourceId}`);
    }, [authGet]);

    const getExternalDataSourceMatters = useCallback(async function(matterId: string): Promise<ExternalDataSource[]> {
        return await authGet(`${EXTERNALDATA_PATH}matter/${matterId}`);
    }, [authGet]);

    const postExternalDataSourceMatters = useCallback(async function(matterId: string, formValues: Record<string, boolean>): Promise<void> {
        return await authPost(`${EXTERNALDATA_PATH}matter/${matterId}`, formValues);
    }, [authPost]);

    const getExternalData = useCallback(async function(id: string): Promise<ExternalData> {
        return await authGet(EXTERNALDATA_PATH + id);
    }, [authGet]);

    const searchExternalData = useCallback(async function(workId: string, externalFields: ExternalDataField[]): Promise<ExternalData[]> {
        return await authPost(`${EXTERNALDATA_PATH}search/${workId}`, externalFields);
    }, [authPost]);

    const checkExternalData = useCallback(async function (id: string): Promise<number> {
        return await authGet(`upload/registeredvoters/${id}`);
    }, [authGet]);

    const deleteExternalData = useCallback(async function (id: string): Promise<null> {
        return await authDelete(`upload/registeredvoters/${id}`);
    }, [authDelete]);


    const funcs = {
        getAllExternalData,
        getExternalDataSourceById,
        getExternalDataSourceMatters,
        postExternalDataSourceMatters,
        getExternalData,
        searchExternalData,
        checkExternalData,
        deleteExternalData,
    };
    return funcs;
}
