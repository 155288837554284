import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMatterStatusService } from "../services/matter-status.service";
import { DocumentUploadInfo } from "../types/matter.types";
import UploadFiles from "./upload-files.component";

interface UploadCirculatorsProps {
  refreshChecklist: () => void;
}

export default function UploadCirculators({refreshChecklist}: UploadCirculatorsProps) {
  const { getCirculatorRegistrationInfoById } = useMatterStatusService();
  const params = useParams();
  const matterId = params?.matterId;

  const [
    circulatorRegistrationUploadInfo,
    setCirculatorRegistrationUploadInfo,
  ] = useState<DocumentUploadInfo>({
    count: 0,
    lastUploadId: "",
    lastUploadedBy: "",
    lastUploadedOn: "",
  });
  const [registeredCirculatorsProcessing, setRegisteredCirculatorsProcessing] =
    useState(false);

  const populateCirculatorRegistrationInfo = useCallback(() => {
    getCirculatorRegistrationInfoById(matterId?.toString() || "").then(
      (info) => {
        setCirculatorRegistrationUploadInfo(info);
      }
    );
  }, [getCirculatorRegistrationInfoById, matterId]);

  useEffect(() => {
    populateCirculatorRegistrationInfo();
  }, [populateCirculatorRegistrationInfo]);

  return (
    <div className="mt-4">
      <h3>Circulator Info</h3>
      <UploadFiles
        uploadUrlPath={`upload/circulators?matterId=${matterId}`}
        fileTypes=".csv"
        uploadProcessing={() => setRegisteredCirculatorsProcessing(true)}
        uploadComplete={() => {
          populateCirculatorRegistrationInfo();
          setRegisteredCirculatorsProcessing(false);
          refreshChecklist();
        }}
        uploadError={() => setRegisteredCirculatorsProcessing(false)}
      />
      {registeredCirculatorsProcessing && (
        <div>
          <span className="spinner-border spinner-border-sm me-1 text-secondary"></span>
          <span className="small">Processing file...</span>
        </div>
      )}
      {!registeredCirculatorsProcessing && (
          <span className="small">
            {`${circulatorRegistrationUploadInfo?.count} circulator registrations have been uploaded. `}
            {circulatorRegistrationUploadInfo?.count > 0
              ? `Last upload by ${circulatorRegistrationUploadInfo?.lastUploadedBy} on ${circulatorRegistrationUploadInfo?.lastUploadedOn}`
              : ""}
          </span>
      )}
    </div>
  );
}
