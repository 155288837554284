import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";

import { MatterVariable } from "../types/matter.types";
import { handleError } from "../common/utils";
import { useMatterParametersService } from "../services/matter-parameters.service";

interface MatterParametersProps {
  refreshChecklist: () => void;
  matterParameters: MatterVariable[];
}

interface MatterParametersForm {
  matterParameters: MatterVariable[];
}

export default function MatterParameters({
  refreshChecklist,
  matterParameters,
}: MatterParametersProps) {
  const {
    upsertMatterParameters,
    deleteMatterParameters,
  } = useMatterParametersService();
  const params = useParams();
  const matterId = params?.matterId;

  const [errorMessage, setErrorMessage] = useState("");
  const [savingParameters, setSavingParameters] = useState(false);
  const [matterParametersDeleted, setMatterParametersDeleted] = useState<
    number[]
  >([]);

  const validateVariable = (value: string) => {
    let error;
    if (value === "") {
      error = "Parameter field required";
    }
    return error;
  };

  const handleSave = (formValues: MatterParametersForm) => {
    const { matterParameters } = formValues;

    const saveMatterParameters = () => {
      upsertMatterParameters(matterId?.toString() || "", matterParameters).then(
        () => {
          setSavingParameters(false);
          refreshChecklist();
        },
        (error) => {
          handleError(error, setErrorMessage, setSavingParameters);
        }
      );
    };

    setErrorMessage("");
    setSavingParameters(true);

    if (matterParametersDeleted.length) {
      deleteMatterParameters(
        matterId?.toString() || "",
        matterParametersDeleted
      ).then(
        () => {
          setMatterParametersDeleted([]);
          saveMatterParameters();
        },
        (error) => {
          handleError(error, setErrorMessage, setSavingParameters);
        }
      );
    } else {
      saveMatterParameters();
    }
  };

  const handleDelete = (
    remove: (index: number) => any,
    index: number,
    variable: MatterVariable
  ) => {
    if (variable.matterVariableId) {
      setMatterParametersDeleted([
        ...matterParametersDeleted,
        variable.matterVariableId,
      ]);
    }
    remove(index);
  };

  return (
    <div className="mt-4">
      <h3>Parameters</h3>
      <div className="row">
        <div className="col-sm-5">Name</div>
        <div className="col-sm-6">Value</div>
        <div className="col-sm-1"></div>
      </div>

      <Formik
        enableReinitialize
        initialValues={{
          matterParameters: matterParameters,
        }}
        onSubmit={handleSave}
      >
        {({ values }) => (
          <Form>
            <FieldArray name="matterParameters">
              {({ push, remove }) => (
                <>
                  {values.matterParameters.length > 0 &&
                    values.matterParameters.map((variable, i) => (
                      <div className="row mb-1" key={i}>
                        <div className="col-sm-5">
                          <Field
                            name={`matterParameters.${i}.key`}
                            className="form-control form-control-sm d-inline"
                            placeholder="Enter parameter name"
                            type="text"
                            validate={validateVariable}
                          />
                          <ErrorMessage
                            name={`matterParameters.${i}.key`}
                            component="div"
                            className="alert alert-danger w-auto p-2"
                          />
                        </div>

                        <div className="col-sm-6">
                          <Field
                            name={`matterParameters.${i}.value`}
                            className="form-control form-control-sm d-inline"
                            placeholder="Enter parameter value"
                            type="text"
                            validate={validateVariable}
                          />
                          <ErrorMessage
                            name={`matterParameters.${i}.value`}
                            component="div"
                            className="alert alert-danger w-auto p-2"
                          />
                        </div>
                        <div className="col-sm-1">
                          <a
                            role="button"
                            onClick={() =>
                              handleDelete(
                                remove,
                                values.matterParameters.indexOf(variable),
                                variable
                              )
                            }
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              className="pt-1"
                            />
                          </a>
                        </div>
                      </div>
                    ))}
                  <div className="row">
                    <div className="d-flex left-right">
                      <button
                        type="button"
                        className="btn btn-sw btn-sm"
                        onClick={() =>
                          push({
                            matterVariableId: null,
                            key: "",
                            value: "",
                          } as MatterVariable)
                        }
                      >
                        <FontAwesomeIcon icon={faPlusSquare} className="me-2" />
                        Add
                      </button>

                      <button
                        type="submit"
                        className="btn btn-sw btn-sm"
                        disabled={savingParameters}
                      >
                        {savingParameters && (
                          <span className="spinner-border spinner-border-sm me-1"></span>
                        )}
                        Save
                      </button>
                    </div>
                  </div>
                </>
              )}
            </FieldArray>

            {errorMessage && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
