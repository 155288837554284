import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import AdminMatters from "./board-admin-matters.component";
import AdminTemplates from "./board-admin-templates.component";
import AdminDataFiles from "./board-admin-datafiles.component";
import { UploadType } from "../types/external-data.types";

export default function AdminBoard() {

  return (
    <div className="container">
      <div className="row tablet-view justify-content-start">
        <div className="col-4 tablet-left d-flex flex-column">
          <div className="tablet-buffer d-flex flex-fill flex-column mb-2">
            <div className="mb-auto">
              <span>Administrator</span>
              <h2>Home</h2>
            </div>
            <div className="">
              <Link to="/admin/invite" className="btn btn-light btn-outline">
                <FontAwesomeIcon icon={faSquarePlus} className="me-2" />
                Invite Team Member
              </Link>
            </div>
          </div>
        </div>
        <div className="col-8 tablet-right">
          <div className="tablet-buffer">
            <AdminMatters />
            <AdminTemplates />
            <AdminDataFiles uploadType={UploadType.Voter}/>
          </div>
        </div>
      </div>
    </div>
  );
}
