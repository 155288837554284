import { useCallback } from "react";
import { User } from "../types/user.types";
import { useRequestService } from "./service.base";

export function useAuthService() {
  const { httpPost } = useRequestService();

  const login = useCallback(async function(email: string, password: string): Promise<User | null> {
    return await httpPost("authenticate", {
        email,
        password
      })
      
  }, [httpPost]);
  
  return { login } as const
}
