export interface User {
    id: number,
    fullName: string,
    shortName: string | null,
    email: string;
    roleId: number,
    role: string,
    accessToken: string
  }

export interface UserStats {
  id: number,
  fullName: string,
  email: string,
  workerSpeed: number,
  deficiencies: number,
  hoursWorked: number,
  inReview: number 
}

export enum Roles {
  Admin = 1,
  Manager = 2,
  Reviewer = 3,
}