export interface Rule {
    id: number;
    name: string;
    description: string;
}

export enum RuleContextType {
    None = 0, // Do not execute
    Transcription = 1, // executed by the transcriber
    SignatureRow = 2,
    SignatureColumn = 3,
    SignatureCell = 4,
    SignatureSheet = 5,
    Circulator = 6,
    Matter = 7,
}