
import { useCallback } from 'react';
import { Work, FinishWorkDTO, MatterWorkStatusDTO } from '../types/work.types';
import { useAuthenticatedRequestService } from './service.base';

export function useWorkService() {
    const WORK_PATH = 'work/';
    const { authGet, authPut } = useAuthenticatedRequestService();

    const getWorkStatus = useCallback(async function(): Promise<MatterWorkStatusDTO[]> {
        return await authGet(WORK_PATH + "status");
    }, [authGet]);

    const getWork = useCallback(async function(id: string): Promise<Work> {
        return await authGet(WORK_PATH + id);
    }, [authGet]);

    const getNextWork = useCallback(async function(): Promise<Work | null> {
        return await authGet(WORK_PATH + "next");
    }, [authGet]);

    const getNextFlaggedWork = useCallback(async function(): Promise<Work | null> {
        return await authGet(WORK_PATH + "flagged");
    }, [authGet]);

    const getPreviousWork = useCallback(async function(id: string): Promise<Work | null> {
        return await authGet(WORK_PATH + id + "/previous");
    }, [authGet]);

    const updateWork = useCallback(async function (id: string, work: FinishWorkDTO): Promise<null> {
        return await authPut(WORK_PATH + id, work);
    }, [authPut]);

    const funcs = {
        getWorkStatus,
        getWork,
        getNextWork,
        getNextFlaggedWork,
        getPreviousWork,
        updateWork
    };
    return funcs;
}