import { useEffect, useState } from "react";
import { useTemplateService } from "../services/template.service";
import { Template } from "../types/template.types";

interface SelectTemplateProps {
  templateChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export default function SelectTemplate(props: SelectTemplateProps) {
  const { getAllTemplates } = useTemplateService();
  const [templates, setTemplates] = useState<Template[] | null>(null);

  useEffect(() => {
    getAllTemplates().then((tm) => {
      setTemplates(tm);
    });
  }, [getAllTemplates]);

  return (
    <select
      className="form-control form-control-sm w-50 mb-1"
      onChange={props.templateChange}
    >
      <option value="">Select Template</option>
      {templates?.map((template) => (
        <option key={template.id} value={template.id}>
          {template.name}
        </option>
      ))}
    </select>
  );
}
