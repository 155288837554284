import Modal from "react-modal";
Modal.setAppElement("#root");

interface ConfirmModalProps {
  isOpen: boolean;
  message: string;
  action: () => void;
  close: () => void;
}

export default function ConfirmModal({
  isOpen,
  message,
  action,
  close,
}: ConfirmModalProps) {
  const handleConfirm = () => {
    action();
    close();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          width: 600,
          transform: "translate(-50%, -50%)",
        },
      }}
      contentLabel="Confirmation Modal"
    >
      <div className="row">
        <div className="col">
          <div className="d-flex left-right">
            <h3>Confirmation</h3>
            <span
              style={{ fontSize: "1.25rem", cursor: "pointer" }}
              aria-label="Close"
              onClick={close}
              className="font-weight-bold"
            >
              X
            </span>
          </div>
        </div>
      </div>
      <div className="row mt-3 border-top">
        <div className="col py-3">{message}</div>
      </div>

      <div
        className="row align-items-end mt-3"
        style={{ minHeight: 50 }}
      >
        <div className="col">
          <button
            onClick={close}
            type="button"
            className="btn btn-outline-secondary me-2"
          >
            Cancel
          </button>
          <button type="button" onClick={handleConfirm} className="btn btn-sw">
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
}
