import { Ref, useCallback, useEffect, useState } from "react";
import {
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
  FormikErrors,
  FormikProps,
} from "formik";
import {
  RegisteredVoterFlags,
  ValidationForm,
  Work,
  WorkField,
} from "../types/work.types";
import { useExternalDataService } from "../services/external-data.service";
import { ExternalData, ExternalDataField } from "../types/external-data.types";
import { handleError } from "../common/utils";

interface FieldValidationProps {
  work: Work;
  formikRef: Ref<FormikProps<ValidationForm>> | null;
  handleNextWork: (
    fields: WorkField[],
    externalDataRecordId: string | null,
    registeredVoterFlags: RegisteredVoterFlags
  ) => void;
}

export default function VoterValidation({
  work,
  handleNextWork,
  formikRef,
}: FieldValidationProps) {
  const { getExternalData, searchExternalData } = useExternalDataService();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [voterRegistrations, setVoterRegistrations] = useState<
    ExternalData[] | null
  >(null);

  const handleSearch = useCallback(
    (fields: WorkField[], setFieldValue) => {
      const externalDataFields = fields
        .filter(field => !!field.name)
        .map((field) => ({
          name: field.name,
          value: field.value,
        }));

      if (work?.workId) {
        setErrorMessage("");
        setLoading(true);
        searchExternalData(work.workId.toString(), externalDataFields).then(
          (externalData) => {
            setLoading(false);
            setVoterRegistrations(externalData);
            console.log(`Search results`, externalData);
            setFieldValue("voterRegistrations", externalData);
          },
          (error) => {
            handleError(error, setErrorMessage, setLoading);
          }
        );
      }
    },
    [work.workId, searchExternalData]
  );

  useEffect(() => {
    if (work?.workId) {
      if (work.externalDataRecordId) {
        getExternalData(work.externalDataRecordId.toString()).then(
          (externalData) => {
            console.log(`Previous search results`, externalData);
            setVoterRegistrations([externalData]);
          }
        );
      } else if (work.fields[0].value || work.fields[1].value) {
        const externalDataFields = work.fields
          .filter(field => !!field.name)
          .map((field) => ({ name: field.name, value: field.value }));

        setErrorMessage("");
        setLoading(true);
        searchExternalData(work.workId.toString(), externalDataFields).then(
          (externalData) => {
            setLoading(false);
            console.log(`Initial search results`, externalData);
            setVoterRegistrations(externalData);
          },
          (error) => {
            handleError(error, setErrorMessage, setLoading);
          }
        );
      }
    }
  }, [work, getExternalData, searchExternalData]);

  const handleNext = (formValues: ValidationForm) => {
    // Only update externalDataRecordId from form
    handleNextWork(
      work.fields,
      formValues.externalDataRecordId ?? null,
      formValues.registeredVoterFlags
    );
  };

  const getWorkFieldIndexByName = (fields: WorkField[], name: string) => {
    return fields.findIndex((field) => field.name?.toLowerCase().includes(name))?.toString();
  };

  const getExternalDataFieldIndexByName = (
    fields: ExternalDataField[],
    name: string
  ) => {
    return fields.findIndex((field) => field.name.toLowerCase().includes(name));
  };

  return (
    <div className="row mt-3">
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={
          {
            fields: work.fields,
            externalDataRecordId: work.externalDataRecordId?.toString() || "",
            registeredVoterFlags: work.registeredVoterFlags,
            voterRegistrations: voterRegistrations,
          } as ValidationForm
        }
        validate={(values) => {
          const errors = {} as any;
          if (values.registeredVoterFlags === RegisteredVoterFlags.Valid
            && values.externalDataRecordId === "") {
            errors.fieldName = "A valid voter registration must have a selected voter";
          }
          return errors;
        }}
        onSubmit={handleNext}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <div className="row mb-2">
              <div className="col-sm-6">
                <div className="form-group">
                  <label
                    htmlFor={`fields[${getWorkFieldIndexByName(
                      values.fields,
                      "name"
                    )}].value`}
                  >
                    Name
                  </label>

                  <Field
                    name={`fields[${getWorkFieldIndexByName(
                      values.fields,
                      "name"
                    )}].value`}
                    className="form-control"
                    placeholder="Enter name"
                    type="text"
                  />
                  <ErrorMessage
                    name={`fields[${getWorkFieldIndexByName(
                      values.fields,
                      "name"
                    )}].value`}
                    component="div"
                    className="alert alert-danger p-2"
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label
                    htmlFor={`fields[${getWorkFieldIndexByName(
                      values.fields,
                      "address"
                    )}].value`}
                  >
                    Address
                  </label>

                  <Field
                    name={`fields[${getWorkFieldIndexByName(
                      values.fields,
                      "address"
                    )}].value`}
                    className="form-control"
                    placeholder="Enter address"
                    type="text"
                  />
                  <ErrorMessage
                    name={`fields[${getWorkFieldIndexByName(
                      values.fields,
                      "address"
                    )}].value`}
                    component="div"
                    className="alert alert-danger p-2"
                  />
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-sm-12 text-center">
                <button
                  type="button"
                  className="btn btn-sw"
                  onClick={() => {
                    handleSearch(values.fields, setFieldValue);
                  }}
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm me-1"></span>
                  )}
                  Search
                </button>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col">
                <FieldArray name="voterRegistrations">
                  {() => (
                    <div className="border-left border-bottom border-right ps-2 rounded">
                      <div
                        style={{
                          maxHeight: 125,
                          overflowY: "auto",
                          overflowX: "hidden",
                        }}
                      >
                        {voterRegistrations &&
                          voterRegistrations.length > 0 && (
                            <div className="row" key="none">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <div className="form-check form-check-inline">
                                    <Field
                                      id="externalDataRecordId-0"
                                      className="form-check-input"
                                      type="radio"
                                      name="externalDataRecordId"
                                      required
                                      onChange={() => setFieldValue("externalDataRecordId","")}
                                      checked={values.externalDataRecordId === ""}
                                    />
                                    <label
                                      className="m-0"
                                      htmlFor="externalDataRecordId-0"
                                    >
                                      None of these
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6"></div>
                            </div>
                          )}

                        {voterRegistrations &&
                          voterRegistrations.length > 0 &&
                          voterRegistrations?.map((voterRegistration, i) => (
                            <div className="row" key={voterRegistration.id}>
                              <div className="col-sm-6">
                                <div className="form-check form-check-inline">
                                  <Field
                                    id={`externalDataRecordId-${voterRegistration.id}`}
                                    className="form-check-input"
                                    type="radio"
                                    name="externalDataRecordId"
                                    required
                                    value={voterRegistration.id.toString()}
                                    onChange={() => {
                                      setFieldValue("externalDataRecordId", voterRegistration.id.toString());
                                      setFieldValue("registeredVoterFlags", RegisteredVoterFlags.Valid);
                                    }}
                                    />
                                  <label
                                    className="m-0"
                                    htmlFor={`externalDataRecordId-${voterRegistration.id}`}
                                  >
                                    {
                                      voterRegistration.fields[
                                        getExternalDataFieldIndexByName(
                                          voterRegistration.fields,
                                          "name"
                                        )
                                      ]?.value
                                    }
                                  </label>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <label
                                  className="m-0"
                                  htmlFor={`externalDataRecordId-${voterRegistration.id}`}
                                >
                                  {
                                    voterRegistration.fields[
                                      getExternalDataFieldIndexByName(
                                        voterRegistration.fields,
                                        "address"
                                      )
                                    ]?.value
                                  }
                                </label>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </FieldArray>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-sm-3">
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <Field
                      id="noMismatch"
                      className="form-check-input"
                      type="radio"
                      name="registeredVoterFlags"
                      required
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          "registeredVoterFlags",
                          RegisteredVoterFlags.Valid
                        );
                      }}
                      checked={
                        values.registeredVoterFlags ===
                        RegisteredVoterFlags.Valid
                      }
                    />
                    <label className="m-0" htmlFor="noMismatch">
                      Valid/No Mismatch
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <Field
                      id="mismatchedName"
                      className="form-check-input"
                      type="radio"
                      name="registeredVoterFlags"
                      required
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          "registeredVoterFlags",
                          RegisteredVoterFlags.MismatchedName
                        );
                      }}
                      checked={
                        values.registeredVoterFlags ===
                        RegisteredVoterFlags.MismatchedName
                      }
                    />
                    <label className="m-0" htmlFor="mismatchedName">
                      Mismatched Name
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <Field
                      id="mismatchedAddress"
                      className="form-check-input"
                      type="radio"
                      name="registeredVoterFlags"
                      required
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          "registeredVoterFlags",
                          RegisteredVoterFlags.MismatchedAddress
                        );
                      }}
                      checked={
                        values.registeredVoterFlags ===
                        RegisteredVoterFlags.MismatchedAddress
                      }
                    />
                    <label className="m-0" htmlFor="mismatchedAddress">
                      Mismatched Address
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <Field
                      id="notRegistered"
                      className="form-check-input"
                      type="radio"
                      name="registeredVoterFlags"
                      required
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          "registeredVoterFlags",
                          RegisteredVoterFlags.NotRegistered
                        );
                      }}
                      checked={
                        values.registeredVoterFlags ===
                        RegisteredVoterFlags.NotRegistered
                      }
                    />
                    <label className="m-0" htmlFor="notRegistered">
                      Not Registered
                    </label>
                  </div>
                </div>
              </div>

            </div>

            {errorMessage && (
              <div className="row mt-1">
                <div className="col">
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row text-center">
              <div className="col-sm-12 mt-1">
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-secondary"
                    disabled={isSubmitting}
                  >
                    {!isSubmitting && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-arrow-right me-1"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        ></path>
                      </svg>
                    )}
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm me-1"></span>
                    )}
                    Next
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
