export interface ExternalDataSourcePart {
  id: number
  fileName: string
  originalUploadedFileName: string
  uploadedBy: string
  uploadedOn: string;
  partNumber: number
}

export interface ExternalDataSource {
  id: number
  fileName: string
  uploadedBy: string
  uploadedOn: string;
  county: string
  totalParts: number
  uploadType: UploadType
  externalDataSourceParts: ExternalDataSourcePart[]
}

export enum UploadType {
  Unknown = "Unknown",
  Circulator = "Circulator",
  Voter = "Voter"
}

export interface ExternalData {
  id: number
  fields: ExternalDataField[];
}

export interface ExternalDataField {
  name: string;
  value: string;
}
