import { faCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../AuthProvider";
import { handleError } from "../common/utils";
import { useDeficiencyService } from "../services/deficiency.service";
import { useRuleService } from "../services/rule.service";
import { Deficiency, FullSheetDeficiency } from "../types/deficiency.types";
import { Rule } from "../types/rule.types";
import { Roles } from "../types/user.types";
import { ViolationCriterion } from "../types/work.types";
import { AddMatterDeficiencyModal } from "./add-matter-deficiency-modal";
import DeficienciesByRule from "./deficiencies-by-rule.component";
import DeficienciesBySheet from "./deficiencies-by-sheet.component";

export default function Deficiencies() {
  const { authenticatedUser } = useAuthContext();
  const navigate = useNavigate();
  const {
    getRuleDeficienciesById,
    getFullSheetDeficienciesById: getFullsheetDeficienciesById,
    getDeficiencyCsv,
    postMatterDeficiency,
    clearMatterDeficiency
  } = useDeficiencyService();
  const { getRules, getMatterViolations } = useRuleService();
  const params = useParams();
  const [sheetDeficiencies, setSheetDeficiencies] = useState<
    FullSheetDeficiency[]
  >([]);
  const [ruleDeficiencies, setRuleDeficiencies] = useState<Deficiency[]>([]);

  const [loadingSheetDeficiencies, setLoadingSheetDeficiencies] =
    useState(false);
  const [loadingRuleDeficiencies, setLoadingRuleDeficiencies] = useState(false);

  const [errorSheetDeficiencies, setErrorSheetDeficiencies] = useState("");
  const [errorRuleDeficiencies, setErrorRuleDeficiencies] = useState("");
  const [errorRules, setErrorRules] = useState("");
  const [errorExport, setErrorExport] = useState("");

  const [showSheetDeficiencies, setShowSheetDeficiencies] = useState(false);
  const [sheetDeficienciesLoaded, setSheetDeficienciesLoaded] = useState(false);
  const [rules, setRules] = useState<Rule[]>([]);
  const csvLink = useRef<HTMLAnchorElement>(null);
  const [showMatterDeficiencyModal, setShowMatterDeficiencyModal] =
    useState(false);
  const [matterViolations, setMatterViolations] = useState<ViolationCriterion[]>([]);
  const [errorMatterDeficiency, setErrorMatterDeficiency] = useState("");

  const matterId = params?.matterId;

  const loadRulesWithDeficiency = () => 
    getRules(matterId!).then(
      (r) => {
        setRules(r);
      },
      (error) => {
        handleError(error, setErrorRules, undefined);
      }
    );
  useEffect(() => {
    if (authenticatedUser?.roleId !== Roles.Admin) {
      navigate("/login");
    } else if (matterId) {
      setErrorRules("");
      loadRulesWithDeficiency();
      getMatterViolations(matterId).then(
        (r) => {
          setMatterViolations(r);
        },
        (error) => {
          handleError(error, setErrorRules, undefined);
        }
      );
    }
  }, [
    authenticatedUser?.roleId,
    matterId,
    getFullsheetDeficienciesById,
    getRules,
    navigate,
  ]);

  const loadSheetDeficiencies = () => {
    setErrorSheetDeficiencies("");
    setLoadingSheetDeficiencies(true);
    getFullsheetDeficienciesById(matterId!).then(
      (deficiencies) => {
        setLoadingSheetDeficiencies(false);
        setSheetDeficiencies(deficiencies);
        setSheetDeficienciesLoaded(true);
      },
      (error) => {
        handleError(
          error,
          setErrorSheetDeficiencies,
          setLoadingSheetDeficiencies
        );
      }
    );
  };

  const loadRuleDeficiencies = (ruleId: string) => {
    if (ruleId === "") {
      setRuleDeficiencies([]);
      setShowSheetDeficiencies(true);
      return;
    }
    setErrorRuleDeficiencies("");
    setLoadingRuleDeficiencies(true);
    setShowSheetDeficiencies(false);
    getRuleDeficienciesById(matterId!, ruleId).then(
      (deficiencies) => {
        setRuleDeficiencies(deficiencies);
        setLoadingRuleDeficiencies(false);
      },
      (error) => {
        handleError(
          error,
          setErrorRuleDeficiencies,
          setLoadingRuleDeficiencies
        );
      }
    );
  };

  const exportDeficiencyCsvFile = () => {
    setErrorExport("");
    getDeficiencyCsv(matterId!).then(
      (csv) => {
        csvLink.current?.setAttribute("download", "CSVData.csv");
        csvLink.current?.setAttribute(
          "href",
          URL.createObjectURL(new Blob([csv], { type: "text/csv" }))
        );
        csvLink.current?.click();
      },
      (error) => {
        handleError(error, setErrorExport);
      }
    );
  };

  const handleAddMatterDeficiency = (violationId: number, violationNote: string | null) => {
    setErrorMatterDeficiency("");
    postMatterDeficiency(matterId!, violationId, violationNote).then(
      (deficiency) => {
        loadRulesWithDeficiency();
        if (showSheetDeficiencies) {
          loadSheetDeficiencies()
        }
      },
      (error) => {
        handleError(error, setErrorMatterDeficiency);
      }
    );
  };

  const handleClearMatterDeficiency = () => {
    setErrorMatterDeficiency("");
    clearMatterDeficiency(matterId!).then(
      () => {
        loadRulesWithDeficiency();
        if (showSheetDeficiencies) {
          loadSheetDeficiencies()
        }
      },
      (error) => {
        handleError(error, setErrorMatterDeficiency);
      }
    );
  };


  return (
    <div className="container-fluid bg-sw-secondary">
      <div className="row bg-sw-primary">
        <div className="sw-buffer">
          <div className="d-flex flex-row mb-4">
            <div className="me-auto">
              <Link
                to={`/admin/matters/${matterId}/details`}
                className="btn btn-sw btn-sm btn-outline"
              >
                <FontAwesomeIcon icon={faCircleLeft} className="me-2" />
                Back to Matter
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="tablet-buffer bg-sw-secondary">
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <div className="form-check form-check-inline">
                <input
                  id="rule"
                  className="form-check-input"
                  type="radio"
                  name="category"
                  checked={showSheetDeficiencies === false}
                  onChange={() => {
                    setShowSheetDeficiencies(false);
                  }}
                />

                <label className="m-0" htmlFor="rule">
                  By Rule
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  id="sheet"
                  className="form-check-input"
                  type="radio"
                  name="category"
                  checked={showSheetDeficiencies === true}
                  onChange={() => {
                    setShowSheetDeficiencies(true);
                    if (!sheetDeficienciesLoaded) {
                      loadSheetDeficiencies();
                    }
                  }}
                />
                <label className="m-0" htmlFor="sheet">
                  By Sheet
                </label>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <button onClick={exportDeficiencyCsvFile} className="btn btn-sw">
              Export CSV
            </button>
            {/* eslint-disable-next-line */}
            <a ref={csvLink} style={{ display: "none" }} target="_blank"></a>
            <button
              onClick={() => setShowMatterDeficiencyModal(true)}
              className="btn btn-sw ms-4"
            >
              Matter Deficiency
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              {errorExport && (
                <div className="alert alert-danger" role="alert">
                  {errorExport}
                </div>
              )}
            </div>
          </div>
        </div>

        {!showSheetDeficiencies && (
          <DeficienciesByRule
            rules={rules}
            errorRules={errorRules}
            loadRuleDeficiencies={loadRuleDeficiencies}
            loadingRuleDeficiencies={loadingRuleDeficiencies}
            errorRuleDeficiencies={errorRuleDeficiencies}
            ruleDeficiencies={ruleDeficiencies}
          />
        )}

        {showSheetDeficiencies && (
          <DeficienciesBySheet
            loadingSheetDeficiencies={loadingSheetDeficiencies}
            errorSheetDeficiencies={errorSheetDeficiencies}
            sheetDeficiencies={sheetDeficiencies}
          />
        )}

        <AddMatterDeficiencyModal
          isOpen={showMatterDeficiencyModal}
          close={() => {
            setShowMatterDeficiencyModal(false);
          }}
          addDeficiency={(form) => {
            handleAddMatterDeficiency(form.violationId as number, form.violationNote);
          }}
          clearDeficiency={() => {
            handleClearMatterDeficiency();
          }}
          errorMessage={errorMatterDeficiency}
          violationCriteria={matterViolations}
        />
      </div>
    </div>
  );
}
