import { RefObject, useCallback, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { ValidationForm, Validity, Work, WorkField } from "../types/work.types";

interface FieldValidationProps {
  work: Work;
  formikRef: RefObject<FormikProps<ValidationForm>> | null;
  handleNextWork: (fields: WorkField[]) => void;
}

interface KeyboardEvent {
  key: string;
  repeat: boolean;
  target: EventTarget | null;
}

export default function ColumnValidation({
  work,
  handleNextWork,
  formikRef,
}: FieldValidationProps) {
  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.repeat) return;

      if (e.key.toLowerCase() === "enter") {
        formikRef?.current?.submitForm();
        return;
      }

      const nodeName = (e.target as Element)?.nodeName;
      if (nodeName !== "INPUT" && nodeName !== "TEXTAREA") {
        if (e.key.toLowerCase() === "v") {
          formikRef?.current?.setFieldValue(
            "fields[0].validity",
            Validity.Valid
          );
          return;
        }
        if (e.key.toLowerCase() === "n") {
          formikRef?.current?.setFieldValue(
            "fields[0].validity",
            Validity.Invalid
          );
          return;
        }
        if (e.key.toLowerCase() === "s") {
          formikRef?.current?.setFieldValue(
            "fields[0].validity",
            Validity.Strikethrough
          );
          return;
        }
      }
    },
    [formikRef]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress, true);
    return () => {
      document.removeEventListener("keydown", handleKeyPress, true);
    };
  }, [handleKeyPress]);

  const handleNext = (formValues: ValidationForm) => {
    handleNextWork(formValues.fields);
  };

  const checkValidity = (value: string) => {
    let error;
    if (value === "") {
      error = "Valid state is required";
    }
    return error;
  };

  return (
    <div className="row text-center mt-3">
      <div className="col-sm-12 col-lg-8">
        <img
          alt="transcribable column"
          style={{ maxHeight: 550, maxWidth: 600 }}
          src={`data:image/png;base64,${work.image.fileContents}`}
        />
      </div>
      <div className="col-sm-12 col-lg-4">
        <Formik
          innerRef={formikRef}
          enableReinitialize
          validateOnBlur={false}
          initialValues={
            {
              fields: work.fields,
            } as ValidationForm
          }
          onSubmit={handleNext}
        >
          {({ values, isSubmitting, setFieldValue, handleChange }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="fields[0].value">{values.fields[0].name}</label>
                <Field
                  name="fields[0].value"
                  className="form-control"
                  placeholder="Enter field value"
                  type="text"
                />
                <ErrorMessage
                  name="fields[0].value"
                  component="div"
                  className="alert alert-danger p-2"
                />
              </div>
              <div className="form-check form-check-inline">
                <Field
                  id="fields[0].valid"
                  className="form-check-input"
                  type="radio"
                  validate={checkValidity}
                  name="fields[0].validity"
                  required
                  checked={values.fields[0].validity === Validity.Valid}
                  onChange={() => {
                    setFieldValue("fields[0].validity", Validity.Valid);
                  }}
                />
                <label className="m-0" htmlFor="fields[0].valid">
                  <span style={{ textDecoration: "underline" }}>V</span>
                  <span>alid</span>
                </label>
              </div>
              <div className="form-check form-check-inline">
                <Field
                  id="fields[0].invalid"
                  className="form-check-input"
                  type="radio"
                  validate={checkValidity}
                  name="fields[0].validity"
                  required
                  checked={values.fields[0].validity === Validity.Invalid}
                  onChange={() => {
                    setFieldValue("fields[0].validity", Validity.Invalid);
                  }}
                />
                <label className="m-0" htmlFor="fields[0].invalid">
                  <span>I</span>
                  <span style={{ textDecoration: "underline" }}>n</span>
                  <span>valid</span>
                </label>
              </div>
              <div className="form-check form-check-inline">
                <Field
                  id="fields[0].strikethough"
                  className="form-check-input"
                  type="radio"
                  validate={checkValidity}
                  name="fields[0].validity"
                  required
                  checked={values.fields[0].validity === Validity.Strikethrough}
                  onChange={() => {
                    setFieldValue("fields[0].validity", Validity.Strikethrough);
                  }}
                />
                <label className="m-0" htmlFor="fields[0].strikethrough">
                  <span style={{ textDecoration: "underline" }}>S</span>
                  <span>trikethrough</span>
                </label>
              </div>

              {values.fields[0].validity === Validity.Invalid && (
                <div>
                  <Field
                    as="select"
                    style={{ minWidth: "25%" }}
                    className="form-control w-auto d-inline"
                    validate={(value: string) => {
                      if (
                        !value &&
                        values.fields[0].validity === Validity.Invalid
                      ) {
                        return "Violation is required";
                      }
                      return null;
                    }}
                    name="fields[0].violationId"
                    value={values.fields[0].violationId}
                    onChange={handleChange}
                  >
                    <option value="">Select Violation</option>
                    {values.fields[0].violationCriteria.map((criterion) => {
                      return (
                        <option key={criterion.ruleId} value={criterion.ruleId}>
                          {criterion.name}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    name="fields[0].violationId"
                    component="div"
                    className="alert alert-danger w-25 ms-auto me-auto p-2"
                  />
                  <Field
                    className="form-control mt-1"
                    placeholder="Notes"
                    as="textarea"
                    name="fields[0].violationNote"
                    value={values.fields[0].violationNote}
                    onChange={handleChange}
                  />
                </div>
              )}
              <ErrorMessage
                name="fields[0].isValid"
                component="div"
                className="alert alert-danger p-2"
              />
              <div className="form-group mt-4">
                <button
                  type="submit"
                  className="btn btn-secondary"
                  disabled={isSubmitting}
                >
                  {!isSubmitting && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-arrow-right me-1"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      ></path>
                    </svg>
                  )}
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm me-1"></span>
                  )}
                  Next
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
