import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faSquarePlus } from "@fortawesome/free-regular-svg-icons";

import { formatDateString, handleError } from "../common/utils";
import { useMatterService } from "../services/matter.service";
import { Matter } from "../types/matter.types";
import { useAuthContext } from "../AuthProvider";
import { Roles } from "../types/user.types";
import ConfirmModal from "./confirm-modal";

export default function AdminMatters() {
  const { authenticatedUser } = useAuthContext();
  const navigate = useNavigate();
  const [matters, setMatters] = useState<Matter[]>([]);
  const [loadingMatters, setLoadingMatters] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showMatterConfirmModal, setShowMatterConfirmModal] = useState(false);
  const { getAllMatters, deleteMatter } = useMatterService();
  const [matterIdToDelete, setMatterIdToDelete] = useState<string>();

  const loadMatters = useCallback(() => {
    setErrorMessage("");
    setLoadingMatters(true);

    getAllMatters().then(
      (matters) => {
        setMatters(matters);
        setLoadingMatters(false);
      },
      (error) => {
        handleError(error, setErrorMessage, setLoadingMatters);
      }
    );
  }, [getAllMatters]);

  useEffect(() => {
    if (authenticatedUser?.roleId !== Roles.Admin) {
      navigate("/login");
    } else {
      loadMatters();
    }
  }, [authenticatedUser?.roleId, loadMatters, navigate]);

  const handleDeleteMatter = () => {
    if (matterIdToDelete) {
      setErrorMessage("");
      setLoadingMatters(true);

      deleteMatter(matterIdToDelete).then(
        () => {
          setLoadingMatters(false);
          loadMatters();
        },
        (error) => {
          handleError(error, setErrorMessage, setLoadingMatters);
        }
      );
    }
  };

  return (
    <>
      <div className="d-flex left-right">
        <h3>Matters</h3>
        <Link to="/admin/matters/new" className="btn btn-sw btn-outline">
          <FontAwesomeIcon icon={faSquarePlus} className="me-2" />
          New Matter
        </Link>
      </div>
      <div
        className="mt-3"
        style={{ maxHeight: 400, overflowY: "auto", overflowX: "hidden" }}
      >
        {matters?.length > 0 && (
          <table className="sw">
            <thead>
              <tr className="d-flex">
                <th scope="col" className="col-sm-7">
                  Name
                </th>
                <th scope="col" className="col-sm-4">
                  Due Date
                </th>
                <th scope="col" className="col-sm-1"></th>
              </tr>
            </thead>
            <tbody>
              {matters?.map((matter) => (
                <tr key={matter.id} className="d-flex border-bottom">
                  <td
                    className="col-sm-7"
                    onClick={() => navigate(`/admin/matters/${matter.id}/details`)}
                  >
                    {matter.name}
                  </td>
                  <td className="col-sm-4">
                    {formatDateString(matter?.dueDate?.toString())}
                  </td>
                  <td className="col-sm-1">
                    <button
                      className="btn btn-link sw-maroon"
                      onClick={() => {
                        setShowMatterConfirmModal(true);
                        setMatterIdToDelete(matter.id.toString());
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#741D38" }}
                        icon={faTrashCan}
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {!matters?.length && !loadingMatters && (
          <div className="list-group-item list-group-item-light">
            There are no matters
          </div>
        )}
      </div>
      <ConfirmModal
        message="Are you sure you want to delete this matter?"
        action={handleDeleteMatter}
        isOpen={showMatterConfirmModal}
        close={() => setShowMatterConfirmModal(false)}
      />
    </>
  );
}
