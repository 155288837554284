import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  TooltipProps,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { Progress } from "../types/matter.types";

type ValueType = number | string | Array<number | string>;
type NameType = number | string;

interface MatterProgressProps {
  progress: Progress | undefined;
  isLoading: boolean;
  errorMessage: string;
}

export default function MatterProgress({
  progress,
  isLoading,
  errorMessage,
}: MatterProgressProps) {
  const CustomTooltip = ({
    active,
    payload,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="text-center"
          style={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #CCCCCC",
            whiteSpace: "nowrap",
          }}
        >
          <div>
            <span className="font-weight-bold">
              {payload[1].value?.toLocaleString("en-US")}
            </span>
            {" Signatures to Review"}
          </div>
          <div>
            <span className="font-weight-bold">
              {payload[0].value?.toLocaleString("en-US")}
            </span>
            {" Reviewed"}
          </div>
          <div>
            <span className="font-weight-bold">
              {payload[2].value?.toLocaleString("en-US")}
            </span>
            {" Deficient"}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <ResponsiveContainer height={100} width={"100%"}>
          <BarChart
            layout="vertical"
            data={[progress]}
            margin={{
              top: 0,
              right: 10,
              left: 10,
              bottom: 0,
            }}
          >
            <XAxis
              type="number"
              tickCount={2}
              tick={{ fontSize: 12 }}
              domain={["dataMin", "dataMax"]}
            />
            <YAxis type="category" dataKey="a" hide />
            <Tooltip wrapperStyle={{ zIndex: 1 }} content={<CustomTooltip />} />
            <Bar
              isAnimationActive={false}
              dataKey="reviewed"
              fill="#FFFFFF"
              stackId="a"
            />
            <Bar
              isAnimationActive={false}
              dataKey="remaining"
              fill="#AFAFAF"
              stackId="a"
            />
            <Bar
              isAnimationActive={false}
              dataKey="deficient"
              fill="#741D38"
              stackId="a"
            />
            <ReferenceLine
              x={progress?.threshold}
              stroke="black"
              label={{ value: "Threshold", position: "bottom", fontSize: 10 }}
              strokeDasharray="3 3"
            />
          </BarChart>
        </ResponsiveContainer>
        {errorMessage && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
