import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../AuthProvider';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const extractData = (response: AxiosResponse) => response.data;
const onResponse = (response: AxiosResponse): AxiosResponse => response

export function useRequestService() {
    const { setAuthenticatedUser } = useAuthContext();
    const navigate = useNavigate();
    const instance = useMemo(() => axios.create({
        baseURL: API_URL,
        timeout: 60000
    }), []);

    const onResponseError = (error: AxiosError): Promise<AxiosError> => {
        if (error.response?.status === 401) {
            setAuthenticatedUser(null);
            navigate("/login");
          }
        return Promise.reject(error);
    }
    instance.interceptors.response.use(onResponse, onResponseError);

    const httpGet = useCallback(async function (url: string, config?: AxiosRequestConfig) {
        return await instance.get(url, config).then(extractData);
    }, [instance]);

    const httpPost = useCallback(async function (url: string, body: {}, config?: AxiosRequestConfig) {
        return await instance.post(url, body, config).then(extractData);
    }, [instance]);


    const httpPut = useCallback(async function (url: string, body: {}, config?: AxiosRequestConfig) {
        return await instance.put(url, body, config).then(extractData);
    }, [instance]);


    const httpDelete = useCallback(async function (url: string, config?: AxiosRequestConfig) {
        return await instance.delete(url, config).then(extractData);
    }, [instance]);


    const funcs = {
        httpGet, httpPost, httpPut, httpDelete,
    };
    return funcs;
};

export function useAuthenticatedRequestService() {
    const { authenticatedUser } = useAuthContext();
    const { httpGet, httpPost, httpPut, httpDelete } = useRequestService();
    const authConfig = useMemo<AxiosRequestConfig>(() => { return {
        headers: {
            'Authorization': 'Bearer ' + authenticatedUser?.accessToken,
        }
    }}, [authenticatedUser?.accessToken]);

    const authGet = useCallback(async function authGetInternal(url: string, additionalConfig?: AxiosRequestConfig) {
        const options = additionalConfig ? {...authConfig, ...additionalConfig} : authConfig
        return await httpGet(url, options);
    }, [httpGet, authConfig]);

    const authPost = useCallback(async function authPostInternal(url: string, body: {}) {
        return await httpPost(url, body, authConfig);
    }, [httpPost, authConfig]);

    const authPut = useCallback(async function authPutInternal(url: string, body: {}) {
        return await httpPut(url, body, authConfig);
    }, [httpPut, authConfig]);

    const authDelete = useCallback(async function authDeleteInternal(url: string) {
        return await httpDelete(url, authConfig);
    }, [httpDelete, authConfig]);

    const funcs = {
        authGet, authPost, authPut, authDelete,
    };
    return funcs;
};