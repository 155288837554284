import { useRef, useState } from "react";
import { useAuthContext } from "../AuthProvider";
import { FileProgress, MultiFileProgress } from "../types/file.types";
import { uploadFileService } from "../services/upload-file.service";

interface UploadProps {
  filePath?: string;
  uploadUrlPath: string;
  fileTypes: string;
  isMultiFile?: boolean;
  isValid?: () => boolean;
  uploadProcessing?: () => void;
  uploadComplete?: (result: string) => void;
  uploadError?: (msg: string) => void;
  fileSelected?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  replaceFile?: () => void;
  additionalData?: { [key: string]: string };
}

export default function UploadFiles({
  filePath,
  uploadUrlPath,
  fileTypes,
  isMultiFile = false,
  isValid = () => {
    return true;
  },
  uploadProcessing,
  uploadComplete,
  uploadError,
  fileSelected,
  replaceFile,
  additionalData,
}: UploadProps) {
  const { authenticatedUser } = useAuthContext();
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [multiFileProgress, setMultiFileProgress] = useState<MultiFileProgress>(
    {
      fileProgressArray: [],
    }
  );
  const [message, setMessage] = useState<string[]>([]);
  const multiFileProgressRef = useRef<MultiFileProgress>({
    fileProgressArray: [],
  });

  const selectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMultiFileProgress({ fileProgressArray: [] });
    setSelectedFiles(e.target.files);
    if (fileSelected) {
      fileSelected(e);
    }
  };

  const uploadFiles = () => {
    if (selectedFiles) {
      const files = Array.from(selectedFiles);
      let fileProgressArray = files.map((file) => ({
        percentage: 0,
        fileName: file.name,
      }));

      multiFileProgressRef.current = {
        fileProgressArray: fileProgressArray,
      };

      files.map((file, i) => upload(file, i));
      setMessage([]);
    }
  };

  const upload = (file: File, index: number) => {
    let fileProgressArray = [...multiFileProgressRef.current.fileProgressArray];

    uploadFileService.uploadFile(
      file,
      uploadUrlPath,
      authenticatedUser?.accessToken!,
      {
        setUploadProgress: (percentage: number) => {
          if (percentage === 100 && uploadProcessing) {
            uploadProcessing();
          }
          fileProgressArray[index].percentage = percentage;
          setMultiFileProgress({
            fileProgressArray: fileProgressArray,
          });
        },
      },
      additionalData
    )
      .then((body) => {
        setMessage((prevMessage) => [
          ...prevMessage,
          `File uploaded successfully: ${file.name}`,
        ]);

        if (uploadComplete) {
          uploadComplete(body.data);
        }
      })
      .catch((error) => {
        fileProgressArray[index].percentage = 0;
        setMultiFileProgress({
          fileProgressArray: fileProgressArray,
        });
        const message = error.response?.data || error;
        setMessage((prevMessage) => [
          ...prevMessage,
          `Could not upload the file ${file.name}: ${message}`,
        ]);

        if (uploadError) {
          uploadError(message);
        }
      });
  };

  return (
    <>
      {filePath ? (
        <div className="input-group">
          <input
            className="form-control"
            type="text"
            value={filePath}
            readOnly
          />
          <button className="btn btn-sw" onClick={replaceFile}>
            Change
          </button>
        </div>
      ) : (
        <>
          <div className="input-group">
            <input
              className="form-control"
              type="file"
              {...(isMultiFile === true ? { multiple: true } : {})}
              accept={fileTypes}
              onChange={selectFiles}
            />
            <button
              className="btn btn-sw"
              disabled={!selectedFiles || !isValid()}
              onClick={uploadFiles}
            >
              Upload
            </button>
          </div>
          <div style={{ maxHeight: 200 }} className="overflow-auto">
            {multiFileProgress &&
              multiFileProgress.fileProgressArray.length > 0 &&
              multiFileProgress.fileProgressArray.map(
                (fileProgress: FileProgress, index: number) => (
                  <div className="mb-2" key={index}>
                    <span>{fileProgress.fileName}</span>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow={fileProgress.percentage}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: fileProgress.percentage + "%" }}
                      >
                        {fileProgress.percentage}%
                      </div>
                    </div>
                  </div>
                )
              )}
          </div>
        </>
      )}

      {message.length > 0 && (
        <div
          style={{ maxHeight: 100 }}
          className="alert alert-secondary overflow-auto p-1"
          role="alert"
        >
          <ul className="m-0 p-0 small">
            {message.map((item, i) => {
              return (
                <li key={i} className="list-unstyled">
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}
